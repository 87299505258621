import React, { useEffect, useRef, useState } from "react";
import ChurchHealthMobileCards from "../ChurchHealthMobile/ChurchHealthMobileCards/ChurchHealthMobileCards";
import { Header } from "../ChurchHealthMobile/Header/Header";
import MobileFilterCard from "../MobileFilterCard/MobileFilterCard";
import { MobileAIInsights } from "../ChurchHealthMobile/MobileAIInsights/MobileAIInsights";
import RelationshipBadge from "../../Assests/Images/badge-heart.svg";
import PurposeBadge from "../../Assests/Images/badge-purpose.svg";
import ContentmentBadge from "../../Assests/Images/badge-contentment.svg";
import WellnessBadge from "../../Assests/Images/badge-health.svg";
import FinancesBadge from "../../Assests/Images/badge-finances.svg";
import SpiritualBadge from "../../Assests/Images/badge-spiritual.svg";
import CharacterBadge from "../../Assests/Images/badge-character.svg";
import arrowright from '../../Assests/Images/arrow-right-black-view.svg';
import ring from "../../Assests/Images/ring.svg"
import baby from "../../Assests/Images/baby.svg"
import heartCrack from "../../Assests/Images/heart-crack.svg"
import personCane from "../../Assests/Images/person-cane.svg"

// Nurturing
import connectedCommunityIcon from "../../Assests/Images/ThrivingResults/connected-community-bold.svg"
import workshipExperienceIcon from "../../Assests/Images/ThrivingResults/workship-experience-bold.svg"
import prayerCultureIcon from "../../Assests/Images/ThrivingResults/prayer-culture-bold.svg"
import bibleCenterednessIcon from "../../Assests/Images/ThrivingResults/bible-centeredness-bold.svg"
import spiritualFormationIcon from "../../Assests/Images/ThrivingResults/spiritual-formation-bold.svg"
import trustedLeadersIcon from "../../Assests/Images/ThrivingResults/trusted-leaders-bold.svg"

// sending
import faithSharingIcon from "../../Assests/Images/ThrivingResults/faith-sharing-bold.svg"
import servingOthersIcon from "../../Assests/Images/ThrivingResults/serving-others-bold.svg"
import holisticStewardshipIcon from "../../Assests/Images/ThrivingResults/holistic-stewardship-bold.svg"
import leadershipDevelopmentIcon from "../../Assests/Images/ThrivingResults/leadership-development-bold.svg"
import socialImpactIcon from "../../Assests/Images/ThrivingResults/social-impact-bold.svg"

// leading
import futureFocusedIcon from "../../Assests/Images/ThrivingResults/future-focused-bold.svg"
import dataInformedIcon from "../../Assests/Images/ThrivingResults/data-informed-bold.svg"
import resourceStabilityIcon from "../../Assests/Images/ThrivingResults/resource-stability-bold.svg"
import teamHealthIcon from "../../Assests/Images/ThrivingResults/team-health-bold.svg"
import { useNavigate } from "react-router-dom";
import { navigateToCircles } from "../../utils";


const chartDataTemplatepeopleFlourishing = [
  { name: "Relationships", variable: "cprelation", icon: RelationshipBadge, mydata: 32 },
  { name: "Purpose", variable: "cppurpose", icon: PurposeBadge, mydata: 34 },
  { name: "Faith", variable: "cpspiritual", icon: SpiritualBadge, mydata: 84 },
  { name: "Virtue", variable: "cpcharacter", icon: CharacterBadge, mydata: 27 },
  { name: "Finances", variable: "cpfinance", icon: FinancesBadge, mydata: 48 },
  { name: "Fulfillment", variable: "cpcontent", icon: ContentmentBadge, mydata: 63 },
  { name: "Wellness", variable: "cphealth", icon: WellnessBadge, mydata: 63 },
];
// Create chart data using the averages
const chartDataTemplateGrowthGoals = [
  { id: 1, name: "Relationships", variable: "cprelation", description: "Grow in the quality of my relationships.", mydata: 32, icon: RelationshipBadge, },
  { id: 2, name: "Purpose", variable: "cppurpose", description: "Grow in the sense of meaning I find in daily activity.", mydata: 56, icon: PurposeBadge, },
  { id: 3, name: "Faith", variable: "cpspiritual", description: "Grow in my spiritual vitality.", mydata: 83, icon: SpiritualBadge, },
  { id: 4, name: "Wellness", variable: "cphealth", description: "Grow in my overall physical and mental health.", mydata: 12, icon: WellnessBadge, },
  { id: 5, name: "Finances", variable: "cpfinance", description: "Grow in my financial stability.", mydata: 39, icon: FinancesBadge, },
  { id: 6, name: "Virtue", variable: "cpcharacter", description: "Grow in my responsiveness to God and culture.", mydata: 43, icon: CharacterBadge, },
  { id: 7, name: "Fulfillment", variable: "cpcontent", description: "Grow in my overall life happiness and satisfaction.", mydata: 3, icon: ContentmentBadge, },
];

const chartDataTemplateChurchThriving = {
  "Nurturing": [
    { variable: "cpcommunity", name: "Connected Community", mydata: 32, icon: connectedCommunityIcon },
    { variable: "cpworship", name: "Worship Experience", mydata: 34, icon: workshipExperienceIcon },
    { variable: "cptrusted", name: "Trusted Leaders", mydata: 84, icon: trustedLeadersIcon },
    { variable: "cpbible", name: "Bible Centeredness", mydata: 27, icon: bibleCenterednessIcon },
    { variable: "cpformation", name: "Spiritual Formation", mydata: 48, icon: spiritualFormationIcon },
    { variable: "cpprayer", name: "Prayer Culture", mydata: 63, icon: prayerCultureIcon },

  ],
  "Sending": [
    { variable: "cpfaith", name: "Faith Sharing", mydata: 44, icon: faithSharingIcon },
    { variable: "cpserving", name: "Serving Others", mydata: 74, icon: servingOthersIcon },
    { variable: "cpsteward", name: "Holistic Stewardship", mydata: 34, icon: holisticStewardshipIcon },
    { variable: "cpleaddev", name: "Leadership Development", mydata: 84, icon: leadershipDevelopmentIcon },
    { variable: "cpimpact", name: "Social Impact", mydata: 31, icon: socialImpactIcon }
  ],
  "Leading": [
    { variable: "cpfuture", name: "Future Focused", mydata: 21, icon: futureFocusedIcon },
    { variable: "cpdata", name: "Data Informed", mydata: 45, icon: dataInformedIcon },
    { variable: "cpresource", name: "Resource Stability", mydata: 97, icon: resourceStabilityIcon },
    { variable: "cpteam", name: "Team Health", mydata: 10, icon: teamHealthIcon }
  ]
}
const chartDataTemplateDemographics = [
  { name: "Marriage", icon: ring, mydata: 45, nationalaverage: 78, variable: 'cpmarriage', description: "What percentage of congregants are married?" },
  { name: "Parenthood", icon: baby, mydata: 81, nationalaverage: 51, variable: 'cpparenthood', description: "What percentage of congregants are parents?" },
  { name: "Deploy Assessment", variable: 'Deploy'},
]

const ChurchHealthMobile = ({ comparison, isDeployedDone, peopleFlourishingprimaryData, peopleFlourishingsecondaryData, growthGoalsPrimaryData, growthGoalsSecondaryData, demographicsprimaryData, demographicssecondaryData, dimensionCatIds, setAppliedFilters, appliedFilters, primaryLabel, secondaryLabel , isChurch }) => {
  const [activeTab, setActiveTab] = useState("Nurturing");
  const tabs = ["Nurturing", "Sending", "Leading"];
  const navigate = useNavigate();

  const handleViewAll = () => {
    navigate("/comparative-demographic-details",
      {
        state: {
          from: 'Demographics',
          isDeployedDone: isDeployedDone,
          isComparisonBarChart: comparison,
          appliedFiltersPrimary: {},
          appliedFiltersSecondary: appliedFilters,
          isChurch:isChurch
        }
      }
    )
  }
  return (
    <>
      <Header />
      <div className="church-health-mobile-container-deploy">
        <div className="filter-container-mobile">
          <MobileFilterCard
            filterType="rightfilter"
            isDeployedDone={false}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            comparison={true}
            primaryColor="#D4D4D4"
            isChurch={isChurch}
          />
          <div className='divider' />
          <div className="add-comparison-container" onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
            <p className="add-comparison-text">{isChurch ? "Deploy Assessment"  :"Create your Circle" }</p>
          </div>
        </div>

        <div className="deploy-asesessment-banner-container" >
          <div className={`deploy-asesessment-banner ${isChurch ? "" : "network"}`}>
            {isChurch ? 
            <div className="mobile-banner-title">
              Find out how <span>your</span> congregants are doing
            </div>
            : <div className="mobile-banner-title">
            Find out how <span>your</span> churches are doing
          </div>}
          {isChurch ? <div className="mobile-banner-description">
              <p>
                Church leaders nationwide are measuring what matters through the
                State of the Church Initiative. Explore real-time national
                results below and compare them to your own Church’s data with
                the Church Pulse Assessment.
              </p>
            </div> : <div className="mobile-banner-description">
              <p>
              Church leaders nationwide are measuring what matters through the State of the Church Initiative. Explore real-time national results below, and compare them to your own Church’s data by inviting them to join your Circle and encouraging them to deploy the Church Pulse Assessment to their congregants.
              </p>
            </div> }
            
            <button onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()} className={`mobile-banner-cta ${isChurch ? "" : "network-cta"}`}>{isChurch ? "Deploy Assessment" : "Create your Circle"} </button>
          </div>
        </div>
        <div className="mobile-people-flourishing-container">
          <span className="mobile-people-flourishing-container__heading">
            People Flourishing
          </span>
          <span className="mobile-people-flourishing-container__description">
            7 dimensions of whole-person health. How are my people doing and
            where do they need support?
          </span>

          <ChurchHealthMobileCards
            charategry='PeopleFlourishing'
            chartDataTemplate={chartDataTemplatepeopleFlourishing}
            comparison={comparison}
            isDeployedDone={isDeployedDone}
            primaryData={peopleFlourishingprimaryData}
            secondaryData={peopleFlourishingsecondaryData}
            dimensionCatIds={dimensionCatIds}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            isChurch={isChurch}
            />

          <MobileAIInsights isChurch={isChurch}  isDeployedDone={isDeployedDone} />
        </div>
        <div className="mobile-gowth-goals-container">
          <span className="mobile-gowth-goals-container__heading">
            Growth Goals
          </span>
          <span className="mobile-gowth-goals-container__description">
            See which dimension of flourishing your people want to grow in the most.
          </span>
          {/* GrowthGoals */}
          <ChurchHealthMobileCards
            charategry='GrowthGoals'
            comparison={comparison}
            isDeployedDone={isDeployedDone}
            chartDataTemplate={chartDataTemplateGrowthGoals}
            primaryData={growthGoalsPrimaryData}
            secondaryData={growthGoalsSecondaryData}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            isChurch={isChurch}
            />

        </div>
        <div className="mobile-thriving-container">
          <span className="mobile-thriving-container__heading">
            Church Thriving
          </span>
          <span className="mobile-thriving-container__description">
            22 dimensions of <span>Church-specific</span> health metrics, broken into 3 buckets. What are the deeper drivers of my church health?
          </span>
          <div className="mobile-thriving-tab-container">
            {tabs.map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${activeTab === tab && "active"}`}
              >
                {tab}
              </div>
            ))}
          </div>
          {/* ChurchThriving */}
          <div>
            <ChurchHealthMobileCards
              charategry='ChurchThriving'
              comparison={comparison}
              activeCard={activeTab}
              isDeployedDone={isDeployedDone}
              chartDataTemplate={chartDataTemplateChurchThriving}
              primaryData={peopleFlourishingprimaryData}
              secondaryData={peopleFlourishingsecondaryData}
              dimensionCatIds={dimensionCatIds} ß
              primaryLabel={primaryLabel}
              secondaryLabel={secondaryLabel}
              isChurch={isChurch}
            />
            <MobileAIInsights isChurch={isChurch} isDeployedDone={isDeployedDone}/>
          </div>
        </div>

        <div className="mobile-demographics-container">
          <span className="mobile-demographics-container__heading">
            Demographics
          </span>
          <span className="mobile-demographics-container__description">
            Learn more about the characteristics of your community.
          </span>
          <div className="viewcta">
            <button className="cta-viewall" onClick={() => handleViewAll()} >View all <img src={arrowright} alt="" style={{ width: "16px" }} /></button>
          </div>
          {/* Demographics */}
          <ChurchHealthMobileCards
            charategry='Demographics'
            comparison={comparison}
            isDeployedDone={isDeployedDone}
            chartDataTemplate={chartDataTemplateDemographics}
            primaryData={demographicsprimaryData}
            secondaryData={demographicssecondaryData}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel} 
            isChurch={isChurch}
            />
        </div>
      </div>
    </>
  );
};

export default ChurchHealthMobile;
