import React, { useEffect, useRef, useState } from 'react'
import ArrowRight from '../../../Assests/Images/arrow-right-white.svg';
import cards from "../../../Assests/Images/cards.svg";
import demoCTA from "../../../Assests/Images/demoCTA.svg";
import { useNavigate } from 'react-router-dom';
import { navigateToCircles } from '../../../utils';
const ChurchHealthMobileCards = ({ charategry, comparison, chartDataTemplate, isDeployedDone, primaryData, secondaryData, dimensionCatIds, activeCard, primaryLabel, secondaryLabel , isChurch }) => {
  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (charategry === 'PeopleFlourishing') {
      if (primaryData && secondaryData && Array.isArray(dimensionCatIds)) {

        const primAverageScores = {};
        const secAverageScores = {};
        // Populate the averages
        chartDataTemplate.forEach(({ variable }) => {
          const item = secondaryData.find((data) => data.variable === variable);
          secAverageScores[variable] = Math.floor(item?.average_score || 0);

          const prItem = primaryData.find((data) => data.variable === variable);
          primAverageScores[variable] = Math.floor(prItem?.average_score || 0);
        });

        const newData = chartDataTemplate.map(({ name, mydata, variable, icon }) => {
          const dimension = dimensionCatIds.find(
            (dim) => dim.variable === variable
          );
          return {
            name,
            icon,
            mydata: isDeployedDone ? primAverageScores[variable] || 0 : mydata,
            nationalaverage: secAverageScores[variable],
            catId: dimension?.catId || null,
          };
        });
        setChartData(newData);
        // console.log("check2", newData);
      }
    } else if (charategry === 'GrowthGoals') {
      if (primaryData && secondaryData) {
        const newData = chartDataTemplate.map(
          ({ id, name, mydata, variable, description, icon }, index) => ({
            id,
            name,
            mydata: isDeployedDone ? primaryData[index]?.percentage || 0 : mydata,
            description,
            nationalaverage: secondaryData[index]?.percentage || 0,
            icon,
          })
        );
        setChartData(newData);
      }
    } else if (charategry === 'ChurchThriving') {
      if (primaryData && secondaryData && Array.isArray(dimensionCatIds)) {
        const churchThrivingVariables = {
          Nurturing: ["cpcommunity", "cpworship", "cptrusted", "cpbible", "cpformation", "cpprayer",],
          Sending: ["cpfaith", "cpserving", "cpsteward", "cpleaddev", "cpimpact"],
          Leading: ["cpfuture", "cpdata", "cpresource", "cpteam"]
        };

        const primAverageScores = {};
        const secAverageScores = {};

        const currentVariables = churchThrivingVariables[activeCard];
        currentVariables.forEach(variable => {
          const item = secondaryData.find(data => data.variable === variable);
          secAverageScores[variable] = Math.floor(item?.average_score || 0);

          const prItem = primaryData.find(data => data.variable === variable);
          primAverageScores[variable] = Math.floor(prItem?.average_score || 0);
        });

        const newData = chartDataTemplate[activeCard].map(({ name, mydata, variable, icon }) => {
          const dimension = dimensionCatIds.find(dim => dim.variable === variable);
          return {
            name,
            icon,
            mydata: isDeployedDone ? primAverageScores[variable] || 0 : mydata,
            nationalaverage: secAverageScores[variable],
            catId: dimension?.catId || null,
          }
        });
        setChartData(newData);
      }
    } else if (charategry === 'Demographics') {
      if (primaryData && secondaryData) {
        const newData = chartDataTemplate.map(
          ({ name, mydata, description, variable, icon }, index) => ({
            name,
            mydata: isDeployedDone ? primaryData[variable] || 0 : mydata,
            description,
            nationalaverage: secondaryData[variable] || 0,
            icon,
          })
        );
        setChartData(newData);
      }
    }

  }, [primaryData, secondaryData, charategry, activeCard]);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const cardWidth = 288 + 16;
    const scrollPosition = container.scrollLeft;
    const currentIndex = Math.round(scrollPosition / cardWidth);
    setActiveIndex(currentIndex);
  };

  const scrollToCard = (index) => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const cardWidth = 288 + 16;
    const containerWidth = container.offsetWidth;
    const scrollPosition = (cardWidth * index) - (containerWidth - cardWidth) / 2;

    container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      setTimeout(() => scrollToCard(1), 100);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleBarClick = (catId, name) => {
    if (catId && name && (charategry === 'PeopleFlourishing' || charategry === 'ChurchThriving')) {
      navigate(`/dimension-details/${catId}/${encodeURIComponent(name)}`, {
        state: {
          from: comparison ? 'ComparisonBarChart' : 'NonComparisonBarChart',
          isDeployedDone: isDeployedDone,
          isChurch: isChurch,
        },
      });
    } else {
      console.error("No data found for the clicked bar", catId, name, charategry === 'PeopleFlourishing', charategry === 'ChurchThriving');
    }
  };

  return (
    <div className="dimensions-outer-wrapper">
      <div ref={scrollContainerRef} className="seven-dimensions-container">
        <div className="scroll-padding" />
        {chartData?.map((dimension, index) => {
          const { name, icon, mydata, nationalaverage, description, catId } = dimension;
          if (name === 'Deploy Assessment' && !isDeployedDone && charategry === 'Demographics') {
            return (
              <div key={index} className={`seven-dimensions-box ${activeIndex === index ? "activecard" : ""}`}>
                <div className='dimensions-description'>
                  <div className='dimensions-description-title'>
                    <img src={cards} alt="ring" />
                  </div>
                </div>
                <div className='ch-card3-detail-container'>
                  <div>
                    <div className='ch-card3-description'>
                      <p>Learn more about your people’s background.</p>
                    </div>
                    <button className='ch-card3-cta' onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
                      <p className='ch-card3-cta-title'>{isChurch ? "Deploy Assessment" : "Create your Circle"}</p>
                      <img src={demoCTA} alt="scrollerRight" />
                    </button>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div key={index} className={`seven-dimensions-box ${activeIndex === index ? "activecard" : ""}`}>
              <div className='dimensions-description'>
                <div className='dimensions-description-title'>
                  <img src={icon} alt={icon} />
                  <p>{name}</p>
                </div>
              </div>
              {description && <div className='dimensions-description-subtitle'>{description}</div>}
              <div className='ch-card-bar-container' onClick={() => { isDeployedDone && handleBarClick(catId, name) }}>
                <div className='national-avg-card' style={{ margin: comparison ? '0' : '0 auto' }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={(e) => {
                    e.stopPropagation();
                    handleMouseEnter(index);
                  }}
                >
                  <div>
                    <div className="national-avg-card-box">
                      <div
                        style={{ filter: isDeployedDone ? "blur(0px)" : "blur(5px)", height: `${Math.round(mydata) || 0}%` || 0, backgroundColor: isDeployedDone ? '#C581FF' : '#D4D4D4' }}
                        className="percentage-bar"
                      />
                    </div>
                  </div>
                  <div className='bar-percentage' style={{ filter: isDeployedDone ? "blur(0px)" : "blur(5px)", color: isDeployedDone ? comparison ? '#C581FF': '#171717' : '#A3A3A3' }}>
                    {
                      (charategry === 'PeopleFlourishing' || charategry === 'ChurchThriving') ? (Math.ceil(((mydata / 10) || 0) * 10) / 10) : Math.round(mydata) + '%'
                    }
                  </div>

                  {!isDeployedDone && index === hoveredIndex && (
                    <div className='tooltip-container'>
                      <p>Discover how your people are doing by deploying the Church Pulse.</p>
                      <button onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
                         {isChurch ? "Deploy Assessment" : "Create your Circle"} 
                        <img src={ArrowRight} height={11} alt="ArrowRight Icon" />
                      </button>
                    </div>
                  )}
                </div>
                {comparison &&
                  <div className='national-avg-card' onClick={() => { handleBarClick(catId, name) }}>
                    <div>
                      <div className="national-avg-card-box">
                        <div
                          style={{ height: `${Math.round(nationalaverage) || 0}%` }}
                          className="national-percentage-bar"
                        />
                      </div>
                    </div>
                    <div className='bar-percentage'>
                      {
                        (charategry === 'PeopleFlourishing' || charategry === 'ChurchThriving') ? (Math.ceil(((nationalaverage / 10) || 0) * 10) / 10) : Math.round(nationalaverage) + '%'
                      }
                    </div>
                  </div>
                }

              </div>
              {comparison && (
                <div className="lableaboutdata">
                  <div className="mydata">
                    <div
                      className="circle"
                      style={{
                        backgroundColor:
                          !isDeployedDone && comparison
                            ? "#D4D4D4"
                            : "#C581FF",
                      }}
                    ></div>
                    <p className="dataname">{primaryLabel}</p>
                  </div>
                  <div className="nationalaverage">
                    <div className="circle"></div>
                    <p className="dataname">{secondaryLabel}</p>
                  </div>
                </div>
              )}
            </div>
          )
        })}
        <div className="scroll-padding" />

      </div>
      <div className="scroll-indicators">
        {chartData.map((_, index) => (
          <div key={index} className={`scroll-dot ${index === activeIndex ? 'active' : ''}`} onClick={() => scrollToCard(index)} />
        ))}
      </div>
    </div>
  )
}

export default ChurchHealthMobileCards