
export const getAllCircleIds = (circles = [], id) => {
  const getCircleIds = (members = []) => members.filter(member => String(member.id).length > 0).map(member => member.id);
  if ( id === 'all' ) {
    return circles?.flatMap(circle => getCircleIds(circle?.memberOrgs || []))
  } else {
    const circleInfo = circles?.filter(circle => circle.id === id)[0];
    console.log(circleInfo);
    if(circleInfo && circleInfo.length > 0) {
      return getCircleIds(circleInfo.memberOrgs);
    }
    return [];
  }
}

export const navigateToCircles = () => {
  window.location.href = `${process.env.GLOO_URL}/measure/networks/circles`
}