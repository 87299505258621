import { useContext } from "react";
import {
  useDeploymentData,
  useFifteenDimensions,
} from "../../../hooks/useQueryAPI";
import Arrow from "../../../Assests/Images/arrow-right-black.svg";
import { ReactComponent as AiInsightIcon } from "../../../Assests/Images/ChurchHealth/icons/AiInsightsIcon.svg";
import AuthContext from "../../../store/AuthContext";
import { useNavigate } from "react-router-dom";
import { navigateToCircles } from "../../../utils";


const getHighestLowest = (values = []) => {
    let highest = {variable: "", dimensionName: "", averageScore: 0}, lowest = {variable: "", dimensionName: "", averageScore: 100};
    values?.forEach(({averageScore, variable, dimensionName}) => {
        if(highest.averageScore < averageScore) {
            highest = { averageScore, variable, dimensionName };
        }
        if(lowest.averageScore > averageScore ) {
            lowest = { averageScore, variable, dimensionName }; 
        }
    })
    return [lowest, highest];
}

export function AIChurchThriving({isDeployedDone = true , isChurch}) {
    const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { data: fifteenDimensionsData } = useFifteenDimensions(
    authCtx.orgId,
    authCtx.token
  );

  const { data: deploymentData = [] } = useDeploymentData(authCtx.orgId)

  const [lowestThriving = {}, highestThriving = {}] = getHighestLowest(fifteenDimensionsData);

  return (
    <>
      <div className="church-thriving-card">
      <div className="powered-by-wrapper">
            <div className="powered-text-container">
            <span className="sparkle">
              <AiInsightIcon />
            </span>
            {deploymentData.length > 0 && (
              <span className="powered-text">
                powered by <strong className="highlight-blue">GlooAI</strong>
              </span>
            )}
            </div>
            <div className="beta-container">
              Beta
            </div>
          </div>
        {!deploymentData.length > 0 ? (
          <>
            <div className="insights-text">
              Your people rated{" "}
              <span className="highlight-blue">
                {highestThriving?.dimensionName}
              </span>{" "}
              as your greatest strength.
            </div>
          </>
        ) : (
          <div className="insights-text">
            {convertMarkdownToHtml(deploymentData[3])}
          </div>
        )}
      </div>
      <div className="church-thriving-card">
      <div className="powered-by-wrapper">
            <div className="powered-text-container">
            <span className="sparkle">
              <AiInsightIcon />
            </span>
            {deploymentData.length > 0 && (
              <span className="powered-text">
                powered by <strong className="highlight-blue">GlooAI</strong>
              </span>
            )}
            </div>
            <div className="beta-container">
              Beta
            </div>
          </div>
        {!deploymentData.length > 0 ? (
          <>
            <div className="insights-text">
              Your people rated{" "}
              <span className="highlight-blue">
                {lowestThriving?.dimensionName}
              </span>{" "}
              as your greatest opportunity for growth.
            </div>
          </>
        ) : (
          <div className="insights-text">
            {convertMarkdownToHtml(deploymentData[4])}
          </div>
        )}
      </div>
      <div className="church-thriving-card">
      <div className="powered-by-wrapper">
            <div className="powered-text-container">
            <span className="sparkle">
              <AiInsightIcon />
            </span>
            {deploymentData.length > 0 && isDeployedDone && (
              <span className="powered-text">
                powered by <strong className="highlight-blue">GlooAI</strong>
              </span>
            )}
            </div>
            <div className="beta-container">Beta</div>
          </div>
        {deploymentData.length > 0 && isDeployedDone ? (
          <div className="insights-text">
            {convertMarkdownToHtml(deploymentData[5])}
          </div>
        ) : isDeployedDone ? (
          <>
            <div className="insights-text">
              <p className="ai-insights-people-flourishing-insights-text">
                Once we've gathered{" "}
                <span className="highlight-blue">more data</span> we will update
                these with AI insights powered by GlooAI.
              </p>
            </div>
          </>
        ) : (
          <div>
            <p className="ai-insights-people-flourishing-insights-text">
                Discover your <span className="highlight-blue">own</span>{" "}
                insights.
              </p>
            <button
              className="ai-insights-people-flourishing-button"
              onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}
            >
               <p> { isChurch ? "Deploy Assessment" : "Create your Circle"}</p>
              <img src={Arrow} alt="Arrow Icon" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

const convertMarkdownToHtml = (text) => {
  if (!text) return null;

  const parts = text.split("**");
  return parts.map((part, index) => {
    return index % 2 === 0 ? (
      <span key={index}>{part}</span>
    ) : (
      <b className="highlight-blue" key={index}>
        {part}
      </b>
    );
  });
};
