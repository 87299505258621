import React, { useContext, useState, useEffect, useRef } from "react";

import Header from "../../components/ChurchHealth/Header/Header";
import CreateDeployAssessment from "../../components/ChurchHealth/CreateDeployAssessment/CreateDeployAssessment";
import FilterCard from "../../components/FilterCard/FilterCard";
import PeopleFlourishing from "../../components/ChurchHealth/PeopleFlourishing/PeopleFlourishing";
import GrowthGoals from "../../components/GrowthGoals/GrowthGoals";
import ChurchThriving from "../../components/ChurchHealth/ChurchThriving/ChurchThriving";
import Demographics from "../../components/ChurchHealth/Demographics/Demographics";
import Banner from "../../components/ChurchHealth/Banner/Banner";
import ChurchHealthMobile from '../../components/ChurchHealthMobileDeploy/ChurchHealthMobile';
import {
  useChurchHealthDemographicsData,
  useChurchHealthGrowthGoals,
  useNationalAverage,
} from "../../hooks/useQueryAPI";
import { useNavigate } from "react-router-dom";
import { useChartData } from "../../hooks/useQueryAPI";
import AuthContext from "../../store/AuthContext";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import BannerNetwork from "../../components/ChurchHealth/BannerNetwork/BannerNetwork";
import { getAllCircleIds, navigateToCircles } from "../../utils";

const dummyData = {
  sevenDimensions: [
    { variable: "cphealth", average_score: 29 },
    { variable: "cpcontent", average_score: 30 },
    { variable: "cprelation", average_score: 45 },
    { variable: "cppurpose", average_score: 32 },
    { variable: "cpspiritual", average_score: 54 },
    { variable: "cpcharacter", average_score: 75 },
    { variable: "cpfinance", average_score: 19 },
  ],
  growthGoals: [
    { score: 1, percentage: 15 },
    { score: 2, percentage: 20 },
    { score: 3, percentage: 25 },
    { score: 4, percentage: 84 },
    { score: 5, percentage: 54 },
    { score: 6, percentage: 34 },
    { score: 7, percentage: 98 },
  ],
  fifteenDimensions: [
    {
      variable: "cpcommunity",
      dimensionName: "Connected Community",
      average_score: 32,
    },
    {
      variable: "cpworship",
      dimensionName: "Worship Experience",
      average_score: 34,
    },
    {
      variable: "cptrusted",
      dimensionName: "Trusted Leaders",
      average_score: 84,
    },
    {
      variable: "cpbible",
      dimensionName: "Bible Centeredness",
      average_score: 27,
    },
    {
      variable: "cpformation",
      dimensionName: "Spiritual Formation",
      average_score: 48,
    },
    {
      variable: "cpprayer",
      dimensionName: "Prayer Culture",
      average_score: 63,
    },
    { variable: "cpfaith", dimensionName: "Faith Sharing", average_score: 44 },
    {
      variable: "cpserving",
      dimensionName: "Serving Others",
      average_score: 74,
    },
    {
      variable: "cpsteward",
      dimensionName: "Holistic Stewardship",
      average_score: 34,
    },
    {
      variable: "cpleaddev",
      dimensionName: "Leadership Development",
      average_score: 84,
    },
    { variable: "cpimpact", dimensionName: "Social Impact", average_score: 31 },
    {
      variable: "cpfuture",
      dimensionName: "Future Focused",
      average_score: 21,
    },
    { variable: "cpdata", dimensionName: "Data Informed", average_score: 45 },
    {
      variable: "cpresource",
      dimensionName: "Resource Stability",
      average_score: 97,
    },
    { variable: "cpteam", dimensionName: "Team Health", average_score: 10 },
  ],
  demographics: {
    "cpmarriage": "40.0000",
    "cpdivorced": "30.0000",
    "cpparenthood": "24.0000",
    "cpsenior": "85.0000"
  }
};

const ChurchHealthDeploy = ({isChurch}) => {
  const [appliedFilters, setAppliedFilters] = useState({
    timeline: "allTime",
    dataset: "National Average",
  });
  const [isFilterSticky, setIsFilterSticky] = useState(false);
  const bannerRef = useRef(null);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { windowWidth } = useWindowDimensions();

  const getCircleIds = (filters = {}) => filters?.dataset === "Circles" ? getAllCircleIds(authCtx?.circles || [], filters?.circleType) : [];

  const { error, isLoading, isError, data } = useChartData({
    orgIds: [authCtx.orgId, ...getCircleIds(appliedFilters)],
    dataset: appliedFilters?.dataset,
    timeline: appliedFilters?.timeline,
    age: appliedFilters?.ageRange || "",
    gender: appliedFilters?.gender || "",
  });

  const { data: growthGoalsData } = useChurchHealthGrowthGoals({
    orgIds: [authCtx.orgId, ...getCircleIds(appliedFilters)],
    dataset: appliedFilters?.dataset,
    timeline: appliedFilters?.timeline,
    age: appliedFilters?.ageRange || "",
    gender: appliedFilters?.gender || "",
  });

  const { data: demographicsData } = useChurchHealthDemographicsData({
    orgIds: [authCtx.orgId, ...getCircleIds(appliedFilters)],
    dataset: appliedFilters?.dataset,
    timeline: appliedFilters?.timeline,
    age: appliedFilters?.ageRange || "",
    gender: appliedFilters?.gender || "",
  });

  useEffect(() => {
    let filters = {}
    try {
      filters = JSON.parse(localStorage.getItem("church-health-fitlers"));
      if (filters) {
        if (filters?.appliedFiltersSecondary) {
          setAppliedFilters(filters.appliedFiltersSecondary)
        } else {
          setAppliedFilters({
            timeline: "allTime",
            dataset: "National Average",
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("church-health-fitlers", JSON.stringify({ appliedFiltersPrimary: null, appliedFiltersSecondary: appliedFilters, comparison: true }));
  }, [appliedFilters])

  useEffect(() => {
    const handleScroll = () => {
      if (!bannerRef.current){
        return;
      }
      const bannerBottomOffset = bannerRef.current.getBoundingClientRect().bottom;
      if (bannerBottomOffset <= 95) {
        setIsFilterSticky(true);
      } else {
        setIsFilterSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);  

  return windowWidth < 560 ? (
    <ChurchHealthMobile
      comparison={true}
      isDeployedDone={false}

      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}

      peopleFlourishingprimaryData={dummyData["sevenDimensions"] || []}
      peopleFlourishingsecondaryData={data?.data?.[appliedFilters.timeline] || []}

      growthGoalsPrimaryData={dummyData?.["growthGoals"] || []}
      growthGoalsSecondaryData={growthGoalsData || []}

      demographicsprimaryData={dummyData["demographics"] || null}
      demographicssecondaryData={demographicsData || 0}

      dimensionCatIds={data?.data?.["dimensionCatIds"]}

      primaryLabel={'My Data'}
      secondaryLabel={appliedFilters["dataset"]}
      isChurch={isChurch} 
    />
  ) : (
    <div className="church-health-deploy">
      <Header />
      <div ref={bannerRef} className="church-health__banner">
        {isChurch ? <Banner /> : <BannerNetwork />}
        
      </div>
      <div className="filters-container">
         <div className={`church-health__Filters-Section ${isFilterSticky ? "church-health-deploy-sticky" : ""}`} >
        <CreateDeployAssessment
          onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}
          isChurch={isChurch}
        />
        <FilterCard
          filterType="rightfilter"
          isDeployedDone={false}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          comparison={true}
          primaryColor="#D4D4D4"
        />
      </div>
      </div>
      <div className="church-health__people-flourishing" style={{ marginTop:isFilterSticky ? '135px':'0'}}>
        <PeopleFlourishing
          isDeployedDone={false}
          primaryData={dummyData["sevenDimensions"]}
          secondaryData={data?.data?.[appliedFilters.timeline] || []}
          comparison={true}
          primaryColor="#D4D4D4"
          dimensionCatIds={data?.data?.["dimensionCatIds"]}
          deploymentCardData={[]}
          primaryLabel={'My Data'}
          secondaryLabel={appliedFilters["dataset"]}
          isChurch={isChurch}
        />
      </div>
      <div className="church-health-growthGoals">
        <GrowthGoals
          isDeployedDone={false}
          primaryData={dummyData?.["growthGoals"] || []}
          secondaryData={growthGoalsData || []}
          comparison={true}
          primaryColor="#D4D4D4"
          dimensionCatIds={data?.data?.["dimensionCatIds"]}
        />
      </div>
      <div className="church-health__thriving">
        <ChurchThriving
          isDeployedDone={false}
          primaryData={dummyData["fifteenDimensions"]}
          secondaryData={data?.data?.[appliedFilters.timeline] || []}
          comparison={true}
          primaryColor="#D4D4D4"
          dimensionCatIds={data?.data?.["dimensionCatIds"]}
          primaryLabel={'My Data'}
          secondaryLabel={appliedFilters["dataset"]}
          isChurch={isChurch}
        />
      </div>
      <div className="church-health-Demographics">
        <Demographics
          isDeployedDone={false}
          primaryData={dummyData["demographics"] || null}
          secondaryData={demographicsData}
          primaryColor="#D4D4D4"
          comparison={true}
          primaryLabel={'My Data'}
          secondaryLabel={appliedFilters["dataset"]}
          appliedFiltersPrimary={null}
          appliedFilters={appliedFilters}
          isChurch={isChurch}
        />
      </div>
    </div>
  );
};

export default ChurchHealthDeploy;
