import { useEffect, useState, useContext, useMemo } from "react";
import { IconButton } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Checkbox from '../../Assests/Images/Uncheckboxes.png';
import Uncheckbox from '../../Assests/Images/Checkboxes.png';
import allcheckbox from '../../Assests/Images/AllCheckboxes.png'
import { Link, useNavigate } from "react-router-dom";

import AuthContext from '../../store/AuthContext';
// import { useDashboardContext } from "../../store/DashboardContext";
import Api from '../../components/api/api';

function Organization() {

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const allApiData = Api();
  const [searchOrg, setSearchOrg] = useState("");
  // const dashboardCtx = useDashboardContext();

  const [selectedIds, setSelectIds] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [organizations, setOrganization] = useState([]);

  useEffect(() => {
    let assessmentId = 86;
    fetchOrganizations(assessmentId).then(data => setOrganization(data?.data));
  }, [])

  const fetchOrganizations = async (assessmentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v4/organisation/byClient/${assessmentId}`, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        return data; 
      } else {
        console.error("Failed to fetch insights data");
        return null;
      }
    } catch (err) {
      console.error("Error fetching insights data...", err);
      throw err;
    }
  }

  const toggleCheckbox = (id) => {
      let x = [...selectedIds];
      if (x.includes(id)) {
        x = x.filter(_id => _id !== id);
      }
      else {
        x = [...x, id]
      }
      setSelectIds(x);
  };

  const filteredOrganizations = useMemo(() => {
    if ( !Boolean(searchOrg) || searchOrg.trim().length === 0 ) {
      return organizations;
    }

    const fOrg = []
    organizations.forEach((org) => {
      if (org.organisation.toLowerCase().includes(searchOrg.toLowerCase())) {
        fOrg.push(org);
      }
    });
    return fOrg;
  }, [organizations, searchOrg]);


  const totalPages = Math.ceil(filteredOrganizations.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredOrganizations.length);

  const displayedOrganizations = filteredOrganizations.slice(startIndex, endIndex);
  const isAllSelected = selectedIds.length === displayedOrganizations.length;
  
  const handleRowUpdates = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  }

  const toggleAll = () => {
    if (selectedIds.length === displayedOrganizations?.length) {
        setSelectIds([])
    }
    else {
        setSelectIds(() => displayedOrganizations.map(org => org.gloo_id))
    }
  }

  const authorizeOrg = (id, glooId, name) => {
    allApiData.clearData();
    console.log("We get here");
    authCtx.update(0, glooId, name, id, "stephen@studio137.co.za", "Stephen", "church", []);
    
    fetch(`${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${id}/deployments`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == 'success') {
        if(data.data.length > 0) {
          navigate('/home');
          localStorage.setItem('currentorddelopment', true);
        } else {
          navigate('/deploy');
          localStorage.setItem('currentorddelopment', false);
        }
      }
    }).catch(err => {
      console.error(err)
      
    });    

    // navigate(`/https://app.dev.gloo.us/oauth/authorize?client_id=${id}.1a70217fa43cdef82ae21c99edb23ed5&redirect_uri=https://dashboard.preview.survey.church/authenticate&response_type=code`)
  }

  return (
    <div style={{padding: 20}}>
      <input type="text" placeholder="Search Organizations" style={{padding: 10, borderRadius: 10}} value={searchOrg} onChange={(ev) => {
        setSearchOrg(ev.target.value)
      }} />
      <div className="table-container">
        <div className='organization-table'>
          <div className='header-row'>
            <div className='cell checkbox-column' style={{ padding: 0 }}>
              <div className='cell checkbox-column' onClick={() => toggleAll()}>
                {displayedOrganizations.length > 0 && <img height={16} width={16} src={isAllSelected ? allcheckbox : Checkbox} alt={selectedIds.length === displayedOrganizations.length ? "Checked" : "Unchecked"} />}
              </div>
            </div>
            <div className='cell name-column'>Name </div>
            <div className='cell url-column'>Gloo Id</div>
            <div className='cell url-column'>Deployments</div>
            <div className='cell url-column'>Respondents</div>
            {/* <div className='cell link-column'>Link</div> */}
            <div className='cell actions-column'></div>
          </div>
          {displayedOrganizations.length === 0 ? (
            <p className='no-data-message'>No data available</p>
          ) : (
            displayedOrganizations.map((organization, index) => (
              <div key={index} className='row'>
                <div className='cell checkbox-column' onClick={() => toggleCheckbox(organization.glooId)}>
                  <img height={16} width={16} src={selectedIds.includes(organization.glooId) ? Uncheckbox : Checkbox} alt={selectedIds.includes(organization.glooId) ? "Checked" : "Unchecked"} />
                </div>
                <a style={{ textDecoration: "none" }} className='cell name-column' onClick={() => authorizeOrg(organization.id, organization.glooId, organization.organisation)} >{organization.organisation}</a>
                <div className='cell url-column'>{ organization.glooId }</div>
                <div className='cell url-column'>{ organization?.deploymentCount }</div>
                <div className='cell url-column'>{ organization?.respondentCount }</div>
                {/* <div className='cell link-column'>
                  <a href={organization.link} target="_blank">Link</a>
                </div> */}
              </div>
            ))
          )}

          <div className="pagination">
            <div className='items-perpage'>
              <p>Rows per page</p>
              <div className='dropdown-container'>
                <select
                  className="rows-dropdown"
                  value={itemsPerPage}
                  onChange={(e) => handleRowUpdates(e)}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
                <ExpandMoreOutlinedIcon className="dropdown-icon" sx={{ color: '#737373' }} />
              </div>
              <p>Showing {startIndex + 1} - {endIndex} of {startIndex+displayedOrganizations.length}</p>
            </div>
            <div className='right-btns'>
                <IconButton 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    disabled={currentPage === 1} 
                    className='btn previous'
                >
                <ChevronLeft sx={{ fontSize: "16px" }} />
              </IconButton>
              {currentPage > 1 && <span>{currentPage - 1}</span>}
              <span className="current">{currentPage}</span>
              {currentPage < totalPages && <span>{currentPage + 1}</span>}
              <IconButton 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages} 
                className='btn next'>
                <ChevronRight sx={{ fontSize: "16px" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Organization;
