
export function Header() {
    return (
        <div className="church-health-header-container">
          <div className="mobile-header-container">
            <div className="mobile-header-text">
              <p>Church Health</p>
            </div>
          </div>
        </div>
    )
}
