import React from "react";
import Illustration from "../../../Assests/Images/Illustration.png"
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className="banner">
      <div className="banner__content">
        <div className="banner__text">
          <div>
            Find out how <span>your</span> congregants are doing
          </div>
          <p>
            Church leaders nationwide are measuring what matters through the State of the Church Initiative. 
            Explore real-time national results below and compare them to your own Church's data with the 
            Church Pulse Assessment.
          </p>
          <button className="banner-btn" onClick={() => navigate("/assessments/details")}>
            Deploy Assessment
          </button>
        </div>
        <div className="banner__image">
          <img src={Illustration} className="banner__banner-image" alt="banner"/>
        </div>
      </div>
    </div>
  );
};

export default Banner;