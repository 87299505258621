import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LeftArrow from "../../Assests/Images/leftarrow.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useByCategoryData } from "../../hooks/useQueryAPI";
import About from "../../Assests/Images/aboutblack.svg";
import {
  ShimmerDiv,
  ShimmerTitle,
  ShimmerBarChart,
} from "shimmer-effects-react";
import AuthContext from "../../store/AuthContext";

import recommendedData from "../../Assests/data/RecommendedResourceUpdated.json"
import { ReactComponent as DimensionDetailCards } from "../../Assests/Images/dimensionDetailCards.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ArrowRight from "../../Assests/Images/arrow-right-white.svg";
import RecommendedSection from "../../components/RecommendedSection/RecommendedSection";
import { getAllCircleIds, navigateToCircles } from "../../utils";

const DimensionDetail = () => {
  const authCtx = useContext(AuthContext);
  const { id: catId, groupName } = useParams();
  const [chartData, setChartData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [QuestionNo, SetQuesstionNo] = useState(0);
  const [questionData, setQuestionData] = useState([]);
  // const [isLoading, setLoading] = useState(true);
  const [shouldSetHeight, setShouldSetHeight] = useState(0);
  const [isHover, setHover] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndexTooltip, setHoveredIndexTooltip] = useState(null);
  // console.log("churchData", churchData);
  const navigate = useNavigate();
  const location = useLocation();
  const { windowWidth } = useWindowDimensions();
  const { from, urldata, isDeployedDone = false, isChurch, comparison } =
    location.state || {};
  const isComparisonBarChart = from === "ComparisonBarChart";

  const [filters, setFilters] = useState({
    appliedFiltersPrimary: {},
    appliedFiltersSecondary: {},
  });

  useEffect(() => {
    let filters = {};
    try {
      filters = JSON.parse(localStorage.getItem("church-health-fitlers"));
      if (filters) {
        setFilters(filters);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getCircleIds = (filters = {}) => filters?.dataset === "Circles" ? getAllCircleIds(authCtx?.circles || [], filters?.circleType) : [];
  
  const {
    data: primaryData = [],
    isLoading: isPrimaryLoading,
    isError: isPrimaryError,
  } = useByCategoryData(
    { filter: { ...filters?.appliedFiltersPrimary, orgIds: [authCtx.orgId, ...getCircleIds(filters?.appliedFiltersPrimary || {})] }, catId },
    isDeployedDone
  );

  const {
    data: secondaryData = [],
    isLoading: isSecondaryLoading,
    isError: isSecondaryError,
  } = useByCategoryData(
    { filter: { ...filters?.appliedFiltersSecondary, orgIds: [authCtx.orgId, ...getCircleIds(filters?.appliedFiltersSecondary || {})] }, catId },
    isComparisonBarChart
  );

  if ( (primaryData.length > 0 || secondaryData.length > 0) && !isPrimaryLoading && !isSecondaryLoading && questionData.length === 0 ) {
    setQuestionData(primaryData.length > 0 ? primaryData : secondaryData);
  }

  useEffect(() => {   
    if (!isPrimaryLoading && !isSecondaryLoading) {
      const chartDataOne = Object?.values(
        primaryData?.[QuestionNo]?.answerCount || {}
      );
      const chartDataTwo = Object?.values(
        secondaryData?.[QuestionNo]?.answerCount || {}
      );

      const chartData = combineChartData(
        chartDataOne,
        chartDataTwo
      );
      const normalizedData = normalizeHeights(chartData);
      setChartData(normalizedData);
      console.log("chartData", normalizedData);
      checkQuestionLength();
      console.log("shouldSetHeight : " + shouldSetHeight);
    }
  }, [QuestionNo, isPrimaryLoading, isSecondaryLoading]);

  const normalizeHeights = (data) => {

    const totalSecondaryData = data.reduce(
      (sum, item) => sum + (item.secondaryData || 0),
      0
    );
    const totalPrimaryData = data.reduce(
      (sum, item) => sum + (item.primaryData || 0),
      0
    );

    const maxData = Math.max(totalSecondaryData, totalPrimaryData);
    return data.map((item) => {
      let [primaryHeight, secondaryHeight] =
        maxData <= 100
          ? [
              parseFloat(((item.primaryData / 100) * 100).toFixed(1)),
              parseFloat(((item.secondaryData / 100) * 100).toFixed(1)),
            ]
          : [
              parseFloat(((item.primaryData / maxData) * 100).toFixed(1)),
              parseFloat(((item.secondaryData / maxData) * 100).toFixed(1)),
            ];

      if (!isDeployedDone && secondaryHeight === 0) {
        primaryHeight = 0;
      }

      return {
        ...item,
        normalizedPrimaryHeight: item.primaryData > 0 ? Math.max(primaryHeight, 0.1) : 0,
        normalizedSecondaryHeight: item.secondaryData > 0 ? Math.max(secondaryHeight, 0.1) : 0,
      };
    });
  };

  const combineChartData = (chartDataOne = [], chartDataTwo = []) => {

    const chartMapper = chartDataOne.length > 0 ? chartDataOne : chartDataTwo;
    const randomChartData = chartDataTwo.map((item) => Math.floor(Math.random() * Math.max(...chartDataTwo)) + 1);

    return chartMapper.map((data, index) => {
      const primaryData = isDeployedDone ? chartDataOne?.[index] || 0 : randomChartData[index];
      const secondaryData = chartDataTwo?.[index] || 0;
      return { primaryData, secondaryData }
    });
  };

  const dimensionName = decodeURIComponent(groupName || "")
    .trim()
    .toLowerCase();

  const dimensionInfo = () => {
    if (dimensionName === "spiritual") {
      return "The Spiritual dimension is a high-level rating of spiritual vitality as measured by the desire to place Jesus first in one’s life, and the belief in the authority of the Bible. We often refer to these two items as “the centrality of Christ” and “the centrality of Scripture.”";
    } else if (dimensionName === "relationships") {
      return "The Relationships dimension looks at how satisfied and content one is with their friendships and close relationships in general. Overall, it measures whether one’s expectations of their closest relationships are being met.";
    } else if (dimensionName === "purpose") {
      return "The Purpose dimension looks at the experience of purpose and meaning in one’s daily activity, whether at work, home or during one’s free time. It looks beyond occupation to one’s place and role in the world.";
    } else if (dimensionName === "finances" || dimensionName === "finance") {
      return "The Finances dimension of flourishing measures the degree to which one worries about meeting monthly living expenses and whether or not food, safety or housing emerge as ongoing stressors. Measuring worry is a sound place to start when desiring to measure financial well-being.";
    } else if (dimensionName === "wellness") {
      return "The Wellness dimension looks at how one rates both their physical and overall mental health.";
    } else if (dimensionName === "fulfillment") {
      return "The Fulfillment dimension looks at a holistic view of one’s happiness and the satisfaction they feel in their life.";
    } else if (dimensionName === "virtue") {
      return "The Virtue dimension looks at one’s overall response in the world and how they engage with culture around them.";
    } else if (dimensionName === "connected community") {
      return "The Connected Community dimension looks at how connected individuals feel to their church community and whether they are forming meaningful Christian relationships that are beyond surface-level.";
    } else if (dimensionName === "worship experience") {
      return "The Worship Experience dimension provides insight into how the main worship service is helping congregants feel more connected with God and experience His presence. The wording of these two questions is designed to be relevant for all service styles.";
    } else if (dimensionName === "prayer culture") {
      return "The Prayer Culture dimension asks congregants to rate their development of healthy prayer habits as well as their experience of the power of prayer at their church.";
    } else if (dimensionName === "bible centeredness") {
      return "The Bible Centeredness dimension measures how well the church is helping congregants understand foundational biblical teachings and live them out in their everyday life (application of biblical knowledge).";
    } else if (dimensionName === "spiritual formation") {
      return "In the Spiritual Formation dimension, congregants rate the extent to which their church provides a clear pathway for ongoing growth and functions as a key partner in their spiritual formation.";
    } else if (dimensionName === "trusted leaders") {
      return "In the Trusted Leaders dimension, congregants rate their level of trust in church leadership and the extent to which they feel they receive clear communication. This dimension measures both trust and connectedness between church institution and congregant.";
    } else if (dimensionName === "faith sharing") {
      return "The Faith Sharing dimension looks at how a church is equipping and encouraging congregants to talk about and share their faith in Jesus. This dimension may be referred to as the evangelism or evangelization dimension.";
    } else if (dimensionName === "serving others") {
      return "The Serving Others dimension measures how a church is empowering congregants to make an impact on the lives of others and the extent to which congregants are actually taking time to serve and help others in the community. Note that the first item measures one’s personal priorities and does not mention the church.";
    } else if (dimensionName === "holistic stewardship") {
      return "The Holistic Stewardship dimension measures how well a church is helping congregants embrace their calling and use their gifts as well as their resources to serve God and others. Stewardship activation beyond financial giving is a key element of church thriving.";
    } else if (dimensionName === "leadership development") {
      return "The Leadership Development dimension looks at how clear a church’s leadership training pathway is and the degree to which a church empowers young people to become leaders. Equipping the next generation for leadership is a key element of church thriving.";
    } else if (dimensionName === "social impact") {
      return "The Social Impact dimension measures how involved a church is in addressing social injustices as they provide opportunities for congregants to help those who are suffering or marginalized.";
    } else if (dimensionName === "future focused") {
      return "The Future Focused dimension asks leadership to rate the clarity of their vision for the future and the extent to which they feel the next generation is essential to their path forward.";
    } else if (dimensionName === "data informed") {
      return "The Data Informed dimension is about having good systems in place to know and track attendees and the data needed to make well-informed ministry decisions. Church leadership cultures that normalize thoughtful, data-informed decision making tend to be healthier overall.";
    } else if (dimensionName === "resource stability") {
      return "The Resource Stability dimension looks at two key facets of resources: financial optimism and having enough volunteers and leaders to operate effectively (leadership personnel).";
    } else if (dimensionName === "team health") {
      return "The Team Health dimension screens for role clarity and internal trust amongst the leadership.";
    } else if (dimensionName === "faith") {
      return "The Faith dimension is a high-level rating of spiritual vitality as measured by the desire to place Jesus first in one’s life, and the belief in the authority of the Bible. We often refer to these two items as “the centrality of Christ” and “the centrality of Scripture.”";
    }
  };

  function removeBrTags(text) {
    return text ? text?.replace(/<br\s*\/?>/gi, "") : "";
  }

  const handleBack = () => {
    const { from = "", deploymentId = null } = location?.state || {};
    from === "flourishing"
      ? navigate(`/assessments/details/${deploymentId}`, { state: { from } })
      : navigate(-1);
  };

  const checkQuestionLength = () => {
    const exceedsLimit = primaryData.some((item) => {
      const questionLength = item?.question?.length || 0;
      return questionLength > 62;
    });
    setShouldSetHeight(exceedsLimit);
  };

  const getTileStatement = (question) => {
    let lower = 0,
      middle = 0,
      higher = 0;

    Object.values(question?.answerCount || {})?.forEach((count, index) => {
      if (index < 4) {
        lower += count;
      } else if (index < 7) {
        middle += count;
      } else if (index < 11) {
        higher += count;
      }
    });

    let answer = "";
    if (lower > middle && lower > higher) {
      answer = removeBrTags(question?.answers?.[0]?.desktop_text);
    } else if (middle > lower && middle > higher) {
      answer = removeBrTags(question?.answers?.[5]?.desktop_text);
    } else {
      answer = removeBrTags(question?.answers?.[10]?.desktop_text);
    }

    if (answer === "yes") {
      answer = "Strongly Disagree";
    } else if (answer === "no") {
      answer = "Strongly Agree";
    }

    let result = "";
    if (
      answer.toLowerCase().includes("worry") ||
      answer.toLowerCase().includes("strongly")
    ) {
      result = answer;
    } else {
      result = "are " + answer;
    }

    return result;
  };

  const formatAiInsight = (text) => {
    if (!text) return "";

    const highLightColor = isDeployedDone ? "#851DD3" : "#008358";
    const paragraphColor = isDeployedDone ? "#AF51FB" : "#00C980";

    // Replace **text** with <span class='highlight'>text</span>
    const formattedText = text.replace(
      /\*\*(.*?)\*\*/g,
      `<span class='highlight' style='color: ${highLightColor}'>$1</span>`
    );

    // Wrap the entire text in <span class='paragraph'>
    return `<span class='paragraph' style='color: ${paragraphColor}'>${formattedText}</span>`;
  };

  // ToolTip
  const TooltipComponent = ({ isVisible, isChurch }) => {
    if (!isVisible) return null;

    return (
      <div className="customtootip-container">
        <div className="customtootip">
          <p>
            Discover how your people are doing by deploying the ChurchPulse.
          </p>
          <button onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
            {isChurch ? "Deploy Assessment" : "Create your Circle"}{" "}
            <img src={LeftArrow} alt="" />
          </button>

          {/* Tooltip arrow */}
          <div
            style={{
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid #ffffff",
            }}
          />
        </div>
      </div>
    );
  };

  //Recommended Resources
  const formattedDimensionName = dimensionName.replace(/\s+/g, '-').toLowerCase();
  const filteredData = recommendedData.find((item) =>
      item.Dimensions_name.includes(formattedDimensionName)
  );

  const handleMouseEnter = (index) => {
    setHoveredIndexTooltip(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndexTooltip(null);
  };

  const isLoading = isPrimaryLoading || isSecondaryLoading;

  return (
    <div className="dimensiondetail-container">
      <div className="heading">
        <KeyboardBackspaceIcon
          onClick={handleBack}
          sx={{ cursor: "pointer" }}
        />
        <h1>{decodeURIComponent(groupName)}</h1>
      </div>
      {!isLoading ? (
        <div className="paragraph-container">
          <div className="para-heading">
            <img src={About} alt="" />
            <p>About this Dimension</p>
          </div>
          <div className="paragraph">
            <p>{dimensionInfo()}</p>
          </div>
        </div>
      ) : (
        <div style={{ opacity: isLoading ? "50%" : "100%" }}>
          <ShimmerDiv mode="light" height={160} width={1046} rounded={2} />
        </div>
      )}

      <div
        className="question-buttons-container"
        style={{ opacity: isLoading ? "50%" : "100%" }}
      >
        {!isLoading && Array.isArray(questionData) ? (
          questionData?.map((item, index) => {
            const adjustedIndex = questionData[0]?.questionNo === 0 ? 1 : 0;
            return (
              <button
                key={index}
                className={`question ${index === QuestionNo ? "active" : ""}`}
                onClick={() => {
                  SetQuesstionNo(index);
                }}
              >
                Question {item?.questionNo + adjustedIndex}
              </button>
            );
          })
        ) : (
          <>
            <ShimmerDiv mode="light" height={45} width={120} rounded={2} />
            <ShimmerDiv mode="light" height={45} width={120} rounded={2} />
            <ShimmerDiv mode="light" height={45} width={120} rounded={2} />
          </>
        )}
      </div>

      {/* <div className="right-container-heading">
        {!isLoading ? (
          <>
            <div className="questions-number">
              Question {churchData[QuestionNo]?.questionNo}
            </div>
            <div>
              <div
                className="question-quote"
                dangerouslySetInnerHTML={{
                  __html: churchData[QuestionNo]?.question || "",
                }}
              ></div>
            </div>
          </>
        ) : (
          <ShimmerTitle mode="light" line={3} gap={3} />
        )}
      </div> */}
      <div className="chart-container">
        {!isLoading ? (
          <div
            className="left-container"
            style={{
              backgroundColor: isDeployedDone ? "#F9F5FE" : "#F6FEF9",
              borderColor: isDeployedDone ? "#C581FF" : "#008358",
            }}
          >
            <div className="img">
              <div className="container-imageBeta">
                  <DimensionDetailCards
                    fill={isDeployedDone ? "#AF51FB" : "#008358"}
                    alt="card-image"
                  />
                  <div className="beta-container">Beta</div>
             </div>
            </div>
            {questionData[QuestionNo] && questionData[QuestionNo].aiInsight ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: formatAiInsight(questionData[QuestionNo]?.aiInsight),
                }}
              />
            ) : (
              <p
                className="paragraph"
                style={{ color: isDeployedDone ? "#AF51FB" : "#00C980" }}
              >
                The{" "}
                <span
                  className="highlight"
                  style={{ color: isDeployedDone ? "#851DD3" : "#008358" }}
                >
                  majority
                </span>{" "}
                of {isDeployedDone && "your"} people
                <span
                  className="highlight"
                  style={{ color: isDeployedDone ? "#851DD3" : "#008358" }}
                >
                  {" "}
                  {getTileStatement(questionData[QuestionNo])}{" "}
                </span>
                {/* regarding the question:
                <br />
                <span
                  className="highlight"
                  style={{
                    fontSize: "24px",
                    color: isDeployedDone ? "#851DD3" : "#008358",
                  }}
                >
                  {questionData[QuestionNo]?.question || ""}
                </span> */}
              </p>
            )}
          </div>
        ) : (
          <div style={{ opacity: isLoading ? "50%" : "100%" }}>
            <ShimmerDiv
              mode="light"
              height={532}
              width={320}
              rounded={2}
              style={{ opacity: 0.5 }}
            />
          </div>
        )}

        <div
          className="right-container"
          style={{
            opacity: isLoading ? "50%" : "100%",
            width: windowWidth > 550 ? "70%" : "100%",
          }}
        >
          <div className="right-container-heading">
            {!isLoading ? (
              <>
                <div className="questions-number">
                  Question {questionData[QuestionNo]?.questionNo}
                </div>
                <div>
                  <div
                    className="question-quote"
                    dangerouslySetInnerHTML={{
                      __html: questionData[QuestionNo]?.question || "",
                    }}
                    style={{ height: windowWidth > 560 ? "70px" : "auto" }}
                  ></div>
                </div>
              </>
            ) : (
              <ShimmerTitle mode="light" line={3} gap={3} />
            )}
          </div>
          {!isLoading ? (
            <>
              <div className="chartbar-container">
                {chartData?.map((data, index) => {
                  const {
                    name,
                    primaryData,
                    secondaryData,
                    normalizedPrimaryHeight,
                    normalizedSecondaryHeight,
                  } = data;
                  return (
                    <div className="chart" key={index}>
                      <div
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          opacity:
                            hoveredIndex !== null
                              ? hoveredIndex === index
                                ? 1
                                : 0.5
                              : 1,
                        }}
                        className="chart-bar"
                      >
                        <div className="databar-one">
                          <span
                            className="databar-one-percentage"
                            style={{
                              filter:
                                !isDeployedDone && isComparisonBarChart
                                  ? "blur(5px)"
                                  : "none",
                              color:
                                !isDeployedDone && isComparisonBarChart
                                  ? "#D4D4D4"
                                  : isComparisonBarChart
                                  ? "#C581FF"
                                  : "#171717",
                            }}
                          >
                            {primaryData || 0}
                          </span>
                          <div
                            className={`dataheightone-container ${
                              !comparison && "comparison-inactive"
                            }`}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={(e) => {
                              e.stopPropagation();
                              handleMouseEnter(index);
                            }}
                          >
                            <div
                              style={{
                                height: `${normalizedPrimaryHeight || 0}%`,
                                backgroundColor:
                                  !isDeployedDone && isComparisonBarChart
                                    ? "#D4D4D4"
                                    : "#C581FF",
                                filter:
                                  !isDeployedDone && isComparisonBarChart
                                    ? "blur(5px)"
                                    : "none",
                              }}
                              className="dataheightone"
                            />
                            {!isDeployedDone &&
                              index === hoveredIndexTooltip && (
                                <div className="tooltip-container">
                                  <p>
                                    Discover how your people are doing by
                                    deploying the Church Pulse.
                                  </p>
                                  <div className='btn-box'>
                                    <button className="tool-tip-btn" onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
                                      {isChurch ? "Deploy Assessment" : "Create your Circle"}
                                      <img src={ArrowRight} height={11} alt="ArrowRight Icon" />
                                    </button>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        {comparison && (
                          <div className="databar-two">
                            {isComparisonBarChart && (
                              <span className="databar-two-percentage">
                                {secondaryData || 0}
                              </span>
                            )}
                            <div className="dataheighttwo-container">
                              <div
                                style={{
                                  height: `${normalizedSecondaryHeight || 0}%`,
                                }}
                                className="dataheighttwo"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="chartlabel">{name}</div>
                    </div>
                  );
                })}
              </div>
              <div className="right-container-footer">
                {/* <p style={{ textAlign: 'left', marginLeft: "2px", width: 'calc(100% / 3 - 10px)' }}> <span dangerouslySetInnerHTML={{ __html: churchData[QuestionNo]?.answers[0]?.desktop_text }} /> </p> */}
                <p
                  style={{
                    textAlign: "left",
                    marginLeft: "2px",
                    width: "calc(100% / 3 - 10px)",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: removeBrTags(
                      questionData[
                        QuestionNo
                      ]?.answers[0]?.desktop_text?.toLowerCase() === "yes"
                        ? "Strongly Disagree"
                        : questionData[QuestionNo]?.answers[0]?.desktop_text
                    ),
                  }}
                />

                <p
                  style={{
                    textAlign: "center",
                    width: "calc(100% / 3 - 10px)",
                  }}
                >
                  {" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: removeBrTags(
                        questionData[QuestionNo]?.answers.length > 2
                          ? questionData[QuestionNo]?.answers[
                              Math.floor(
                                questionData[QuestionNo]?.answers.length / 2
                              )
                            ]?.desktop_text
                          : ""
                      ),
                    }}
                  />{" "}
                </p>
                {/* <p style={{ textAlign: 'right', marginRight: "15px", width: 'calc(100% / 3 - 10px)' }}> <span dangerouslySetInnerHTML={{ __html: churchData[QuestionNo]?.answers[churchData[QuestionNo]?.answers.length - 1]?.desktop_text }} /> </p> */}
                <p
                  style={{
                    textAlign: "right",
                    marginRight: "15px",
                    width: "calc(100% / 3 - 10px)",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: removeBrTags(
                      questionData[QuestionNo]?.answers[
                        questionData[QuestionNo]?.answers.length - 1
                      ]?.desktop_text?.toLowerCase() === "no"
                        ? "Strongly Agree"
                        : questionData[QuestionNo]?.answers[
                          questionData[QuestionNo]?.answers.length - 1
                          ]?.desktop_text
                    ),
                  }}
                />
              </div>
              <p
                className="mobile-label"
                style={{ marginBottom: "8px" }}
                dangerouslySetInnerHTML={{
                  __html: removeBrTags(
                    questionData[
                      QuestionNo
                    ]?.answers[0]?.desktop_text?.toLowerCase() === "yes"
                      ? "Strongly Disagree"
                      : questionData[QuestionNo]?.answers[0]?.desktop_text
                  ),
                }}
              />
              <div className="chart-mobile">
                {chartData.map((data, index) => {
                  const {
                    primaryData,
                    secondaryData,
                    normalizedPrimaryHeight,
                    normalizedSecondaryHeight,
                  } = data;
                  const isHovered = isHover === index;
                  return (
                    <div className="chart-bar" key={index}>
                      <div className="data-value">
                        {/* Org side  */}
                        <p
                          className="data-one"
                          style={{
                            filter:
                              !isDeployedDone && isComparisonBarChart
                                ? "blur(5px)"
                                : "none",
                            color:
                              !isDeployedDone && isComparisonBarChart
                                ? "#D4D4D4"
                                : isComparisonBarChart
                                ? "#C581FF"
                                : "#171717",
                          }}
                        >
                          {primaryData || 0}
                        </p>
                        {/* National side  */}
                        {isComparisonBarChart && (
                          <p className="data-two" style={{}}>
                            {secondaryData || 0}
                          </p>
                        )}
                      </div>

                      <div
                        className={`databar ${index}`}
                        onMouseEnter={() => {
                          setHover(!isDeployedDone ? index : null);
                        }}
                        onMouseLeave={() => {
                          setHover(null);
                        }}
                        style={{
                          opacity:
                            isHover !== null && index !== isHover ? 0.4 : 1,
                          gap: "1px",
                          backgroundColor: isComparisonBarChart
                            ? "rgba(229, 229, 229, 0.3)"
                            : "#E5E5E5",
                        }}
                      >
                        {/* Stacked bar for primary data (ORG data) */}
                        <div
                          className="dataheightone"
                          style={{
                            width: `${normalizedPrimaryHeight || 0}%`,
                            transition: "width 0.5s linear",
                            // backgroundColor: '#00C980',
                            filter:
                              !isDeployedDone && isComparisonBarChart
                                ? "blur(5px)"
                                : "none",
                            backgroundColor:
                              !isDeployedDone && isComparisonBarChart
                                ? "#D4D4D4"
                                : "#C581FF",
                            borderRadius: isComparisonBarChart
                              ? "8px 8px 8px 0px"
                              : "8px",
                            height: "100%",
                          }}
                        ></div>
                        {/* Stacked bar for secondary data (National Data)*/}
                        {isComparisonBarChart && (
                          <div
                            className="dataheighttwo"
                            style={{
                              width: `${normalizedSecondaryHeight || 0}%`,
                              transition: "height 0.5s linear",
                              height: "100%",
                            }}
                          ></div>
                        )}

                        {
                          <TooltipComponent
                            isVisible={!isDeployedDone && isHover === index}
                            isChurch={isChurch}
                          />
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
              <p
                className="mobile-label"
                style={{ marginTop: "10px" }}
                dangerouslySetInnerHTML={{
                  __html: removeBrTags(
                    questionData[QuestionNo]?.answers[
                      questionData[QuestionNo]?.answers.length - 1
                    ]?.desktop_text?.toLowerCase() === "no"
                      ? "Strongly Agree"
                      : questionData[QuestionNo]?.answers[
                         questionData[QuestionNo]?.answers.length - 1
                        ]?.desktop_text
                  ),
                }}
              />
              <div className="lableaboutdata">
                <div className="mydata">
                  <div
                    className="circle"
                    style={{
                      backgroundColor:
                        !isDeployedDone && isComparisonBarChart
                          ? "#D4D4D4"
                          : "#C581FF",
                    }}
                  ></div>
                  <p className="dataname">My Data</p>
                </div>
                {isComparisonBarChart && (
                  <div className="nationalaverage">
                    <div className="circle"></div>
                    <p className="dataname">National Average</p>
                  </div>
                )}
              </div>
              {/* {isComparisonBarChart &&
                  (<div className="lableaboutdata">
                    <div className="mydata">
                      <div className='circle' style={{ backgroundColor: isDeployedDone ?  '#C581FF' : '#D4D4D4' }}></div>
                      <p className='dataname'>My Data</p>
                    </div>
                    <div className="nationalaverage">
                      <div className='circle'></div>
                      <p className='dataname'>National Average</p>
                    </div>
                  </div>)
                } */}
            </>
          ) : (
            <ShimmerBarChart
              mode="light"
              chartType="random"
              barWidth={"7%"}
              style={{ paddingTop: 10 }}
            />
          )}
        </div>
        
      </div>

      {/* Recommend Resources  */}
      <div>
        {filteredData && (
          <div className="contents">
            <RecommendedSection
              data={filteredData.data}
              dimensionName={dimensionName}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default DimensionDetail;
