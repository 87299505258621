import React, { useState } from "react";

import backArrow from "../../Assests/Images/prev.svg";
import copyicon from "../../Assests/Images/copy-icon.svg";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import Download from '../../Assests/Images/download.svg'
import { ReactComponent as CopyIcon } from '../../Assests/Images/copy-icon.svg'
import Alert from '../../Assests/Images/alert.svg';
import shareresourcesicon from '../../Assests/Images/ShareResources.svg';
import massageicon from '../../Assests/Images/MessageIcon.svg'
import QRCode from "qrcode"; 

const AssessmentHeader = ({ link, setDeploymentName, deploymentName, deployAssessment, newDeployment, updateAssessment, savingTxt, creatingTxt, isChangedInForm }) => {
  const [on, setOn] = useState(false);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false)
  const [isTooltipVisible, setTooltip] = useState(false);
  const [deploymentId, setDeploymentId] = useState(0);
  const [orgUrl, setOrgUrl] = useState(0);

  const handleChange = () => {
    setOn(!on);
  };

  const Backbtn = () => {
    if(isChangedInForm){
      setOpenPopup('cancel')
    }else{
      handleModalClose();
    }
  }

  const handleModalOpen = async () => {
    if(savingTxt == "Saving..." || savingTxt == "Uploading..." || creatingTxt == "Saving..." || creatingTxt == "Uploading...") {
      console.log("Currently Saving");
    } else {
      if (newDeployment) {
        let deployed = await deployAssessment();
        if (deployed) {
          console.log(deployed);
          setDeploymentId(deployed.deploymentId);
          setOrgUrl(deployed.orgUrl);
          setOpenPopup('deployment')
        }
      } else {
        let deployed = await updateAssessment();
        console.log(deployed);
        if(deployed){
          setOrgUrl(deployed.url);
          setDeploymentId(deployed.deploymentId);
          setOpenPopup('deployment')
        }
      }
    }
  }

  const handleModalClose = () => {
    setOpenPopup(false)
    navigate(-1)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
  };

  const cancelAssessment = () => {
    if(isChangedInForm){
      setOpenPopup('cancel')
    }else{
      handleModalClose();
    }
  }
  const options = ['gloo.us/assessments/lifechurch/deploy245671', 'gloo.us/assessments/church/deploy245671', 'bagloo.us/assessments/lifechurch/deploy245671rna'];


  const previewSurveyLink =
    window.location.href.includes("dashboard.preview") ||
    window.location.href.includes("localhost")
      ? `https://preview.survey.church/${orgUrl}/deploy${deploymentId}`
      : `https://survey.church/${orgUrl}/deploy${deploymentId}`;

  const handleDownloadQRCode = async () => {
    try {
      const qrCodeDataURL = await QRCode.toDataURL(previewSurveyLink, {
        width: 250,
        margin: 1,
        color: { dark: '#000000', light: '#ffffff' }
      });

      const link = document.createElement("a");
      link.href = qrCodeDataURL;
      link.download = `QRCode_${deploymentId || "default"}.png`; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); 
    } catch (error) {
      console.error("Failed to generate QR Code", error);
    }
  };

  return (
    <div className="assessment-header">
      <div className="left">
        <div className="search-bar">
          <div className="back-button">
            <img src={backArrow} alt="" onClick={() => { Backbtn() }} />
          </div>
          <input type="search" placeholder="New Assessment" value={deploymentName} onChange={(e) => setDeploymentName(e.target.value)} />
          {/* <input type="search" placeholder="New Assessment" value={respondent || deployment?.name} /> */}
        </div>
        <div className="auto-save-container">
          {/* <div class="status-indicator" role="status">
            <svg
              aria-hidden="true"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <p>Auto-saved at 12:14 PM</p> */}
        </div>
      </div>
      <div className="right">
        {/* {link && (
          <div className="copy-container">
            <img src={copyicon} alt="" />
            <p>Copy Link</p>
          </div>
        )} */}
        <div className={`date-contents ${link ? "absolute-date-content" : ""}`} style={{ marginRight: '10px' }}>
          <button className="cancel-btn" onClick={cancelAssessment}>Cancel</button>
          <button className="create-btn" onClick={handleModalOpen} >{newDeployment ? creatingTxt : savingTxt}</button>
        </div>
        {/* <FormControlLabel
          control={
            <IOSSwitch sx={{ m: 1 }} checked={on} onChange={handleChange} />
          }
          label="On"
        /> */}
      </div>
      <Modal className={"mui-classes"} open={openPopup === 'deployment'} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="modal-container">
            <div className="modal-heading">
              <div className="heading-left">
                <p className="modal-title">Assessment Published</p>
                <p className="modal-title-info">Invite people to take this assessment</p>
              </div>
              <div className="heading-right">
                <Close onClick={()=>{setOpenPopup(false)}} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div className="unique-link-container">
              <div className="link-heading-container">
                <p className="unique-link-heading">Unique Link</p>
                <p className="unique-link-info">Share a link to this assessment. Every individual deployment gets a unique link.</p>
              </div>
              <div className="copy-link-section">
                <p className="link-title">Unique Link to Assessment</p>
                <div className="copy-link-container">
                  <span className="clink">{previewSurveyLink}</span>
                  <div className="tooltip">
                    <CopyIcon fill={isTooltipVisible ? "#D9F1E1" : "#737373"} onClick={() => {
                      setTooltip(true)
                      setTimeout(() => {
                        setTooltip(false);
                      }, 1000)
                      navigator.clipboard.writeText(previewSurveyLink)
                    }} />
                    <div className="tooltiptext" style={{ visibility: isTooltipVisible ? "visible" : "hidden" }}>Link Copied</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="qr-code-container">
              <div className="qr-code-heading-section">
                <p className="qr-heading">QR Code</p>
                <p className="qr-code-info">Download a QR Code to take people straight to this assessment.</p>
              </div>
              <Button className="qr-download-btn" onClick={handleDownloadQRCode}>
                <img src={Download} alt="" />
                <p>Download Custom QR Code</p>
              </Button>
            </div>
            {/* <div className="qr-code-container">
              <div className="qr-code-heading-section">
                <p className="qr-heading">Deploy with  gloo messaging</p>
                <p className="qr-code-info-limite">Instantly send a broadcast to your entire church or automatically send this assessment to new visitors.</p>
              </div>
              <Button className="qr-download-btn">
                <img src={massageicon} alt="" />
                <p>Create a Broadcast</p>
              </Button>
              <Button className="qr-download-btn">
                <img src={massageicon} alt="" />
                <p>Automate with Workflows</p>
              </Button>
            </div> */}
            <div className="done-btn" onClick={handleModalClose}>
              <Button className="resources"> <p>Get ChurchPulse Resources </p><img src={shareresourcesicon} alt="" /></Button>
              <Button sx={{textTransform:"none"}} className="done">Done</Button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal className={"mui-classes"} open={openPopup === 'cancel'} onClose={handleModalClose} style={{ maxWidth: '500px', maxHeight:'382px', margin:'auto' }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="modal-container">
            <div className="modal-heading">
              <div className="heading-left">
                <p className="modal-title">Delete progress</p>
              </div>
              <div className="heading-right">
                <Close onClick={()=>{setOpenPopup(false)}} style={{ cursor: 'pointer' }} />
              </div>
            </div>
            <div className="mid-container">
              <div className="alert">
                <img src={Alert} alt="" />
              </div>
              <div className="confirmation-msg">
                <p className="message">Are you sure you want to leave without saving?</p>
                <p className="delete-info">Your progress will not be saved.</p>
              </div>
            </div>
            <div className="buttons-container">
              <Button className='cancel' onClick={()=>{setOpenPopup(false)}}>Continue Editing</Button>
              <Button className='delete-btn' onClick={handleModalClose}>Confirm</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AssessmentHeader;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));
