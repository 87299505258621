import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AiInsightIcon } from "../../../Assests/Images/ChurchHealth/icons/AiInsightsIcon.svg";
import Arrow from "../../../Assests/Images/arrow-right-black.svg";
import AuthContext from "../../../store/AuthContext";
import {
    useDeploymentData,
    useFifteenDimensions,
  } from "../../../hooks/useQueryAPI";



const getHighestLowest = (values = []) => {
  let highest = { variable: "", dimensionName: "", averageScore: 0 },
    lowest = { variable: "", dimensionName: "", averageScore: 100 };
  values?.forEach(({ averageScore, variable, dimensionName }) => {
    if (highest.averageScore < averageScore) {
      highest = { averageScore, variable, dimensionName };
    }
    if (lowest.averageScore > averageScore) {
      lowest = { averageScore, variable, dimensionName };
    }
  });
  return [lowest, highest];
};


export function AIChurchThrivingMobile({ isDeployedDone = true }) {
    const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(1);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const cardWidth = 288 + 16; // Card width + gap
    const scrollPosition = container.scrollLeft;
    const containerWidth = container.offsetWidth;
    const currentIndex = Math.round((scrollPosition + containerWidth / 2 - cardWidth / 2) / cardWidth);
    setActiveIndex(currentIndex);
  };

  const scrollToCard = (index) => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const cardWidth = 288 + 16;
    const scrollPosition = index * cardWidth;
    container.scrollTo({ left: scrollPosition, behavior: "smooth" });
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      container.style.paddingLeft = `${(container.offsetWidth - 288) / 2}px`;
      container.style.paddingRight = `${(container.offsetWidth - 288) / 2}px`;
      setTimeout(() => scrollToCard(1), 100);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const authCtx = useContext(AuthContext);
  const { data: fifteenDimensionsData } = useFifteenDimensions(
    authCtx.orgId,
    authCtx.token
  );

  const { data: deploymentData = [] } = useDeploymentData(authCtx.orgId)

  const [lowestThriving = {}, highestThriving = {}] = getHighestLowest(fifteenDimensionsData);

  return (
    <>
      <div
        ref={scrollContainerRef}
        className="mobile-people-flourishing-insights"
      >
        <div className="mobile-people-flourishing-card">
        <div className="mobile-people-flourishing-powered-by-wrapper">
            <div className="mobile-powered-container">
            <span className="mobile-people-flourishing-sparkle">
              <AiInsightIcon />
            </span>
            {deploymentData.length > 0 && (
              <span className="mobile-people-flourishing-powered-text">
              powered by <strong className="highlight-blue">GlooAI</strong>
            </span>
            )}
            </div>
            <div className="mobile-beta-container">
              Beta
            </div>
          </div>
        {!deploymentData.length > 0 ? (
            <>
                <div className="mobile-people-flourishing-insights-text">
                Your people rated{" "}
                <span className="highlight-blue">
                    {highestThriving?.dimensionName}
                </span>{" "}
                as your greatest strength.
                </div>
            </>
            ) : (
            <div className="mobile-people-flourishing-insights-text">
                {convertMarkdownToHtml(deploymentData[3])}
            </div>
        )}
        </div>
        <div className="mobile-people-flourishing-card">
        <div className="mobile-people-flourishing-powered-by-wrapper">
            <div className="mobile-powered-container">
            <span className="mobile-people-flourishing-sparkle">
              <AiInsightIcon />
            </span>
            {deploymentData.length > 0 && (
              <span className="mobile-people-flourishing-powered-text">
              powered by <strong className="highlight-blue">GlooAI</strong>
            </span>
            )}
            </div>
            <div className="mobile-beta-container">
              Beta
            </div>
          </div>
           {!deploymentData.length > 0 ? (
          <>
            <div className="mobile-people-flourishing-insights-text">
              Your people rated{" "}
              <span className="highlight-blue">
                {lowestThriving?.dimensionName}
              </span>{" "}
              as your greatest opportunity for growth.
            </div>
          </>
        ) : (
          <div className="mobile-people-flourishing-insights-text">
            {convertMarkdownToHtml(deploymentData[4])}
          </div>
        )}
        </div>
        <div className="mobile-people-flourishing-card">
        <div className="mobile-people-flourishing-powered-by-wrapper">
            <div className="mobile-powered-container">
            <span className="mobile-people-flourishing-sparkle">
              <AiInsightIcon />
            </span>
            {deploymentData.length > 0 && isDeployedDone && (
              <span className="mobile-people-flourishing-powered-text">
              powered by <strong className="highlight-blue">GlooAI</strong>
            </span>
            )}
            </div>
             <div className="mobile-beta-container">Beta</div> 
          </div>
          {deploymentData.length > 0 && isDeployedDone ? (
          <div className="mobile-people-flourishing-insights-text">
            {convertMarkdownToHtml(deploymentData[5])}
          </div>
        ) : isDeployedDone ? (
          <>
              <p className="mobile-people-flourishing-insights-text">
                Once we've gathered{" "}
                <span className="highlight-blue">more data</span> we will update
                these with AI insights powered by GlooAI.
              </p>
          </>
        ) : (
          <div>
            <p className="mobile-people-flourishing-insights-text">
                Discover your <span className="highlight-blue">own</span>{" "}
                insights.
              </p>
            <button
              className="mobile-people-flourishing-button"
              onClick={() => navigate("/assessments/details")}
            >
              <p>Deploy Assessment</p>
              <img src={Arrow} alt="Arrow Icon" />
            </button>
          </div>
        )}
        </div>
      </div>
      <div className="mobile-people-flourishing-scroll-indicators">
        {[0,1,2].map((_, index) => (
          <div
            key={index}
            className={`mobile-people-flourishing-scroll-dot ${
              index === activeIndex ? "active" : ""
            }`}
            onClick={() => scrollToCard(index)}
          />
        ))}
      </div>
    </>
  );
}

const convertMarkdownToHtml = (text) => {
  if (!text) return null;

  const parts = text.split("**");
  return parts.map((part, index) => {
    return index % 2 === 0 ? (
      <span key={index}>{part}</span>
    ) : (
      <b className="highlight-blue" key={index}>
        {part}
      </b>
    );
  });
};
