import React, { useState, useEffect, useContext } from "react";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { CircularProgress } from "@mui/material";

import ResultArea from "../../Chart/ResultArea";
import WaveLineCard from "../../Chart/WaveLineCard";
import leftarrow from "../../../Assests/Images/leftarrow.svg";
import noResults from "../../../Assests/Images/noresults2.png";
import noResuts from "../../../Assests/Images/noresults2.png";
import RThrivingOverview from "./RThrivingOverview/RThrivingOverview";
import FlourishingOverview from "./FlourishingOverview/FlourishingOverview";
import CtaImage from "../../../Assests/Images/see-insights-cta-img.png";
import AuthContext from "../../../store/AuthContext";
import RangeCalender from "../RangeCalender/RangeCalender";

const Result = ({ deploymentId }) => {
  const authCtx = useContext(AuthContext);
  const { state } = useLocation();
  const [demographicsData, setDemographicsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [interval, setInterval] = useState("1M");
  const [selectedRange, setSelectedRange] = useState([null, null]);
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const fetchDemographicsResults = async (orgId) => {
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/${deploymentId}/demographicsResults`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "success") {
        return data.data;
      } else {
        throw new Error("Failed to fetch demographics results");
      }
    } catch (err) {
      console.error("Error fetching demographics results...", err);
      throw err;
    }
  };

  const transformDataForWaveLineCard = (demographicsData) => {
    return [
      {
        data: {
          label: "Starts",
          color: "#3F83F8",
          count: demographicsData.allAssessments.toString(),
          percentage: "20",
          percentageincrease: true,
        },
        values: transformDayCountToValues(
          demographicsData.allassessmentDayCount
        ),
      },
      {
        data: {
          label: "Completions",
          color: "#41B768",
          count: demographicsData.completedAssessments.toString(),
          percentage: "5",
          percentageincrease: true,
        },
        values: transformDayCountToValues(
          demographicsData.completedAssessmentDayCount
        ),
      },
      {
        data: {
          label: "Incomplete",
          color: "#EF4444",
          count: demographicsData.pendingAssessments.toString(),
          percentage: "5",
          percentageincrease: false,
        },
        values: transformDayCountToValues(
          demographicsData.pendingAssessmentDayCount
        ),
      },
    ];
  };

  const transformDayCountToValues = (dayCountArray) => {
    return dayCountArray.map((day) => ({
      date: day.date,
      value: day.count || 0,
    }));
  };

  const transformDayCountToChartData = (
    allDayCount,
    completedDayCount,
    pendingDayCount
  ) => {
    // Create a map for easy lookup of counts by date
    const completedMap = new Map(
      completedDayCount.map((day) => [day.date, day.count || 0])
    );
    const pendingMap = new Map(
      pendingDayCount.map((day) => [day.date, day.count || 0])
    );

    // Transform the data using allDayCount as the base for dates
    return allDayCount.map((day) => ({
      date: day.date,
      blue: day.count || 0, // All assessments
      green: completedMap.get(day.date) || 0, // Completed assessments
      red: pendingMap.get(day.date) || 0, // Pending assessments
    }));
  };

  const formatRange = ([start, end]) => {
    if (!start && !end) return "Custom range";
  
    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "short" });
      const year = date.getFullYear();
  
      // Add ordinal suffix
      const ordinal = (n) => {
        const s = ["th", "st", "nd", "rd"];
        const v = n % 100;
        return s[(v - 20) % 10] || s[v] || s[0];
      };
  
      return `${day}${ordinal(day)} ${month} ${year}`;
    };
  
    return `${formatDate(start)} to ${end ? formatDate(end) : "..."}`;
  };
  

  useEffect(() => {
    const loadDemographicsData = async () => {
      try {
        setLoading(true);
        const orgId = authCtx.orgId;
        const results = await fetchDemographicsResults(orgId);

        setDemographicsData(results);
      } catch (err) {
        setError(err.message);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (authCtx.orgId) {
      loadDemographicsData();
    }
  }, [authCtx.orgId]);

  const handleIntervalChange = (newInterval) => {
    setSelectedRange([null, null])
    setInterval(newInterval);
  };

  if (loading) {
    return (
      <p className="loading">
        <CircularProgress size={60} style={{ color: "#000000" }} />
      </p>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={noResuts} width={115}></img>
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 800,
              lineHeight: "28px",
              textAlign: "center",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            No results to show
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 300,
              lineHeight: "21px",
              textAlign: "center",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            No one has taken this assessment yet.
          </p>
        </div>
      </div>
    );
  }

  const transformedData = demographicsData
    ? transformDataForWaveLineCard(demographicsData)
    : [];

  const transformedChartData = demographicsData
    ? transformDayCountToChartData(
        demographicsData.allassessmentDayCount,
        demographicsData.completedAssessmentDayCount,
        demographicsData.pendingAssessmentDayCount
      )
    : [];

  console.log("demographicsData", demographicsData);

  return (
    <div className="result-container">
      {demographicsData?.allAssessments > 0 ? (
        <>
          <div className="interval">
            <div>
              <button
                className={
                  interval === "1D" ? "intervalbutton active" : "intervalbutton"
                }
                onClick={() => handleIntervalChange("1D")}
              >
                1D
              </button>
              <button
                className={
                  interval === "1W" ? "intervalbutton active" : "intervalbutton"
                }
                onClick={() => handleIntervalChange("1W")}
              >
                1W
              </button>
              <button
                className={
                  interval === "1M" ? "intervalbutton active" : "intervalbutton"
                }
                onClick={() => handleIntervalChange("1M")}
              >
                1M
              </button>
              <button
                className={
                  interval === "6M" ? "intervalbutton active" : "intervalbutton"
                }
                onClick={() => handleIntervalChange("6M")}
              >
                6M
              </button>
              <button
                className={
                  interval === "1Y" ? "intervalbutton active" : "intervalbutton"
                }
                onClick={() => handleIntervalChange("1Y")}
              >
                1Y
              </button>
              <button
                className={
                  interval === "all"
                    ? "intervalbutton active"
                    : "intervalbutton"
                }
                onClick={() => handleIntervalChange("all")}
              >
                All time
              </button>
              <button
                className={`range-calendar-container ${
                  interval === "custom-range"
                    ? "intervalbutton active"
                    : "intervalbutton"
                }`}
                onClick={() => {
                  setCalendarOpen(true);
                  handleIntervalChange("custom-range");
                }}
              >
                <div className="range-calendar-header">
                  <div className="range-calendar-icon-text">
                    <p className="range-calendar-text">Custom range</p>
                  </div>
                </div>
              </button>
              {interval === "custom-range" && isCalendarOpen && (
                <RangeCalender
                  setCalendarOpen={setCalendarOpen}
                  handleSelectedDates={(dates) => setSelectedRange(dates)}
                />
              )}
            </div>
            {formatRange(selectedRange) !== "Custom range" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="range-calendar-text1">
                  {formatRange(selectedRange)}
                </p>
                <MdOutlineCancel
                  style={{ display: "inline-block", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedRange([null, null]);
                  }}
                />
              </div>
            )}
          </div>

          <div className="headstack">
            {transformedData.map((item, index) => (
              <WaveLineCard
                data={item.values}
                key={index}
                mdata={item.data}
                index={index}
                totalCards={transformedData.length}
                interval={interval}
                customRange={selectedRange}
              />
            ))}
          </div>

          <div className="areagharph">
            <ResultArea
              data={transformedChartData}
              filter={interval}
              customRange={selectedRange}
              chartDataToday={demographicsData?.assessmentCountLast24Hours}
            />
          </div>

          <div className="bottomghaph">
            <FlourishingOverview deploymentId={deploymentId} />
            <RThrivingOverview />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={noResults}></img>
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontWeight: 800,
                  lineHeight: "28px",
                  textAlign: "center",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                }}
              >
                No results show
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 300,
                  lineHeight: "21px",
                  textAlign: "center",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                }}
              >
                No one has taken this assessment yet.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Result;
