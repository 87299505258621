import React from "react";

const CreateDeployAssessment = ({onClick , isChurch}) => {
  return <div className="create-deploy-assesment">
      <button className="create-deploy-assesment__btn" onClick={onClick}>
       {isChurch ? "Deploy Assessment" : "Create your Circle" } 
      </button>
    </div>
};

export default CreateDeployAssessment;
