import React, { useEffect, useRef, useState } from "react";
import arrowLeft from "../../Assests/Images/arrowLeft.svg";
import arrowRight from "../../Assests/Images/arrowRight.svg";
import handshake from "../../Assests/Images/handshake.svg";
import RelationshipBadge from "../../Assests/Images/badge-heart.svg";
import PurposeBadge from "../../Assests/Images/badge-purpose.svg";
import ContentmentBadge from "../../Assests/Images/badge-contentment.svg";
import HealthBadge from "../../Assests/Images/badge-health.svg";
import FinancesBadge from "../../Assests/Images/badge-finances.svg";
import SpiritualBadge from "../../Assests/Images/badge-spiritual.svg";
import CharacterBadge from "../../Assests/Images/badge-character.svg";

const GrowthGoals = ({
  // generally user data.
  primaryData = [],
  // generally national average or church data.
  secondaryData = [],
  primaryColor,
  secondaryColor = "",
  isDeployedDone,
  comparison
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (primaryData && secondaryData) {
      const growthGoalVars = [
        "cprelation",
        "cppurpose",
        "cpspiritual",
        "cphealth",
        "cpfinance",
        "cpcharacter",
        "cpcontent",
      ];

      // Create chart data using the averages
      const chartDataTemplate = [
        {
          id: 1,
          name: "Relationships",
          variable: "cprelation",
          description: "Grow in the quality of my relationships.",
          icon: RelationshipBadge,
          mydata: 34
        },
        {
          id: 2,
          name: "Purpose",
          variable: "cppurpose",
          description: "Grow in the sense of meaning I find in daily activity.",
          icon: PurposeBadge,
          mydata: 84
        },
        {
          id: 3,
          name: "Faith",
          variable: "cpspiritual",
          description: "Grow in my spiritual vitality.",
          icon: SpiritualBadge,
          mydata: 57
        },
        {
          id: 4,
          name: "Wellness",
          variable: "cphealth",
          description: "Grow in my overall physical and mental health.",
          icon: HealthBadge,
          mydata: 7
        },
        {
          id: 5,
          name: "Finances",
          variable: "cpfinance",
          description: "Grow in my financial stability.",
          icon: FinancesBadge,
          mydata: 63
        },
        {
          id: 6,
          name: "Virtue",
          variable: "cpcharacter",
          description: "Grow in my responsiveness to God and culture.",
          icon: CharacterBadge,
          mydata: 31
        },
        {
          id: 7,
          name: "Fulfillment",
          variable: "cpcontent",
          description: "Grow in my overall life happiness and satisfaction.",
          icon: ContentmentBadge,
          mydata: 19
        },
      ];

      const newData = chartDataTemplate.map(
        ({ id, name, mydata = 0, variable, description, icon },index) => ({
          id,
          name,
          // mydata: scorePercentages[variable],
          mydata: isDeployedDone ? primaryData[index]?.percentage || 0 : mydata,
          description,
          // nationalaverage: averageScores[variable],
          nationalaverage: secondaryData[index]?.percentage || 0,
          icon,
        })
      );

      setChartData(newData);
      // setIsLoading(false);
    }
  }, [secondaryData, primaryData]);

  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = 700;

    if (direction === "left") {
      container.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    } else {
      container.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="growthgoal-container">
      <div style={{overflow:"auto", padding:'0 8px'}}>
        <div className="growthgoal-header">
          <div>
            <p className="growthgoal-header-title">Growth Goals</p>
            <p className="growthgoal-header-subtitle">
              See which dimension of flourishing your people want to grow in the
              most.
            </p>
          </div>
          <div className="growthgoal-header-scrollar">
            <div
              className="arrow-left"
              role="button"
              tabIndex={0}
              onClick={() => scroll("left")}
            >
              <img src={arrowLeft} alt="arrowLeft" />
            </div>
            <div
              className="arrow-rigth"
              role="button"
              tabIndex={0}
              onClick={() => scroll("right")}
            >
              <img src={arrowRight} alt="arrowRight" role="button" />
            </div>
          </div>
        </div>
        <div className="growthgoal-card-container" ref={scrollContainerRef}>
          {chartData.map((goal) => (
            <div key={goal.id} className="growthgoal-card-box">
              <div className="growthgoal-card-text">
                <div className="card-text-header">
                  <img src={goal.icon} alt="handshake" />
                  <p className="card-text-header-title">{goal.name}</p>
                </div>
                <div className="card-text-detail">{goal.description}</div>
              </div>
              <div className="growthgoal-card-bar">
                <div
                  className="blur-bar-container"
                  style={{ filter: isDeployedDone ? "none" : "blur(5px)", marginLeft: comparison ? '0px' :'65px' }}
                >
                  <div className="blur-bar">
                    <div className="blur-box">
                      <div
                        style={{
                          height: goal.mydata,
                          backgroundColor:primaryColor ? primaryColor:'#C581FF'
                        }}
                        className="percentage-bar"
                      />
                    </div>
                  </div>
                  <div className="blur-bar-percentage" style={{color:primaryColor ? primaryColor: comparison ? '#C581FF' : "#171717"}}>{Number(goal?.mydata || 0).toFixed(0)}%</div>
                </div>
                {
                  comparison &&
                  <div className="national-avg-bar-container">
                    <div className="national-avg-bar">
                      <div className="national-avg-box">
                        <div
                          style={{ height: goal.nationalaverage }}
                          className="national-avg-percentage-bar"
                        />
                      </div>
                    </div>
                    <div className="bar-percentage">{Number(goal?.nationalaverage || 0).toFixed(0)}%</div>
                  </div>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GrowthGoals;
