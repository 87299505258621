import React from "react";
import BannerImg from '../../../Assests/Images/ChurchHealth/banner.png'
import { navigateToCircles } from "../../../utils";
const BannerNetwork = () => {
  return (
    <div className="banner">
      <div className="banner__content">
        <div className="banner__text">
          <div>
            Find out how <span>your</span> churches are doing
          </div>
          <p>Church leaders nationwide are measuring what matters through the State of the Church Initiative. Explore real-time national results below, and compare them to your own Church’s data by inviting them to join your Circle and encouraging them to deploy the Church Pulse Assessment to their congregants.
          </p>
          <button className="banner-btn" onClick={() => navigateToCircles()}>
            Create your Circle
          </button>
        </div>
        <section className="banner__image">
            <img src={BannerImg} className="banner__img" alt="banner" />
        </section>
      </div>
    </div>
  );
};

export default BannerNetwork;
