import React from 'react'

const ChruchHealthCards = ({ icon, title, description, isDeployedDone, data2, data1, isComparisonBarChart, primaryLabel, secondaryLabel }) => {
  return (
    <div className={`ch-card`}>
      <div className='ch-card-description'>
        <div className='ch-card-title-box'>
          <div>
            <img src={icon} alt="ring" />
          </div>
          <div className='ch-card-title'>
            <p>{title}</p>
          </div>
        </div>
        <div className='ch-card-subtitle'>
          <p>{description}</p>
        </div>
      </div>
      <div className='ch-card-bar-container'>
        {!isDeployedDone ? <div className='blur-card-container'>
          <div>
            <div className="blur-card-box">
              <div style={{ height: `${data2 === 0 ? 0 : data1 || 0}%` }} className="percentage-bar" />
            </div>
          </div>
          <div className='blur-bar-percentage' style={{ filter: "blur(5px)" }} >
            {Number(data2 === 0 ? 0 : data1 || 0 ).toFixed(0)}%
          </div>
        </div> :
          <div className='national-avg-card'>
            <div>
              <div className="national-avg-card-box">
                <div style={{ height: `${data1 || 0}%`, backgroundColor: '#C581FF' }} className="national-percentage-bar" />
              </div>
            </div>
            <div className='bar-percentage' style={{ color: isComparisonBarChart ? '#C581FF' : "#171717" }}>
              {Number(data1 || 0).toFixed(0)}%
            </div>
          </div>}
        {isComparisonBarChart && <div className='national-avg-card'>
          <div>
            <div className="national-avg-card-box">
              <div style={{ height: `${data2 || 0}%` }} className="national-percentage-bar" />
            </div>
          </div>
          <div className='bar-percentage'>
            {Number(data2 || 0).toFixed(0)}%
          </div>
        </div>}
      </div>
      {isComparisonBarChart && <div className="lableaboutdata">
        <div className="mydata">
          <div className='circle' style={{ backgroundColor: (!isDeployedDone && isComparisonBarChart) ? '#D4D4D4' : '#C581FF' }}></div>
          <p className='dataname'>{primaryLabel}</p>
        </div>
        {isComparisonBarChart &&
          <div className="nationalaverage">
            <div className='circle'></div>
            <p className='dataname'>{secondaryLabel}</p>
          </div>
        }
      </div>}
    </div>
  )
}

export default ChruchHealthCards