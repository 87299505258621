import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  orgId: '0',
  glooUserId: '',
  glooOrgId: '',
  email: '',
  name: '',
  isLoggedIn: false,
  login: (token, tokenExpiration) => {},
  update: (orgId, glooUserId, glooOrgId, orgName, email, name, orgType, circles) => {},
  logout: () => {},
  getToken:(code)=>{}
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
}



const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('token');
  const storedTokenExpiration = localStorage.getItem('tokenExpiration');

  const remainingTime = calculateRemainingTime(storedTokenExpiration);

  if(remainingTime <= 86400) {

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('glooUserId');
    localStorage.removeItem('glooOrgId');
    localStorage.removeItem('orgId');
    localStorage.removeItem('orgName');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('orgType');
    localStorage.removeItem('circles');
    return null;
  }
  
  return {
    token: storedToken,
    duration: remainingTime
  };
}

export const AuthContextProvider = (props) => {

  const tokenData = retrieveStoredToken();
  let initialToken;
  if(tokenData) {
    initialToken = tokenData.token;
  }
  const initialOrgId = localStorage.getItem('orgId') || '0';
  const initialGlooOrgId = localStorage.getItem('glooOrgId');
  const initialGlooUserId = localStorage.getItem('glooUserId');
  const initialOrgName = localStorage.getItem('orgName');
  const initialEmail = localStorage.getItem('email');
  const initialName = localStorage.getItem('name');
  const initialOrgType = localStorage.getItem('orgType');
  

  let initialCircles = [];
  try {
    initialCircles = JSON.parse(localStorage.getItem('circles'));
  } catch (e) {
    console.error(e);
  }

  const [token, setToken] = useState(initialToken);
  const [glooUserId, setGlooUserId] = useState(initialGlooUserId);
  const [glooOrgId, setGlooOrgId] = useState(initialGlooOrgId);
  const [orgName, setOrgName] = useState(initialOrgName);
  const [orgId, setOrgId] = useState(initialOrgId);
  const [email, setEmail] = useState(initialEmail);
  const [name, setName] = useState(initialName);
  const [orgType, setOrgType] = useState(initialOrgType);
  const [circles, setCircles] = useState(initialCircles);
  
  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    
    setToken(null);
    setGlooUserId(null);
    setGlooOrgId(null);
    setOrgName(null);
    setOrgId(null);
    setEmail(null);
    setName(null);
    setOrgType(null);
    setCircles(null);

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('glooUserId');
    localStorage.removeItem('glooOrgId');
    localStorage.removeItem('orgName');
    localStorage.removeItem('orgId');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('orgType');
    localStorage.removeItem('circles');

    if(logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
    if(tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const loginHandler = (token, tokenExpiration) => {

    // console.log(token);
    // console.log("We are getting here");
    
    setToken(token);

    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', tokenExpiration);

    const remainingTime = calculateRemainingTime(tokenExpiration);
    logoutTimer = setTimeout(logoutHandler, remainingTime);

  }

  const updateHandler = (glooUserId, glooOrgId, orgName, orgId, email, name, orgType = "church", circles = []) => {

    setGlooUserId(glooUserId);
    setGlooOrgId(glooOrgId);
    setOrgName(orgName);
    setOrgId(orgId);
    setEmail(email);
    setName(name);
    setOrgType(orgType);
    setCircles(circles)


    localStorage.setItem('glooUserId', glooUserId);
    localStorage.setItem('glooOrgId', glooOrgId);
    localStorage.setItem('orgName', orgName);
    localStorage.setItem('orgId', orgId);
    localStorage.setItem('email', email);
    localStorage.setItem('name', name);
    localStorage.setItem('orgType', orgType);
    localStorage.setItem('circles', JSON.stringify(circles));
  }
  
  const getToken = (code) => {
    
    const payload = {
      code: code
    }
  
    fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/oauth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    .then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == 'success') {
        const tokenExpiration = new Date(new Date().getTime() + 86400000);
        console.log("We are here");
        console.log(data.data.accessToken);
        console.log(tokenExpiration);
        loginHandler(data.data.accessToken, tokenExpiration);
  
        fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/userinfo`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
        })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            updateHandler(data?.data?.glooUserId, data?.data?.glooOrgId, data?.data?.orgName, data?.data?.orgId, data?.data?.email, data?.data?.name, data?.data?.orgType, data?.data?.circles);
            return {status: "success"}
          }
        }).catch(err => {
          console.error("no success")
          return {status: "failure", error: err.message}
        });
  
      }
    }).catch(err => {
      console.error(err)
      
    });
  
  }

  const contextValue = {
    token: token,
    glooUserId: glooUserId,
    glooOrgId: glooOrgId,
    orgName: orgName,
    orgId: orgId,
    email: email,
    name: name,
    orgType: orgType,
    circles: circles,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    update: updateHandler,
    logout: logoutHandler,
    getToken:getToken
  }
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;