import React, { useState,useEffect,useRef, useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DoneIcon from '@mui/icons-material/Done';
import Church from '../../Assests/Images/church.svg'
import CirclesOverlap from '../../Assests/Images/circles-overlap.svg'
import FlagUsa from '../../Assests/Images/Flag-usa.svg'
import AgeRangeIcon from '../../Assests/Images/Agerange.svg'
import GenderIcon from '../../Assests/Images/Gender.svg'
import {ReactComponent as DownArrowIcon} from '../../Assests/Images/lightdownArrow.svg'
import crossicon from '../../Assests/Images/lightCrossIcon.svg';
import menuicon from '../../Assests/Images/lightmenuicon.svg';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFetchCircleInformation } from '../../hooks/useQueryAPI';
import AuthContext from '../../store/AuthContext';

const FilterCard = ({appliedFilters, setAppliedFilters, filterType, comparison=true, setComparison, isDeployedDone}) => {

  const authCtx = useContext(AuthContext);

  const [timeline, setTimeline] = useState(
    appliedFilters?.timeline || "allTime"
  );
  // const [dataset,setDataset]=useState(null)
  const [tempDataset, setTempDataset] = useState(
    appliedFilters?.dataset || "My Church"
  );
  const [open, setOpen] = useState(false);
  const [datasetopen, setDatasetOpen] = useState(false);
  const [circleType, setCircleType] = useState(appliedFilters?.circleType || null);
  const [circleDown, setCircleDown] = useState(appliedFilters?.dataset === "Circles");
  const [isActive, setIsActive] = useState(false);
  const [ageRange, setAgeRange] = useState(appliedFilters?.ageRange);
  const [enableSelect, setEenableSelect] = useState({ Agerange: true, Gender: true, circleDown:true });
  const [gender, setGender] = useState(appliedFilters?.gender);
  const [additionalOpen, setAdditionalOpen] = useState(false);
  const dropdownRef = useRef();
  const datasetDropdownRef = useRef();
  const additionalDownRef = useRef();

  const circles = authCtx?.circles || [];

  if (appliedFilters?.timeline && timeline !== appliedFilters?.timeline) {
    setTimeline(appliedFilters?.timeline);
  }

  const handleTimeLine = (value) => {
    setTimeline(value);
    setAppliedFilters({ ...appliedFilters, timeline: value });
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setOpen((open) => !open);
  };

  const toggleDatasetDropdown = () => {
    setDatasetOpen((datasetopen) => !datasetopen);
    // setTempDataset(dataset)
  };

  const toggleCircleDown = () => {
    setCircleDown((circleDown) => !circleDown);
  };

  const toggleAdditionalDown = () => {
    setAdditionalOpen((additionalOpen) => !additionalOpen);
  };

  const handleApplyDataset = (value) => {
    setTempDataset(value);
    if (value === "Circles") {
      setCircleDown(true);
    }
  };

  useEffect(() => {
    const handler = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const handleSelectFilter = (key) => {
    setEenableSelect({...enableSelect, [key]: !enableSelect[key]})
  }

  useEffect(() => {
    const handler = (event) => {
      if (
        datasetDropdownRef.current &&
        !datasetDropdownRef.current.contains(event.target) &&
        document.getElementById("filter-dataset-modal") &&
        !document.getElementById("filter-dataset-modal").contains(event.target)
      ) {
        setDatasetOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
    };
  }, [datasetDropdownRef]);

  useEffect(() => {
    const handler = (event) => {
      if (
        additionalDownRef.current &&
        !additionalDownRef.current.contains(event.target)
      ) {
        setAdditionalOpen(false);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  // useEffect(() => {
  //   setAppliedFilters({
  //     timeline,
  //     dataset,
  //     circleType,
  //     ageRange,
  //     gender,
  //   });
  // }, [timeline, dataset, circleType, ageRange, gender]);

  const handleApplyFilter = () => {
    setDatasetOpen(false);
    setAdditionalOpen(false);
    setComparison && setComparison(true);
    setAppliedFilters({
      timeline,
      dataset: tempDataset,
      circleType,
      ageRange,
      gender,
    });
  };

  const clearFilters = () => {
    setGender(null);
    setAgeRange(null);
    setAppliedFilters((prev) => ({
      ...prev,
      gender: null,
      ageRange: null,
    }));
  };

  const handlerrightcross = () => {
    if (filterType === "rightfilter" && isDeployedDone) {
      setComparison(false);
    }
  };

  const DatasetModal = ({ style = {} }) => {
    return (
      <div
        id="filter-dataset-modal"
        className={`dataset-content dataset-open`}
      >
        <div className="dataset-heading">
          <span>Data set</span>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={toggleDatasetDropdown}
          />
        </div>
        <div className="dataset-options">
          <div className="options-container">
            <div
              onClick={() => handleApplyDataset("My Church")}
              className={`options ${
                tempDataset === "My Church" ? "selected-option" : null
              }`}
            >
              <img src={Church}></img>
              <p>My Church</p>
            </div>
            <div
              onClick={() => handleApplyDataset("Circles")}
              className={`options ${
                tempDataset === "Circles" ? "selected-option" : null
              }`}
            >
              <img src={CirclesOverlap}></img>
              <p>Circles</p>
            </div>
            <div
              onClick={() => handleApplyDataset("National Average")}
              className={`options ${
                tempDataset === "National Average" ? "selected-option" : null
              }`}
            >
              <img src={FlagUsa}></img>
              <p>National Average</p>
            </div>
          </div>
          {
            <div
              className={`circles-container ${
                tempDataset !== "Circles" || !circleDown ? "close-circle" : null
              }`}
            >
              <div className="circles-heading">
                <div className="my-circles">
                  <img
                    style={{ width: "24px", height: "24px" }}
                    src={CirclesOverlap}
                  ></img>
                  <p>My Circles</p>
                </div>
                <KeyboardArrowUpIcon onClick={()=>handleSelectFilter('circleDown')} sx={{ cursor: "pointer", fontSize: "small", transform: enableSelect['circleDown'] ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}}/>
              </div>
              {
                enableSelect['circleDown'] && 
                <div className="circles-btns">
                  <button
                    className={`${circleType === "all" ? "selected" : null}`}
                    onClick={() => setCircleType("all")}
                  >
                    All Circles
                  </button>
                  {circles?.map((circle = {id: "", name: ""}, index) => (
                    <button
                      key={circle.id}
                      className={`${circleType === circle.id ? "selected" : null}`}
                      onClick={() => setCircleType(circle.id)}
                    >{circle.name}</button>
                  ))}
                </div>
              }
              
            </div>
          }
        </div>
        <div className="dataset-cta">
          <button className="cancel-cta" onClick={toggleDatasetDropdown}>
            Cancel
          </button>
          <button className="apply-cta" onClick={handleApplyFilter}>
            Apply
          </button>
        </div>
      </div>
    );
  };

  if (!comparison) {
    return (
      <div
        className="dropdown-dataset create-deploy-assesment"
        style={{ position: "relative" }}
      >
        <button
          className="create-deploy-assesment__btn"
          onClick={() => {
            setDatasetOpen(true);
          }}
        >
          Add a comparison
        </button>
        {datasetopen && <DatasetModal style={{ top: "70%", left: "10%" }} />}
      </div>
    );
  }

  return (
    <div className='card-container'>
      <div className='card-header'>
        <div className='dropdown-dataset' ref={datasetDropdownRef}>
          <div onClick={toggleDatasetDropdown} className='icon-and-title'>
            <div className="status-icon" style={{backgroundColor : filterType === 'leftfilter' ? '#C581FF' : '#00c980'}}></div>
            <span className="card-title"> {appliedFilters?.dataset ?? 'National Average'}</span>
            <KeyboardArrowUpIcon alt="downicon" fill="#737373" sx={{ cursor: "pointer",  fontSize: "small",  transform: datasetopen ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}} />
          </div>
          {datasetopen && <DatasetModal />}
        </div>
        {/* <CloseIcon onClick={handlerrightcross}/> */}
        <img src={crossicon} alt="crossicon" onClick={handlerrightcross} style={{cursor: filterType === 'rightfilter' ? "pointer" : "inherit"}}  />
      </div>
      
      <div className="card-actions">
          <div className='dropdown-timeline' ref={dropdownRef}>
              <button  className={`filter-dropdown ${isActive ? "active" : ""}`}
                onMouseDown={() => setIsActive(true)}
                onMouseUp={() => setIsActive(false)}
                onClick={toggleDropdown}>
                  <CalendarTodayIcon style={{width:'12px', height:'11px', color:'#ffffff'}}/>
                  <span className='drop-heading'>
                    {timeline === "yearToDate" ? "Year to date" :
                    timeline === "allTime" ? "All time" :
                    timeline === "lastYear" ? "Last year" :
                    timeline === "last30Days" ? "Last 30 days" : timeline}
                  </span>
                  {/* <RiArrowDropDownLine style={{color:"#FFFFFF"}}/> */}
                  <DownArrowIcon alt="whitedownarrow" fill="#FFF" />
              </button>
              {open && <div className={`dropdown_menu content-open`} style={{ zIndex: open ? 999 : 0 }}>
                  <li onClick={()=>handleTimeLine("yearToDate")}><span>Year to date</span>{timeline==="yearToDate"&&<DoneIcon fontSize='small'/>}</li>
                  <li onClick={()=>handleTimeLine("allTime")}><span>All time</span>{timeline==="allTime"&&<DoneIcon fontSize='small'/>}</li>
                  <li onClick={()=>handleTimeLine("lastYear")}><span>Last year</span>{timeline==="lastYear"&&<DoneIcon fontSize='small'/>}</li>
                  <li onClick={()=>handleTimeLine("last30Days")}><span>Last 30 days</span>{timeline==="last30Days"&&<DoneIcon fontSize='small'/>}</li>
              </div>}
          </div>
          <div className="vertical-line"></div>
          <div ref={additionalDownRef} className='additional-dropdown'>
              <div className="filter-selection">
                <div onClick={toggleAdditionalDown} className='tune-container'>
                    {/* <TuneIcon fontSize='small'/> */}
                    <img src={menuicon} alt="menuicon" />
                </div>
                {appliedFilters?.gender && <div className="filter-tag">
                  <img height={11} src={GenderIcon}></img><span className="filter-value">{appliedFilters.gender}</span>
                  <CloseIcon sx={{cursor:"pointer", width: "9px", height: "11px"}} onClick={() => {setGender(null); setAppliedFilters({ timeline, dataset: tempDataset, circleType, ageRange, gender: null});}}/>
                </div>}
                {appliedFilters?.ageRange && <div className="filter-tag">
                  <img height={11} src={AgeRangeIcon}></img><span className="filter-value">{appliedFilters.ageRange}</span>
                  <CloseIcon sx={{cursor:"pointer", width: "9px", height: "11px"}} onClick={() => {setAgeRange(null); setAppliedFilters({ timeline, dataset: tempDataset, circleType, ageRange: null, gender});}}/>
                </div>}
              </div>
              {additionalOpen && <div className={`additional-content dataset-open`}>
                  <div className='dataset-heading'>
                      <span>Filters</span>
                      <CloseIcon sx={{cursor:"pointer"}} onClick={toggleAdditionalDown}/>
                  </div>
                  <div className='dataset-options'>
                      <div className='circles-heading'>
                              <div className='my-circles'>
                                  <img style={{width:"24px",height:"24px"}} src={AgeRangeIcon}></img>
                                  <p>Age range</p>
                              </div>
                              <KeyboardArrowUpIcon onClick={()=>handleSelectFilter('Agerange')} sx={{ cursor: "pointer", fontSize: "small", transform: enableSelect['Agerange'] ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}} />
                      </div>
                      {
                        enableSelect['Agerange'] &&
                        <div className='options-container'>
                          <button className={`${ageRange === "18-25" ? "selected" : null}`} onClick={() => setAgeRange("18-25")}>18-25</button>
                          <button className={`${ageRange === "26-40" ? "selected" : null}`} onClick={() => setAgeRange("26-40")}>26-40</button>
                          <button className={`${ageRange === "41-59" ? "selected" : null}`} onClick={() => setAgeRange("41-59")}>41-59</button>
                          <button className={`${ageRange === "60-78" ? "selected" : null}`} onClick={() => setAgeRange("60-78")}>60-78</button>
                          <button className={`${ageRange === "79-99" ? "selected" : null}`} onClick={() => setAgeRange("79-99")}>79-99</button>
                          <button className={`${ageRange === "99+" ? "selected" : null}`} onClick={() => setAgeRange("99+")}>99+</button>
                        </div>
                      }
                      
                      {<div className={`circles-container`}>
                          <div className='circles-heading'>
                              <div className='my-circles'>
                                  <img style={{width:"24px",height:"24px"}} src={GenderIcon}></img>
                                  <p>Gender</p>
                              </div>
                              <KeyboardArrowUpIcon onClick={()=>handleSelectFilter('Gender')} sx={{ cursor: "pointer", fontSize: "small", transform: enableSelect['Gender'] ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}} />
                          </div>
                          {
                            enableSelect['Gender'] && 
                            <div className='circles-btns'>
                              <button className={`${gender === "Male" ? "selected" : null}`} onClick={() => setGender("Male")}>Male</button>
                              <button className={`${gender === "Female" ? "selected" : null}`} onClick={() => setGender("Female")}>Female</button>
                              <button className={`${gender === "Prefer not to answer" ? "selected" : null}`} onClick={() => setGender("Prefer not to answer")}>Prefer not to answer</button>
                            </div>
                          }
                          
                      </div>}
                  </div>
                  <div className='dataset-cta'>
                      <button onClick={clearFilters} className='clear-cta'>Clear all</button>
                      <div className='btn-div'>
                          <button className='cancel-cta' onClick={toggleAdditionalDown}>Cancel</button>
                          <button className='apply-cta' onClick={handleApplyFilter}>Apply</button>
                      </div>
                  </div>
              </div>}
          </div>
      </div>
    </div>
  )
}

export default FilterCard


