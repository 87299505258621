import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, LabelList, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import ArrowRight from '../../../Assests/Images/arrow-right-white.svg'
import { useNavigate } from 'react-router-dom';
import { navigateToCircles } from '../../../utils';


const ComparisonBarTooltip = ({ active, payload, label, onMouseEnter, onMouseLeave, onClick , isChurch }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="comparison-bar-tooltip "
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <p>Discover how your people are doing by deploying the Church Pulse.</p>
        <div className='btn-box'>
        <div className='btn-box'>
          <button className="tool-tip-btn" onClick={(event) => { event.stopPropagation(); onClick(); }}>
            {isChurch ? "Deploy Assessment" : "Create your Circle"}
            <img src={ArrowRight} height={11} alt="ArrowRight Icon" />
          </button>
        </div>
        </div>
      </div>
    );
  }
  return null;
};


const renderCustomAxisTick = ({ x, y, payload }) => {
  const words = payload?.value?.split(' ') || [];
  const line1 = words[0];
  const line2 = words.slice(1).join(' ');

  const getTextWidth = (text, fontSize = 16, fontFamily = "F37 Jan") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  // Calculate the maximum width needed for the lines of text
  const textWidth = Math.max(getTextWidth(line1), getTextWidth(line2));
  const boxWidth = textWidth + 2 * 14.5;

  return (
    <g transform={`translate(${x},${y + 18})`}>
      {/* Background box */}
      <rect x={-boxWidth / 2} y={-32.95 / 2} width={boxWidth}  height={line2 ==='' ? 32.95: 51} rx={line2 ==='' ? 16 : 22 } ry={line2 ==='' ? 16 : 22 } fill="#FAFAFA"/>
      {/* Text lines */}
      <text x={0} y={5} textAnchor="middle" fontFamily="F37 Jan" fontSize="18px" fontWeight={700} fill="#171717" color="#171717">
        {line1}
      </text>
      {line2 && (
        <text x={0} y={21.6} textAnchor="middle" fontFamily="F37 Jan" fontSize="18px" fontWeight={700} fill="#171717" color="#171717">
          {line2}
        </text>
      )}
    </g>
  );
};


const CustomLabelWithBackground = (props) => {
  const { x, width, value, fill, ...rest } = props;
  const y = 20;
  return (
    <text color={fill} fill={fill} fontFamily="F37 Jan" fontSize="18px" fontWeight={700} textAnchor="middle" {...rest} x={x + width / 2} y={y} > {value} </text>
  );
};


export default function ComparisonBarChart({
  isDeployedDone,
  data,
  primaryColor,
  isChurch,
  comparison
}) {
  const dataKeys = ["mydata", "nationalaverage"];
  const initialcolors = ["#C581FF", "#00c980"];
  const initialtextcolour = ['#C581FF', "#00c980"];

  const [barHovered, setBarHovered] = useState(false);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [pos, setPos] = useState({});
  const navigate = useNavigate();
  const [hoveredBar, setHoveredBar] = useState(null);
  const colors = primaryColor ? [primaryColor, initialcolors[1]] : initialcolors;
  const textcolour = primaryColor ? ['#A3A3A3', initialtextcolour[1]] : initialtextcolour;
  const handleMouseMove = (event) => {
    if (!isDeployedDone && !tooltipHovered) {
      const { chartX, chartY } = event;
      setPos({ x: chartX - 90, y: chartY - 115 });
    }
  };

  const handleBarClick = (event, dataKey) => {
    const clickedBar = event?.activePayload?.[0]?.payload;
    if (clickedBar && clickedBar[dataKey]) {
      navigate(`/dimension-details/${clickedBar?.catId}/${encodeURIComponent(clickedBar?.name)}`, {
        state: {
          from: 'ComparisonBarChart',
          urldata: clickedBar,
          isDeployedDone:isDeployedDone,
          isChurch:isChurch,
          comparison:comparison
        },
      });
    } else {
      console.error("No data found for the clicked bar");
    }
  };

  return (
    <div className="comparison-bar-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          // onClick={handleMouseMove}
          onClick={(e) => handleBarClick(e, 'nationalaverage')}
          barGap={12}
          barSize={24}
          onMouseMove={handleMouseMove}
        >
          <XAxis
            padding={{ top: 50 }}
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={renderCustomAxisTick}
            height={60}
            interval={0}
          />
          {!isDeployedDone && (
            <Tooltip
              active={barHovered}
              cursor={{ fill: "transparent" }}
              position={pos}
              content={<ComparisonBarTooltip
                onMouseEnter={() => {
                  setBarHovered(true);
                  setTooltipHovered(true);
                }}
                onMouseLeave={() => {
                  setBarHovered(false);
                  setTooltipHovered(false);
                }}
                onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}
                isChurch={isChurch}
              />
              }
            />
          )}
          {dataKeys.map((dataKey, index) => (
            <Bar
              key={dataKey}
              id={!isDeployedDone && index === 0 ? "blurBar" : undefined}
              dataKey={dataKey}
              fill={colors[index]}
              radius={[4,4,0,0]}
              background={{
                id: !isDeployedDone && index === 0 ? "blurBar" : undefined,
                fill: "#f5f5f5",
                radius: [4, 4, 0, 0],
              }}
              onMouseEnter={() => !isDeployedDone && index === 0 && setBarHovered(true)}
              onMouseLeave={() => !isDeployedDone && index === 0 && setBarHovered(false)}
            >
              {data.map((entry, cellIndex) => (
                <Cell
                  key={`cell-${cellIndex}`}
                  onMouseEnter={() => setHoveredBar(cellIndex)}
                  onMouseLeave={() => setHoveredBar(null)}
                  style={{
                    transition: 'opacity 0.3s ease, filter 0.3s ease',
                    filter: !isDeployedDone && index === 0 ? "blur(5px)" : hoveredBar !== null && hoveredBar !== cellIndex ? "blur(2px)" : "none",
                    opacity: hoveredBar !== null && hoveredBar !== cellIndex ? 0.5 : 1,
                    strokeWidth: cellIndex === 2 ? 4 : 1
                  }}
                />
              ))}
              <LabelList
                dataKey={dataKey}
                position="top"
                content={
                  <CustomLabelWithBackground
                    id={!isDeployedDone && index === 0 ? "blurBar" : undefined}
                    fill={textcolour[index]}
                  />
                }
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
