import React, { useState } from 'react';
import { BarChart, Bar, XAxis, LabelList, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import ArrowRight from '../../../Assests/Images/arrow-right-white.svg'
import { useNavigate } from 'react-router-dom';
const NonComparisonBarChart = ({
  data,
  primaryKey = "mydata",
  secondaryKey = "nationalaverage",
  secondaryColor = "#C581FF",
  isDeployedDone,
 
}) => {
  const [barHovered, setBarHovered] = useState(false);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [pos, setPos] = useState({});
  const [hoveredBar, setHoveredBar] = useState(null);
  const navigate = useNavigate();
  const handleMouseMove = (event) => {
    if (!tooltipHovered) {
      const { chartX, chartY } = event;
      setPos({ x: chartX - 90, y: chartY - 115 });
    }
  };

  const handleBarClick = (event, dataKey) => {
    const clickedBar = event?.activePayload?.[0]?.payload;
    if (clickedBar && clickedBar[dataKey]) {
      navigate(`/dimension-details/${clickedBar?.catId}/${encodeURIComponent(clickedBar?.name)}`, {
        state: {
          from: 'NonComparisonBarChart',
          urldata: clickedBar,
          isDeployedDone:isDeployedDone,
        },
      });
    } else {
      console.error("No data found for the clicked bar");
    }
  };
  return (
    <div className="comparison-bar-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 25,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          // onClick={handleMouseMove}
          onClick={(e) => handleBarClick(e, 'mydata')}
          barGap={12}
          barSize={50}
          onMouseMove={handleMouseMove}
        >
          <XAxis
            padding={{ top: 50 }}
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={renderCustomAxisTick}
            height={60}
          />
          <Tooltip
            active={barHovered}
            cursor={{ fill: "transparent" }}
            position={pos}
            content={
              <ComparisonBarTooltip
                onMouseEnter={() => {
                  setBarHovered(true);
                  setTooltipHovered(true);
                }}
                onMouseLeave={() => {
                  setBarHovered(false);
                  setTooltipHovered(false);
                }}
              />
            }
          />
          {/* <Legend
              hide
              formatter={(value, entry, index) => (
                <span className="text-color-class">{valueMapper[value]}</span>
              )}
            /> */}
          <Bar
            dataKey={primaryKey}
            fill={secondaryColor}
            background={{ fill: "#F5F5F5",radius: [4, 4, 0, 0], }}
            style={{
              transition: 'opacity 0.3s ease'
            }}
            radius={[4,4,0,0]}
            
          >
            {data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`}
                fill={secondaryColor}
                opacity={hoveredBar !== null && hoveredBar !== index ? 0.5 : 1}
                onMouseEnter={() => setHoveredBar(index)}
                onMouseLeave={() => setHoveredBar(null)}
                strokeWidth={index === 2 ? 4 : 1}
                style={{
                  transition: 'opacity 0.3s ease, filter 0.3s ease',
                  filter: hoveredBar !== null && hoveredBar !== index ? 'blur(2px)' : 'none'
                }}
              />
            ))}
            <LabelList
              dataKey={primaryKey}
              position="top" 
              content={<CustomLabelWithBackground />}
            />
          </Bar>

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
const ComparisonBarTooltip = ({ active, payload, label, onMouseEnter, onMouseLeave }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="comparison-bar-tooltip "
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <p>Discover how your people are doing by deploying the Church Pulse.</p>
        <button onClick={(event) => { event.stopPropagation(); console.log("Create Circle") }}>Create your Circle<img src={ArrowRight} height={11} alt="ArrowRight Icon" /></button>
      </div>
    );
  }
  return null;
};
const renderCustomAxisTick = ({ x, y, payload }) => {
  // Split text if it contains a space
  const words = payload?.value?.split(' ') || [];
  const line1 = words[0];
  const line2 = words.slice(1).join(' ');
  return (
    <g transform={`translate(${x},${y + 10})`}>
      <defs>
        <filter x="-15%" y="-15%" width="130%" height="130%" id="solid" primitiveUnits="objectBoundingBox">
          <feImage preserveAspectRatio="none" width="130%" height="130%" x="-15%" y="-15%" xlinkHref="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 124 51' width='124' height='51'><rect fill='rgb(250, 250, 250)' x='0' y='0' rx='28.94' ry='28.94' width='123.94' height='51'/></svg>"
          />
          <feComposite operator="over" in="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#solid)">
        <text x={0} y={0} dy={16} textAnchor="middle" fontFamily="F37 Jan" fontSize="18px" fontWeight={700} fill="#171717" color="#171717">
          {line1}
        </text>
        {line2 && (
          <text x={0} y={25} dy={16} textAnchor="middle" fontFamily="F37 Jan" fontSize="18px" fontWeight={700} fill="#171717" color="#171717">
            {line2}
          </text>
        )}
      </g>
    </g>
  );
};
const CustomLabelWithBackground = (props) => {
  const { x, width, value, ...rest } = props;
  const y = 20;
  return (
    <text fontFamily="Poppins" fontSize="14px" fontWeight={500} textAnchor="middle" {...rest} x={x + width / 2} y={y} color="#171717" fill="#171717">{value}</text>
    // <text x={x + width / 2} y={y } fill="#171717" textAnchor="middle" dominantBaseline="middle">
    //   {value}
    // </text>
  );
};
const valueMapper = {
  mydata: "My Data",
  nationalaverage: "National Average"
};
export default NonComparisonBarChart;