import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../components/DemographicsDetailsNew/DemographicsDetailsNew.scss";
import LeftArrow from "../../../Assests/Images/leftarrow.svg";
import { navigateToCircles } from "../../../utils";

const DemographicsDetailsMobile = ({ data, isChurch }) => {

  const location = useLocation();
  const [isHover, setHover] = useState(null);
  const navigate = useNavigate();
  const { isDeployedDone, isComparisonBarChart } = location.state || {};

  // ToolTip
  const TooltipComponent = ({ isVisible, isChurch }) => {
    if (!isVisible) return null;

    return (
      <div className="customtootip-container">
        <div className="customtootip">
          <p>
            Discover how your people are doing by deploying the ChurchPulse.
          </p>
          <button onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
            {isChurch ? "Deploy Assessment" : "Create your Circle"}
            <img src={LeftArrow} alt="" />
          </button>

          {/* Tooltip arrow */}
          <div
            style={{
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid #ffffff",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="chart-mobile">
      {data.map((data, index) => {
        const {
          name,
          primaryData,
          secondaryData,
          normalizedPrimaryHeight,
          normalizedSecondaryHeight,
        } = data;
        const isHovered = isHover === index;
        return (
          <div className="chart-mobile-container">
            <div className="chart-label">{name}</div>
            <div className="chart-bar" key={index}>
              <div className="data-value">
                {/* Org side  */}
                <p
                  className="data-one"
                  style={{
                    filter:
                      !isDeployedDone && isComparisonBarChart
                        ? "blur(6px)"
                        : "none",
                    color:
                      !isDeployedDone && isComparisonBarChart
                        ? "#D4D4D4"
                        : isComparisonBarChart
                        ? "#C581FF"
                        : "#171717",
                  }}
                >
                  {primaryData || 0}
                </p>
                {/* National side  */}
                {isComparisonBarChart && (
                  <p className="data-two" style={{}}>
                    {secondaryData || 0}
                  </p>
                )}
              </div>

              <div
                className={`databar ${index}`}
                onMouseEnter={() => {
                  setHover(!isDeployedDone ? index : null);
                }}
                onMouseLeave={() => {
                  setHover(null);
                }}
                style={{
                  opacity: isHover !== null && index !== isHover ? 0.4 : 1,
                  gap: "1px",
                  backgroundColor: isComparisonBarChart
                    ? "rgba(229, 229, 229, 0.3)"
                    : "#E5E5E5",
                }}
              >
                {/* Stacked bar for primary data (ORG data) */}
                <div
                  className="dataheightone"
                  style={{
                    width: `${normalizedPrimaryHeight || 0}%`,
                    transition: "width 0.5s linear",
                    // backgroundColor: '#00C980',
                    filter:
                      !isDeployedDone && isComparisonBarChart
                        ? "blur(10px)"
                        : "none",
                    backgroundColor:
                      !isDeployedDone && isComparisonBarChart
                        ? "#D4D4D4"
                        : "#C581FF",
                    borderRadius: isComparisonBarChart
                      ? "8px 8px 8px 0px"
                      : "8px",
                    height: "100%",
                  }}
                ></div>
                {/* Stacked bar for secondary data (National Data)*/}
                {isComparisonBarChart && (
                  <div
                    className="dataheighttwo"
                    style={{
                      width: `${normalizedSecondaryHeight || 0}%`,
                      transition: "height 0.5s linear",
                      height: "100%",
                    }}
                  ></div>
                )}

                {
                  <TooltipComponent
                    isVisible={!isDeployedDone && isHover === index}
                    isChurch={isChurch}
                  />
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DemographicsDetailsMobile;
