import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchNationalAverage,
  fetchNationalAverageByFilter,
  fetchDashboardData,
  fetchSevenDimensions,
  fetchFifteenDimensions,
  fetchGrowthGoals,
  fetchDemographics,
  fetchChurchData,
  fetchChurchDataByFilters,
  fetchNationalDemographics,
  fetchChurchDemographics,
  fetchNationalGrowthGoals,
  fetchChurchGrowthGoals,
  fetchNationalAverageDataByCategory,
  fetchOrganizationDataByCategory,
  fetchDeploymentData,
  fetchOrganizationDemographicDetails,
  fetchNationalAverageDemographicDetails,
  fetchCircleInformation,
  closeDeploymentMutation,
} from "../components/api/query-api";

export const useNationalAverage = () =>
  useQuery({
    queryKey: ["national-average"],
    queryFn: fetchNationalAverage
  });

export const useChartData = (filter, isLoading = false) => {
  const payload = {
    "dateFilter": filter["timeline"],
    "additionalFilters": {
      "ageRange": filter["age"],
      "gender": filter["gender"]
    }
  };

  const getQuery = () => {
    return filter["dataset"] === "National Average"
        ? fetchNationalAverageByFilter(payload)
      : fetchChurchDataByFilters({...payload, orgIds: filter["orgIds"]})
  };
  return useQuery({
    queryKey: ["national-average", filter],
    queryFn: () => getQuery(),
    enabled: !isLoading
  })
}
export const useChurchHealthDemographicsData = (filter, isLoading = false) => {
  let payload = {};
  let additionalFiltersObj = {};
  if(filter["orgIds"] && filter["orgIds"].length && filter["dataset"] !== "National Average") payload.orgIds = filter["orgIds"];
  if(filter["timeline"] && filter["timeline"].length) payload.dateFilter = filter["timeline"];
  if(filter["age"] && filter["age"].length) additionalFiltersObj.ageRange = filter["age"];
  if(filter["gender"] && filter["gender"].length) additionalFiltersObj.gender = filter["gender"];
  if(additionalFiltersObj.ageRange || additionalFiltersObj.gender) payload.additionalFilters = additionalFiltersObj;

  const getQuery = () => {
    return filter["dataset"] === "National Average" ?
      fetchNationalDemographics(payload)
      : fetchChurchDemographics(payload);
  };
  return useQuery({
    queryKey: ["demographic-average", filter],
    queryFn: () => getQuery(),
    enabled: !isLoading
  })
}

export const useChurchHealthGrowthGoals = (filter, isLoading = false) => {
  let payload = {};
  let additionalFiltersObj = {};
  if(filter["orgIds"] && filter["orgIds"].length && filter["dataset"] !== "National Average") payload.orgIds = filter["orgIds"];
  if(filter["timeline"] && filter["timeline"].length) payload.dateFilter = filter["timeline"];
  if(filter["age"] && filter["age"].length) additionalFiltersObj.ageRange = filter["age"];
  if(filter["gender"] && filter["gender"].length) additionalFiltersObj.gender = filter["gender"];
  if(additionalFiltersObj.ageRange || additionalFiltersObj.gender) payload.additionalFilters = additionalFiltersObj;

  const getQuery = () => {
    return filter["dataset"] === "National Average" ?
    fetchNationalGrowthGoals(payload)
      : fetchChurchGrowthGoals(payload);
  };
  return useQuery({
    queryKey: ["growth-goals-average", filter],
    queryFn: () => getQuery(),
    enabled: !isLoading
  })
}

export const useDashboardData = (orgId, token) =>
  useQuery({
    queryKey: ["dashboard-data", orgId, token],
    queryFn: () => fetchDashboardData(orgId, token)
  });

export const useSevenDimensions = (orgId, token) => 
  useQuery({
    queryKey: ["seven-dimensions", orgId, token],
    queryFn: () => fetchSevenDimensions(orgId, token)
  })

export const useFifteenDimensions = (orgId, token) =>
  useQuery({
    queryKey: ["fifteen-dimensions", orgId, token],
    queryFn: () => fetchFifteenDimensions(orgId, token),
  });

export const useGrowthGoals = (orgId, token) =>
  useQuery({
    queryKey: ["growth-goals", orgId, token],
    queryFn: () => fetchGrowthGoals(orgId, token),
  });
  

export const useDemographicsData = (orgId, token) =>
  useQuery({
    queryKey: ["demographics-data", orgId, token],
    queryFn: () => fetchDemographics(orgId, token),
  });

export const useByCategoryData = ({filter, catId}, isEnabled) => {
  
  let payload = {};
  let additionalFiltersObj = {};
  if(filter["orgIds"] && filter["orgIds"].length && filter["dataset"] !== "National Average") payload.orgIds = filter["orgIds"];
  if(filter["timeline"] && filter["timeline"].length) payload.dateFilter = filter["timeline"];
  if(filter["age"] && filter["age"].length) additionalFiltersObj.ageRange = filter["age"];
  if(filter["gender"] && filter["gender"].length) additionalFiltersObj.gender = filter["gender"];
  if(additionalFiltersObj.ageRange || additionalFiltersObj.gender) payload.additionalFilters = additionalFiltersObj;

  const getQuery = () => {
    return filter["dataset"] === "National Average" ?
      fetchNationalAverageDataByCategory(payload, catId)
      : fetchOrganizationDataByCategory(payload, catId);
  };

  return useQuery({
    queryKey: ["by-category-data", filter, catId],
    queryFn: () => getQuery(),
    enabled: Boolean(isEnabled)
  });
} 

export const useDeploymentData = (orgId, options = {}) =>
  useQuery({
    queryKey: ["deployment-data", orgId],
    queryFn: () => fetchDeploymentData(orgId),
    ...options
  });
    
  export const useDemographicsDetailsData = (filter, token) => {
    let payload = {};
    let additionalFiltersObj = {};
    if(filter["dataset"] !== "National Average") payload.orgIds = filter["orgIds"] || [];
    if(filter["timeline"] && filter["timeline"].length) payload.dateFilter = filter["timeline"];
    if(filter["ageRange"] && filter["ageRange"].length) additionalFiltersObj.ageRange = filter["ageRange"];
    if(filter["gender"] && filter["gender"].length) additionalFiltersObj.gender = filter["gender"];
    if(additionalFiltersObj.ageRange || additionalFiltersObj.gender) payload.additionalFilters = additionalFiltersObj;
  

    const getQuery = () => {
      return filter["dataset"] === "National Average" ?
      fetchNationalAverageDemographicDetails(payload, token)
        : fetchOrganizationDemographicDetails(payload, token);
    };
    return useQuery({
      queryKey: [`${filter["dataset"]}-demographic-details`, filter["dataset"]],
      queryFn: () => getQuery()
    })
  }

export const useFetchCircleInformation = (token) => 
  useQuery({
    queryKey: ["circles"],
    queryFn: () => fetchCircleInformation(token),
  });

export const useCloseDeploymentMutation = (token) =>
  useMutation({
    mutationFn: ({ orgId, payload }) => closeDeploymentMutation(orgId, payload, token),
  });