import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import Layout from './layout/Main/Layout';
import Temp from './pages/Temp/Temp';
// import Home from './components/Home/Home';
import Tools from './pages/Tools/Tools';
import DimensionDetail from './pages/DimensionDetail/DimensionDetail';
import Spiritual from './pages/Spiritual/Spiritual';
import Resources from './pages/Resources/Resources';
import AssesmentsBarnaChurchPulse from './pages/Assesments/AssesmentsBarnaChurchPulse';
import Deployments from './pages/Deployments/Deployments';
import Library from './pages/Library/Library';
import Home from './pages/Home/Home';
import Assessments from './pages/Assesments/Assessments';
import Authenticate from './pages/Authenticate/Authenticate';
import Map from './pages/Map/Map';
import Directory from './pages/Directory/Directory';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import DonutChart from './components/Chart/DonutChart';
import Demographics from './components/DemographicsDetails/DemographicsDetails';
import AssessmentsTools from './components/Assessments/ChurchLeader/AssessmentsTools';
import DemographicsDetailsNew from './components/DemographicsDetailsNew/DemographicsDetailsNew';
import DemographicsDetails from './components/ChurchHealth/DemographicsDetails/DemographicsDetails';
import Deploy from "./pages/Deploy/Deploy"
import Organizations from './pages/Organizations/Organizations';
import MobileFilterCard from './components/MobileFilterCard/MobileFilterCard';
import { AuthContextProvider } from './store/AuthContext';
import DeployWaiting from './pages/DeployWaiting/DeployWaiting';
import ChurchHealth from './pages/ChurchHealth/ChurchHealth';
import ChurchHealthDeploy from './pages/ChurchHealthDeploy/ChurchHealthDeploy';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // default to 20 seconds
      staleTime: 1000 * 20,
      refetchOnWindowFocus: false,
      retry: false
    },
  },
})

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Routes>
                <Route path="/" exact index element={<Home />} />
                <Route path="/card" exact index element={<MobileFilterCard />} />
                <Route path="/authenticate" exact index element={<Authenticate />} />
                <Route path="/home" exact index element={<Home />} />
                <Route path="/deploy" exact index element={<Deploy />} />
                <Route path="/deploy-waiting" exact index element={<DeployWaiting />} />
                <Route path="/temp" exact index element={<Temp />} />
                <Route path="/tools" exact index element={<Tools />} />
                <Route path="/dimension-details/:id/:groupName" exact index element={<DimensionDetail />} />
                <Route path="/details/:id/:groupName" exact index element={<Spiritual />} />
                <Route path="/resources" exact index element={<Resources />} />
                <Route path="/deployments" exact index element={<Deployments />} />
                <Route path="/organizations" exact index element={<Organizations />} />
                <Route path="/library" exact index element={<Library />} />
                <Route path="/assessments/details/:deployId?" exact index element={<Assessments />} />
                <Route path="/demographics" exact index element={<Demographics />} />
                <Route path="/demographics-details" exact index element={<DemographicsDetailsNew />} />
                <Route path="/comparative-demographic-details" exact index element={<DemographicsDetails />} />
                <Route path="/assesement/bananchurhname" exact index element={<AssesmentsBarnaChurchPulse />} />
                <Route path="/map" exact index element={<Map />} />
                <Route path="/directory" exact index element={<Directory />} />
                <Route path="/login" exact index element={<Login />} />
                <Route path="/register" exact index element={<Register />} />
                <Route path="/forgot-password" exact index element={<ForgotPassword />} />
                <Route path="/assessments/library" exact index element={<AssessmentsTools />} />
                <Route path="/network-health-deploy" exact index element={<ChurchHealthDeploy isChurch={false} />} />
                <Route path="/church-health" exact index element={<ChurchHealth />} />
                <Route path="/church-health-deploy" exact index element={<ChurchHealthDeploy isChurch={true}/>} />
                {/* <Route path="/charts-test" exact index element={<DonutChart />} /> */}
              </Routes>
            </Layout>
            {/* </DashboardContextProvider> */}
          </QueryClientProvider>
        </AuthContextProvider>
      </BrowserRouter>

      <ToastContainer
        autoClose={3000}
        position="bottom-center"
      />
    </div>
  );
}

export default App;
