import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from "react-toastify";

import { InputBase, IconButton, Button } from '@mui/material';
import { CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import upanddownIcon from '../../Assests/Images/upanddoenIcon.svg'
import { IoArrowUpOutline } from "react-icons/io5";
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import TripleDots from '../../Assests/Images/TripleDots.svg';
import DeploymentChart from '../../components/Chart/DeploymentChart';
import ArrowUp from '../../Assests/Images/arrow-up.svg'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Checkbox from '../../Assests/Images/Uncheckboxes.png';
import Uncheckbox from '../../Assests/Images/Checkboxes.png';
import allcheckbox from '../../Assests/Images/AllCheckboxes.png'
import Delete from '../../Assests/Images/Delete-Icon.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";

import CloseIcon from '@mui/icons-material/Close'
import Alert from '../../Assests/Images/alert.svg';
import AuthContext from '../../store/AuthContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateURLDeployment } from '../../components/api/query-api';

const Deployments = () => {

  const { windowHeight } = useWindowDimensions();

  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [url, setUrl] = useState('');

  // const getOrgIdFromUrl = () => { return authCtx.orgId };
  // const orgId = getOrgIdFromUrl();
  const code = searchParams?.get('code');

  const [statusFilter, setStatusFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortField, setSortField] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const statusDropdownRef = useRef(null);
  const typeDropdownRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState({ ongoing: false, fixed: false, });
  const [selectedIds, setSelectIds] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)
  // const [selectedDeployment, setSelectedDeployment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const queryClient = useQueryClient();
  const [onSaveUrl, setOnSaveUrl] = useState(false);
  const [urlInput, setUrlInput] = useState(url);



  const navigate = useNavigate();
  // window.parent.postMessage(message, "*");

  const [deployments, setDeployments] = useState(null);

  const datachar = [
    { date: '2024-09-25T00:00:00', green: 400 },
    { date: '2024-09-25T01:00:00', green: 420 },
    { date: '2024-09-25T02:00:00', green: 390 },
    { date: '2024-09-25T03:00:00', green: 380 },
    { date: '2024-09-25T04:00:00', green: 410 },
    { date: '2024-09-25T05:00:00', green: 370 },
    { date: '2024-09-25T21:00:00', green: 370 },
    { date: '2024-09-25T22:00:00', green: 360 },
    { date: '2024-09-25T23:00:00', green: 350 },

    // 1-Week Data (Daily data points)
    { date: '2024-09-19T00:00:00', green: 400 },
    { date: '2024-09-20T00:00:00', green: 420 },
    { date: '2024-09-21T00:00:00', green: 390 },
    { date: '2024-09-22T00:00:00', green: 410 },
    { date: '2024-09-23T00:00:00', green: 370 },
    { date: '2024-09-24T00:00:00', green: 430 },
    { date: '2024-09-25T00:00:00', green: 450 },

    // Existing Data (Assuming from another time period or dataset)
    { date: '2024-09-10T08:30:00', green: 400 },
    { date: '2024-09-11T12:45:00', green: 350 },
    { date: '2024-09-12T14:00:00', green: 300 },
    { date: '2024-09-13T10:15:00', green: 380 },
    { date: '2024-09-14T09:45:00', green: 400 },
    { date: '2024-09-15T11:30:00', green: 360 },
    { date: '2024-09-16T13:15:00', green: 370 },
    { date: '2024-01-15T12:30:00', green: 400 },
    { date: '2024-03-10T14:00:00', green: 300 },
    { date: '2024-06-20T09:45:00', green: 350 },
    { date: '2024-09-05T16:30:00', green: 370 },
    { date: '2024-12-01T11:15:00', green: 310 },
  ];

  // useEffect(() => {    
  //   window.parent.postMessage({ event: 'sotcEmbed:dimensions', name: 'sotcEmbed', height: windowHeight }, 'https://app.dev.gloo.us')
  // }, [windowHeight]);

  // useEffect(() => {
  //   console.log(window.innerHeight);
  //   window.parent.postMessage(
  //     { 
  //       event: 'sotcEmbed:dimensions',
  //       name: 'sotcEmbed',
  //       height: window.innerHeight
  //     },
  //     'https://app.dev.gloo.us'
  //   )
  // }, []);

  useEffect(() => {

    if (authCtx.isLoggedIn && authCtx.orgId != 0 && authCtx.glooOrgId != 0) {      
      fetchDeployments(authCtx.orgId);
    } else if (code != "" && code != undefined) {
      // get token
      console.log("Getting token");
      console.log(code);
      const payload = {
        code: code,
        path: window.location.pathname
      }

      fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/oauth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            const tokenExpiration = new Date(new Date().getTime() + 7200000);
            console.log("We are here");
            console.log(data.data.accessToken);
            console.log(tokenExpiration);
            authCtx.login(data.data.accessToken, tokenExpiration);

            fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/userinfo`, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
            })
              .then(response => {
                return response.json();
              }).then(data => {
                console.log(data);
                if (data.status == 'success') {
                  authCtx.update(data?.data?.glooUserId, data?.data?.glooOrgId, data?.data?.orgName, data?.data?.orgId, data?.data?.email, data?.data?.name, data?.data?.orgType, data?.data?.circles);

                  // if ( data?.data?.orgType === "network" ) {
                  //   navigate("/network-health-deploy");
                  // }
                  fetch(`${process.env.REACT_APP_API_URI}/v5/organisation/gloo/` + data?.data?.orgId + `/deployments`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
                  })
                  .then(response => {
                    return response.json();
                  }).then(data => {
                    if(data.status == "success") {
                      setDeployments(data.data);
                      setIsLoading(false);
                    }
                    
                  }).catch(err => {
                    console.error("no success")
                    return { status: "failure", error: err.message }
                  });
                }
              }).catch(err => {
                console.error("no success")
                return { status: "failure", error: err.message }
              });

          }
        }).catch(err => {
          console.error(err)

        });
    } else {
      window.location.href="https://app.gloo.us/oauth/authorize?client_id=92913.f1f2c96dc3e967a16989beeb58aa9f07&redirect_uri=https://dashboard.survey.church/home&response_type=code";
    }

  }, [])

  const fetchDeployments = async (orgId) => {

    // if ( !(authCtx.orgId || orgId) ) {
    //   window.location.reload();
    // }
    const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${authCtx.orgId || orgId}/deployments`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status === "success") {
        setDeployments(data.data);
        setIsLoading(false);
      } else {
        console.error("Failed to fetch insights data");
        setDeployments([]);
      }
    } catch (err) {
      console.error("Error fetching insights data...", err);
      throw err;
    }

  }

  const sortedDeployments = deployments ? [...deployments] : [].sort((a, b) => {
    if (!sortField) return 0;
    const fieldA = a[sortField];
    const fieldB = b[sortField];

    if (typeof fieldA === 'string' && typeof fieldB === 'string') {
      return sortDirection === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
    }
    if (typeof fieldA === 'number' && typeof fieldB === 'number') {
      return sortDirection === 'asc' ? fieldA - fieldB : fieldB - fieldA;
    }
    if (fieldA instanceof Date && fieldB instanceof Date) {
      return sortDirection === 'asc' ? fieldA - fieldB : fieldB - fieldA;
    }
    return 0;
  });

  // const deleteRow = (id) => {
  //   setDeployments((prevDeployments) =>
  //     prevDeployments.filter((deployment) => deployment.id !== id)
  //   );
  //   setSelectIds((prevSelected) =>
  //     prevSelected.filter((selectedId) => selectedId !== id)
  //   );
  // };

  const deleteDeployment = async (deploymentId) => {
    const apiUrl = `${process.env.REACT_APP_API_URI}/v5/admin/organisation/${authCtx.orgId}/deployments/${deploymentId}`;    
    const response = await fetch(apiUrl,{
      headers: { 'Authorization': 'Bearer ' + authCtx.token, },
      method: 'Delete',
      mode: 'cors', 
    });
    if (!response.ok) {
      throw new Error(`Error! Delete Deployment status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === "success") {
      console.log(data);
      return true;
    } else {
      console.error("Failed to delete deployment ", deploymentId);
      return false
    }
  }

  const deleteSelectedRows = () => {
    for (let id of selectedIds) {
      const { title } = deployments.find( p => p.id == id );
      deleteDeployment(id).then((response) => {
        if ( response ) {
          toast.success(`Deployment ${title} is deleted successfully`);
          setDeployments(prevDeployment => prevDeployment.filter( p => p.id != id ));
          setSelectIds(prevIds => prevIds.filter( p => p != id));
        } else {
          toast.error(`Error in deleting Deployment ${title}.`);
          console.error("Error! Delete deployment", id);
        }
      }).catch(err => {
        toast.error(`Network Error in deleting Deployment ${title}.`);
        console.error({err})
      });
    }
    closeDeleteModal()
  };


  const fetchConfigureUrl = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/${authCtx.orgId}/details`,
        {
          headers: {
            'Authorization': `Bearer ${authCtx.token}`,
            'Content-Type': 'application/json',
          }
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to fetch organization details');
      }
  
      const responseData = await response.json();
      const fetchedUrl = responseData.data.url;
      setUrl(fetchedUrl);
    } catch (err) {
      console.log(err.message);
    }
  };
  
  const updateUrlMutation = useMutation({
    mutationFn: async (newUrl) => updateURLDeployment(authCtx.orgId, authCtx.token, newUrl),
    onSuccess: async (_, variables) => {
      console.log("URL updated successfully:", variables);
      setOnSaveUrl(false); 
      setUrl(variables);
    },
    onError: (error) => {
      console.error('Failed to update URL:', error);
    }
  });
  

  useEffect(() => {
    console.log("Fetching URL...");
    fetchConfigureUrl();
  }, [authCtx.orgId, authCtx.token, updateUrlMutation.isSuccess]);

  console.log("selectedIds", selectedIds)


  let displayedDeployments = sortedDeployments;

  // Search filter
  if (searchQuery) {
    displayedDeployments = displayedDeployments.filter((deployment) =>
      deployment.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  if (typeFilter) {

    displayedDeployments = displayedDeployments.filter((d) => d.type?.toLowerCase() === typeFilter);
  }
  const getDeploymentStatus = (deployment) => {
    const currentDate = new Date();
    const { startDate, endDate, startTime, endTime, type } = deployment;
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    const [startHour = 0, startMinute = 0] = startTime ? startTime.split(':').map(Number) : [0, 0];
    const [endHour = 0, endMinute = 0] = endTime ? endTime.split(':').map(Number) : [0, 0];
  
    startDateTime.setHours(startHour, startMinute, 0, 0);
    endDateTime.setHours(endHour, endMinute, 0, 0);

    if (endDateTime < currentDate) {
      return 'Off';
    }
    if(startDate === 'Invalid Date'){
      return 'Off';
    }
    if (startDateTime > currentDate) {
      return 'Off';
    }
    if(startDateTime <= currentDate && endDateTime > currentDate){
      return 'On'
    }
    if (type === "Ongoing") {
      return 'On';
    }
    return 'Off';
  };
  
  if (statusFilter) {
    displayedDeployments = displayedDeployments.filter((deployment) => {
      const deploymentStatus = getDeploymentStatus(deployment); // Get status
      if (statusFilter === "on") {
        return deploymentStatus === "On"; // Show only active deployments
      }
      if (statusFilter === "off") {
        return deploymentStatus === "Off"; // Show only inactive deployments
      }
      return true; // Default (no filter)
    });
  }
  const totalPages = Math.ceil((deployments?.length || 0) / itemsPerPage);
  const [interval, setInterval] = useState(null);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, (deployments?.length || 0));
  displayedDeployments = displayedDeployments.slice(startIndex, endIndex);

  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval);
  };

  const handleRowUpdates = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1)
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  }

  const toggleCollapse = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const toggleAll = () => {
    if (selectedIds.length === deployments?.length) {
      setSelectIds([])
    }
    else {
      setSelectIds(() => deployments.map(dep => dep.id))
    }
  }

  const toggleCheckbox = (id) => {
    let newIds = [];
    if (selectedIds.includes(id)) {
      newIds = selectedIds.filter(_id => _id !== id);
    }
    else {
      newIds = [...selectedIds, id]
    }
    setSelectIds(newIds);
  };

  useEffect(() => {
    setShowDelete(selectedIds.length > 0)
  }, [selectedIds])


  const toggleCheckboxs = (type) => {
    setCheckedItems((prev) => {
      const newCheckedItems = {
        ongoing: type.toLowerCase() === 'ongoing' ? !prev.ongoing : false,
        fixed: type.toLowerCase() === 'fixed' ? !prev.fixed : false,
      };
      setTypeFilter(newCheckedItems.ongoing ? 'ongoing' : newCheckedItems.fixed ? 'fixed' : '');
      return newCheckedItems;
    });
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ( event.target.className === "filter-dropdown" ) {
        return;
      }
      if (
        (statusDropdownRef?.current && !statusDropdownRef.current.contains(event.target)) ||
        (typeDropdownRef?.current && !typeDropdownRef.current.contains(event.target))
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [statusDropdownRef, typeDropdownRef]);



  const handleDeploymentClick = (deployment) => {

    console.log("deployment", deployment)
    // if(deployment.status === "Off") {
    localStorage.setItem("deployment", JSON.stringify(deployment));
    navigate(deployment.respondents === "No" ? `/assessments/details/${deployment.id}?tab=share` : `/assessments/details/${deployment.id}`, {
    });
    // }
  };

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const openDeleteModal = () => {
    // setSelectedDeployment(deployment);
    setDeleteModal(true);
  };


  const closeDeleteModal = () => {
    setDeleteModal(false);
    // setSelectedDeployment(null);
  };

  // const openDeleteModal = (checkedItems) => {
  //   setSelectedDeployment(checkedItems)
  //   setDeleteModal(true)
  // }

  // const closeDeleteModal = () => {
  //   setDeleteModal(false)
  //   setSelectedDeployment(null)
  // }

  // const confirmDelete = () => {
  //   if (selectedDeployment) {
  //     deleteRow(selectedDeployment.id);
  //     closeModal();
  //   }
  // };

  console.log(displayedDeployments);

  function isDatePast(dateToCheck) {
    const now = new Date(); // Current date and time
    return dateToCheck < now;
  }

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };
  
  const handleOnSave = () => {
    updateUrlMutation.mutate(urlInput); // Pass the current input value
  };
  
  const handleEdittoggle = () => {
    setUrlInput(url); // Reset input to current value when editing starts
    setOnSaveUrl(true);
  };
  
  const baseUrl = 'https://survey.church/';



  return (
    <div className='deployment-container'>
      <div className="search-deployments">
        <InputBase
          placeholder="Search Deployments"
          className="search"
          startAdornment={
            <IconButton type="button" sx={{ padding: 0, margin: 0 }}>
              <SearchIcon sx={{ color: '#171717' }} />
            </IconButton>
          }
          sx={{
            padding: 0,
            '& .MuiInputBase-input': {
              padding: '0 0 0 8px',
              '::placeholder': { color: '#737373', opacity: 1 },
            },
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className='assessment-url-container'>
          <div className='assesment-url-title'>
            <p>Assessment URL</p>
          </div>
          {/* {onSaveUrl ? 
          <div className="assesment-url-input-container">
          <div className='url-input-container'>
              <span className='https-text'>{baseUrl}</span>
              <div  className='url-input-box' >
              <input type="text"  className='url-input' value={configureUrl} onChange={handleInputChange} />
              <buttton className="assessment-save-ctn" onClick={handleOnSave}>Save</buttton>
              </div>
          </div>
        </div> :  
        <div className="edit-url-input-container">
        <div className='edit-input-container'>
            <span className='edit-url'>{baseUrl}{configureUrl ? `${configureUrl}` : ''}</span>
            <button  className='edit-ctn' onClick={handleEdittoggle}>Edit</button>
        </div>
      </div>
          } */}
          {onSaveUrl ? 
            <div className="assesment-url-input-container">
              <div className='url-input-container'>
                <span className='https-text'>{baseUrl}</span>
                <div className='url-input-box'>
                  <input type="text"  className='url-input' value={urlInput} onChange={handleInputChange} />
                  <button className="assessment-save-ctn" onClick={handleOnSave}disabled={updateUrlMutation.isLoading}>
                    {updateUrlMutation.isLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </div> : 
            <div className="edit-url-input-container">
              <div className='edit-input-container'>
                <span className='edit-url'>{baseUrl}{url ? `${url}` : ''}</span>
                <button className='edit-ctn' onClick={handleEdittoggle}>Edit</button>
              </div>
            </div>
          }
        </div>
        {/* <Button variant="contained" startIcon={<AddOutlinedIcon style={{ fontSize: 24 }} />} className='deploy-btn'>
          Deploy New
        </Button> */}
      </div>
      <div className='main-content'>
        <div className="table-container">
          <div className="table-header">
            <div className="filter-dropdowns">
              {/* Status Dropdown */}
              <div className="dropdown-container">
                <button className="filter-dropdown" onClick={() => toggleDropdown("status") }>Status</button>
                <ExpandMoreOutlinedIcon className="dropdown-icon" sx={{ color: '#171717' }} />
                {openDropdown === 'status' && (
                  <div className="dropdown-menu" ref={statusDropdownRef}>
                    <label className="label" onClick={() => setStatusFilter((prev) => (prev === 'on' ? '' : 'on'))}>
                      Published
                      <img src={statusFilter === 'on' ? Uncheckbox : Checkbox} alt={statusFilter === 'on' ? "Checked" : "Unchecked"} />
                    </label>
                    <label className="label" onClick={() => setStatusFilter((prev) => (prev === 'off' ? '' : 'off'))}>
                      Unpublished
                      <img src={statusFilter === 'off' ? Uncheckbox : Checkbox} alt={statusFilter === 'off' ? "Checked" : "Unchecked"} />
                    </label>
                  </div>
                )}
              </div>

              {/* Type Dropdown */}
              <div className="dropdown-container">
                <button className="filter-dropdown" onClick={() => toggleDropdown('type')}>Type</button>
                <ExpandMoreOutlinedIcon className="dropdown-icon" sx={{ color: '#171717' }} />
                {openDropdown === 'type' && (
                  <div className="dropdown-menu" ref={typeDropdownRef}>
                    <label className="label" onClick={() => toggleCheckboxs('ongoing')}>
                      Ongoing
                      <img src={checkedItems.ongoing ? Uncheckbox : Checkbox} alt={checkedItems.ongoing ? "Checked" : "Unchecked"} />
                    </label>
                    <label className="label" onClick={() => toggleCheckboxs('fixed')}>
                      Fixed
                      <img src={checkedItems.fixed ? Uncheckbox : Checkbox} alt={checkedItems.fixed ? "Checked" : "Unchecked"} />
                    </label>
                  </div>
                )}
              </div>

            </div>
            <div className='delete-container'>
              {showDelete && (
                <button className="delete-button" onClick={openDeleteModal}>
                  <img src={Delete} className='delete-icon' />
                  <p>Delete</p>
                </button>
              )}
            </div>
            {deleteModal && (
              <div className='modal'>
                <div className='modal-content'>
                  <div className="heading-container">
                    <div className="heading">
                      <p className="delete">Delete {selectedIds.length > 0 ? `${selectedIds.length === 1 ? "Deployment" : `${selectedIds.length} Deployments`}` : null}?</p>
                      <CloseIcon className='close-icon' onClick={closeDeleteModal} />
                    </div>
                  </div>
                  <div className="mid-container">
                    <div className="alert">
                      <img src={Alert} alt="" />
                    </div>
                    <div className="confirmation-msg">
                    <p className="message">Are you sure you want to delete {selectedIds.length >= 2 ? (`${selectedIds.length} Deployments`) : ("this Deployment")}?</p>
                      <p className="delete-info">
                        Your results will be deleted and your respondents will no longer be able to take this assessment.
                      </p>
                    </div>

                  </div>
                  <div className="buttons-container">
                    <Button className='cancel' onClick={closeDeleteModal}>Cancel</Button>
                    <Button className='delete-btn' onClick={deleteSelectedRows}>Delete {selectedIds.length >= 2  ? `(${selectedIds.length})` : null}</Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {
            !isLoading ? <div className='deployments-list'>
              <div className='header-row'>
                <div className='cell checkbox-column' style={{ padding: 0 }}>
                  <div className='cell checkbox-column' onClick={() => toggleAll()}>
                    {deployments.length > 0 && <img height={16} width={16} src={(selectedIds.length === deployments.length) ? allcheckbox : Checkbox} alt={selectedIds.length === deployments.length ? "Checked" : "Unchecked"} />}
                  </div>
                </div>
                <div className='cell name-column' style={{display: "flex"}}>Name <IoArrowUpOutline color="#000" style={{marginLeft: 32}} /></div>
                <div className='cell status-column'>Status <img src={upanddownIcon} alt="upanddownIcon" height={14} width={9} onClick={() => handleSort('status')} /></div>
                <div className='cell type-column'>Type <img src={upanddownIcon} alt="upanddownIcon" height={14} width={9} onClick={() => handleSort('type')} /></div>
                <div className='cell assessments-column'>Assessments <img src={upanddownIcon} alt="upanddownIcon" height={14} width={9} /></div>
                <div className='cell respondents-column'>Respondents <img src={upanddownIcon} alt="upanddownIcon" height={14} width={9} onClick={() => handleSort('respondents')} /></div>
                <div className='cell interaction-column'>Last Interaction <img src={upanddownIcon} alt="upanddownIcon" height={14} width={9} onClick={() => handleSort('lastInteraction')} /></div>
                <div className='cell actions-column'></div>
              </div>
              {displayedDeployments.length === 0 ? (
                <p className='no-data-message'>No data available</p>
              ) : (
                displayedDeployments.map((deployment, index) => (
                  <div key={index} className='row'>
                    <div className='cell checkbox-column' onClick={() => toggleCheckbox(deployment.id)}>
                      <img height={16} width={16} src={selectedIds.includes(deployment.id) ? Uncheckbox : Checkbox} alt={selectedIds.includes(deployment.id) ? "Checked" : "Unchecked"} />
                    </div>
                    <div className='cell name-column '
                      onClick={() => handleDeploymentClick(deployment)}
                    >
                      {deployment.title}</div>
                    <div className='cell status-column'>
                      <span className={`status-indicator ${(getDeploymentStatus(deployment))?.toLowerCase() || 'on'}`}></span>
                      {(getDeploymentStatus(deployment))}
                    </div>
                    <div className='cell type-column'>{deployment.type}</div>
                    <div className='cell assessments-column'>Assessment data</div>
                    <div className='cell respondents-column'>{deployment.respondents === "No" ? 0 : deployment.respondents}</div>
                    <div className='cell interaction-column'>{new Date(deployment.lastInteraction).toLocaleDateString('en-US', {month: 'short',day: 'numeric',year: 'numeric'})}</div>

                    <div className='cell actions-column' onClick={() => {setSelectIds([deployment.id]); openDeleteModal();}}><img src={Delete} className='delete-icon' /></div>
                  </div>
                ))
              )}
              {/* {isModalOpen && (
                <div className='modal'>
                  <div className='modal-content'>
                    <div className="heading-container">
                      <div className="heading">
                        <p className="delete">Delete Deployment?</p>
                        <CloseIcon className='close-icon' onClick={closeModal} />
                      </div>
                    </div>
                    <div className="mid-container">
                      <div className="alert">
                        <img src={Alert} alt="" />
                      </div>
                      <div className="confirmation-msg">
                        <p className="message">Are you sure you want to delete this deployment?</p>
                        <p className="delete-info">Your results will be deleted and your respondents will no longer be able to take this assessment.</p>
                      </div>
                    </div>
                    <div className="buttons-container">
                      <Button className='cancel' onClick={closeModal}>Cancel</Button>
                      <Button className='delete-btn' onClick={confirmDelete}>Delete</Button>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="pagination">
                <div className='items-perpage'>
                  <p>Rows per page</p>
                  <div className='dropdown-container'>
                    <select
                      className="rows-dropdown"
                      value={itemsPerPage}
                      onChange={(e) => handleRowUpdates(e)}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                    <ExpandMoreOutlinedIcon className="dropdown-icon" sx={{ color: '#737373' }} />
                  </div>
                  {
                    displayedDeployments.length === 0 ?
                      <p>Showing 0 - 0 of 0</p>
                      : <p>Showing {startIndex + 1} - {displayedDeployments.length} of {displayedDeployments.length}</p>
                  }
                </div>
                <div className='right-btns'>
                  <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='btn previous'>
                    <ChevronLeft sx={{ fontSize: "16px" }} />
                  </IconButton>
                  {currentPage > 1 && <span>{currentPage - 1}</span>}
                  <span className="current">{currentPage}</span>
                  {currentPage < totalPages && <span>{currentPage + 1}</span>}
                  <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='btn next'>
                    <ChevronRight sx={{ fontSize: "16px" }} />
                  </IconButton>
                </div>
              </div>
            </div> : <p className='loading'>
              <CircularProgress size={60} style={{ color: "#000000" }} />
            </p>
          }
        </div>
      </div>
    </div>
  )
}

export default Deployments;
