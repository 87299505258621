import React, { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import RecommendedImg from '../../Assests/Images/Recommended.png';
import RecommendedTag from '../../Assests/Images/RecommendedTag.png';
import ThreeStar from '../../Assests/Images/ThreeStar.svg';
import DefaultImage from "../../Assests/Images/DefaultImage/default.png"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BarnaLogo from "../../Assests/Images/demographic-barna-logo.svg"
import {
  ShimmerDiv,
} from "shimmer-effects-react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactComponent as CharacterIcon } from '../../Assests/seven-dimensions-icons/icons/character.svg';
import { ReactComponent as ContentmentIcon } from '../../Assests/seven-dimensions-icons/icons/contentment.svg';
import { ReactComponent as FinancesIcon } from '../../Assests/seven-dimensions-icons/icons/finances.svg';
import { ReactComponent as HealthIcon } from '../../Assests/seven-dimensions-icons/icons/health.svg';
import { ReactComponent as PurposeIcon } from '../../Assests/seven-dimensions-icons/icons/purpose.svg';
import { ReactComponent as SpiritualIcon } from '../../Assests/seven-dimensions-icons/icons/spiritual.svg';
import { ReactComponent as RelationshipIcon } from '../../Assests/seven-dimensions-icons/icons/relationship.svg';

import { ReactComponent as BibleCenterednessIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/bible-centeredness.svg';
import { ReactComponent as ConnectedCommunityIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/connected-community.svg';
import { ReactComponent as DataInformedIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/data-informed.svg';
import { ReactComponent as FaithSharingIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/faith-sharing.svg';
import { ReactComponent as FutureFocusedIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/future-focused.svg';
import { ReactComponent as HolisticStewardshipIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/holistic-stewardship.svg';
import { ReactComponent as LeadershipDevelopmentIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/leadership-development.svg';
import { ReactComponent as PrayerCultureIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/prayer-culture.svg';
import { ReactComponent as ResourceStabilityIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/resource-stability.svg';
import { ReactComponent as ServingOthersIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/serving-others.svg';
import { ReactComponent as SocialImpactIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/social-impact.svg';
import { ReactComponent as SpiritualFormationIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/spiritual-formation.svg';
import { ReactComponent as TeamHealthIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/team-health.svg';
import { ReactComponent as TrustedLeadersIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/trusted-leaders.svg';
import { ReactComponent as WorshipExperienceIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/workship-experience.svg';

const RecommendedSection = ({ data, dimensionName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { windowWidth } = useWindowDimensions();
  const images = [
    { "drHenryCloud": "https://40parables-assets.s3.us-east-1.amazonaws.com/Dr. Henry Cloud.png" },
    { "glooLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/Gloo logo for dev.png" },
    { "careyLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/Carey Logo for dev.png" },
    { "lausanneLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/Lausanne Logo for dev.png" },
    { "whlLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/WHL Logo for dev.png" },
    { "thinqLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/THINQ logo.png" },
    { "stetzerPodcastLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/Stetzer Podcast logo.png" },
    { "d2sLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/D2S Logo.png" },
    { "lpaLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/LPA Logo.png" },
    { "pgLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/pg logo.png" },
    { "twi": "https://40parables-assets.s3.us-east-1.amazonaws.com/TWI.png" },
    { "CLT": "https://40parables-assets.s3.us-east-1.amazonaws.com/CL&T for dev.png" },
    { "theChosen": "https://40parables-assets.s3.us-east-1.amazonaws.com/The Chosen.png" },
    { "futureFocused": "https://40parables-assets.s3.us-east-1.amazonaws.com/future.svg" },
    { "gloo": "https://40parables-assets.s3.us-east-1.amazonaws.com/gloo.jpg" },
    { "barna": "https://40parables-assets.s3.us-east-1.amazonaws.com/barna.svg" },
    { "engagement": "https://40parables-assets.s3.us-east-1.amazonaws.com/engagement.svg" },
    { "teamHealth": "https://40parables-assets.s3.us-east-1.amazonaws.com/team-health.png" },
    { "barnaLogo": "https://40parables-assets.s3.us-east-1.amazonaws.com/barna-logo.png" },
    { "dare": "https://40parables-assets.s3.us-east-1.amazonaws.com/dare.svg" },
    { "luis": "https://40parables-assets.s3.us-east-1.amazonaws.com/luis.svg" },
    { "barnaplus": "https://40parables-assets.s3.us-east-1.amazonaws.com/barna-plus.svg" },
    { "dataInformed": "https://40parables-assets.s3.us-east-1.amazonaws.com/data-informed.svg" },
    { "faith": "https://40parables-assets.s3.us-east-1.amazonaws.com/analytics (1).svg" },
    { "stability": "https://40parables-assets.s3.us-east-1.amazonaws.com/resources-stability.svg" },
    { "yourVersion": "https://40parables-assets.s3.us-east-1.amazonaws.com/YouVersion.svg" },
    { "choosen": "https://40parables-assets.s3.us-east-1.amazonaws.com/Frame%201171276520.svg" },
    { "alpha": "https://40parables-assets.s3.us-east-1.amazonaws.com/20.png 1000x1000.svg" },
    { "relevant": "https://40parables-assets.s3.us-east-1.amazonaws.com/Frame 3.svg" },
    { "version": "https://40parables-assets.s3.us-east-1.amazonaws.com/Frame 4.svg" },
    { "data": "https://40parables-assets.s3.us-east-1.amazonaws.com/data-informed (1).svg" },
    { "practice": "https://40parables-assets.s3.us-east-1.amazonaws.com/Frame 2.svg" },
  ];

  const dimensionIcons = {
    character: CharacterIcon,
    virtue: CharacterIcon,
    contentment: ContentmentIcon,
    fulfillment: ContentmentIcon,
    finances: FinancesIcon,
    finance: FinancesIcon,
    health: HealthIcon,
    wellness: HealthIcon,
    purpose: PurposeIcon,
    relationships: RelationshipIcon,
    spiritual: SpiritualIcon,
    faith: SpiritualIcon,
    biblecenteredness: BibleCenterednessIcon,
    connectedcommunity: ConnectedCommunityIcon,
    datainformed: DataInformedIcon,
    faithsharing: FaithSharingIcon,
    futurefocused: FutureFocusedIcon,
    holisticstewardship: HolisticStewardshipIcon,
    leadershipdevelopment: LeadershipDevelopmentIcon,
    prayerculture: PrayerCultureIcon,
    resourcestability: ResourceStabilityIcon,
    servingothers: ServingOthersIcon,
    socialimpact: SocialImpactIcon,
    spiritualformation: SpiritualFormationIcon,
    teamhealth: TeamHealthIcon,
    trustedleaders: TrustedLeadersIcon,
    worshipexperience: WorshipExperienceIcon,
  };

  const isValidImageUrl = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok && response.headers.get('Content-Type').includes('image')) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  function truncateTextAtWordBoundary(text, maxLength = 30) {
    if (text.length <= maxLength) return text;

    const words = text.split(' ');
    let truncatedText = '';

    for (let word of words) {
      if ((truncatedText + word).length > maxLength) break;
      truncatedText += (truncatedText ? ' ' : '') + word;
    }

    return truncatedText + '...';
  }

  const [validImages, setValidImages] = useState({});

  useEffect(() => {
    const checkImages = async () => {
      setIsLoading(true);
      const imageValidations = {};
      for (let i = 0; i < data?.length; i++) {
        const item = data[i];
        if (item?.author_image && item?.author_image.startsWith('http')) {
          const valid = await isValidImageUrl(item?.author_image);
          imageValidations[i] = valid;
        } else {
          imageValidations[i] = true;
        }
      }
      setValidImages(imageValidations);
      setTimeout(() => {
        setIsLoading(false); 
      }, 1000);
    };

    checkImages();
  }, [data]);

  const getImageByKey = (key) => {
    const imageObj = images.find(image => image.hasOwnProperty(key));
    return imageObj ? imageObj[key] : null;
  };

  const getImageSrc = (imagePath) => {
    if (!imagePath) return '';
    if (imagePath.includes('drive.google.com/open?id=')) {
  
      const fileId = imagePath.split('id=')[1].split('&')[0];
      return `https://drive.google.com/thumbnail?id=${fileId}`;
    }
    if (imagePath.startsWith('http')) {
      return imagePath;
    }
    return getImageByKey(imagePath);
  };

  const getDimensions = (key) => {
    switch (key) {
      case "practice": return ({
        width: 80,
        height: 80
      });
      case "pgLogo": return ({
        width: 40,
        height: 40
      });
      case "choosen": return ({
        width: 60,
        height: 60
      });
      case "version": return ({
        width: 65,
        height: 65
      });
      case "lpaLogo": return ({
        width: 60,
        height: 60
      });
      case "lausanneLogo": return ({
        width: 60,
        height: 60
      });
      case "whlLogo": return ({
        width: 60,
        height: 60
      });
      case "stetzerPodcastLogo": return ({
        width: 60,
        height: 60
      });
      case "CLT": return ({
        width: 60,
        height: 60
      });
      default: return ({
        width: 22,
        height: 22
      });
    }
  }
  const getDimensionIcon = (dimensionname) => {
    const dimensionKey = dimensionname?.toLowerCase().replace(/\s+/g, '');
    return dimensionIcons[dimensionKey] ;
  }


  return (
    <>
      {isLoading || !data.length  ? (
        <div className="recommended-section">
          <h3 className="recommended-title shimmer"> <ShimmerDiv mode="light" height={40} width={329} rounded={1} /></h3>
          <div className='shimmer-container' >
            {[...Array(4)].map((_, index) => (
              <div className="recommended-wrapper">
                <div className="recommended-item" >
                  <div className='shimmer'>
                    <ShimmerDiv mode="light" height={171} width={windowWidth > 550 ? 284 : 328} rounded={1} />
                  </div>
                  <div className="recommended-footer">
                    <div className="recommended-info">
                      <p className='shimmer'>
                        <ShimmerDiv mode="light" height={14} width={100} rounded={1} />
                      </p>
                      <div className='shimmer'>
                        <ShimmerDiv mode="light" height={22} width={22} rounded={1} />
                      </div>
                    </div>
                    <div
                      className="recommended-badge shimmer-badge">
                      <ShimmerDiv mode="light" height={14} width={173} rounded={1} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) :
        (<div className="recommended-section">
          <h3 className="recommended-title">Recommended Next Steps</h3>
          <div className="recommended-wrapper">
            {data?.map((item, index) => {
              const DimensionIcon = getDimensionIcon(item?.dimension);
              return (
                <div className="recommended-item" key={index} onClick={() => window.open(item.url, '_blank')}>
                  <img
                    className="recommended-image"
                    src={getImageSrc(`${item.thumbnail}`)}
                    alt={item?.title || "Item"}
                  />
                  <div className="recommended-footer">
                    <div className="recommended-info">
                      <p>
                        {truncateTextAtWordBoundary(item?.title, 30)}
                      </p>

                      {validImages[index] ? (
                        // <img className="recommended-tag-image" src={getImageSrc(item?.author_image)} alt="Author" style={{ width: `${getDimensions(item?.author_image).width}px`, height: `${getDimensions(item?.author_image).height}px`  }} />
                        <img className="recommended-tag-image" src={getImageSrc(item?.logo)} alt="Author" />
                      ) : (
                        <AccountCircleIcon className="recommended-tag-image" />
                      )}
                    </div>
                    <div className="dimensioniconcontainer">
                      <div className="recommended-badge">
                        <div className='dimensionicon'><DimensionIcon height="11" fill='#851DD3' /></div>
                        {item?.dimension}
                      </div>
                    </div>
                  </div>
                </div>
              )})}
          </div>
        </div>
        )}
    </>

  );
};

export default RecommendedSection;