import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import glooLogo from '../../Assests/Images/glooLogoBig.png';
import leaficon from '../../Assests/Images/Leaf.svg';
import handwithicon from '../../Assests/Images/handWithHeart.svg';
import leadingicon from '../../Assests/Images/leadingicon.svg';
import leaficonwhite from '../../Assests/Images/Leafwhite.svg';
import handwithiconwhite from '../../Assests/Images/handwithheartwhite.svg';
import leadingiconwhite from '../../Assests/Images/Leadingwhite.svg';
import recommendedData from "../../Assests/data/RecommendedResourceUpdated.json";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import RecommendedTag from '../../Assests/Images/RecommendedTag.png';
import { ReactComponent as CharacterIcon } from '../../Assests/seven-dimensions-icons/icons/character.svg';
import { ReactComponent as ContentmentIcon } from '../../Assests/seven-dimensions-icons/icons/contentment.svg';
import { ReactComponent as FinancesIcon } from '../../Assests/seven-dimensions-icons/icons/finances.svg';
import { ReactComponent as HealthIcon } from '../../Assests/seven-dimensions-icons/icons/health.svg';
import { ReactComponent as PurposeIcon } from '../../Assests/seven-dimensions-icons/icons/purpose.svg';
import { ReactComponent as SpiritualIcon } from '../../Assests/seven-dimensions-icons/icons/spiritual.svg';
import { ReactComponent as RelationshipIcon } from '../../Assests/seven-dimensions-icons/icons/relationship.svg';

import { ReactComponent as BibleCenterednessIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/bible-centeredness.svg';
import { ReactComponent as ConnectedCommunityIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/connected-community.svg';
import { ReactComponent as DataInformedIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/data-informed.svg';
import { ReactComponent as FaithSharingIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/faith-sharing.svg';
import { ReactComponent as FutureFocusedIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/future-focused.svg';
import { ReactComponent as HolisticStewardshipIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/holistic-stewardship.svg';
import { ReactComponent as LeadershipDevelopmentIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/leadership-development.svg';
import { ReactComponent as PrayerCultureIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/prayer-culture.svg';
import { ReactComponent as ResourceStabilityIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/resource-stability.svg';
import { ReactComponent as ServingOthersIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/serving-others.svg';
import { ReactComponent as SocialImpactIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/social-impact.svg';
import { ReactComponent as SpiritualFormationIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/spiritual-formation.svg';
import { ReactComponent as TeamHealthIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/team-health.svg';
import { ReactComponent as TrustedLeadersIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/trusted-leaders.svg';
import { ReactComponent as WorshipExperienceIcon } from '../../Assests/fifteen-dimensions-icons/bold-icons/workship-experience.svg';
import { Link, useNavigate } from 'react-router-dom';

const filteroption = [{ option: 'All' },
{ option: 'Flourishing', icon: leaficon, seclectedIcon: leaficonwhite },
{ option: 'Nurturing', icon: leaficon, seclectedIcon: leaficonwhite },
{ option: 'Sending', icon: handwithicon, seclectedIcon: handwithiconwhite },
{ option: 'Leading', icon: leadingicon, seclectedIcon: leadingiconwhite },
]

const dimensionIcons = {
  character: CharacterIcon,
  virtue: CharacterIcon,
  contentment: ContentmentIcon,
  fulfillment: ContentmentIcon,
  finances: FinancesIcon,
  finance: FinancesIcon,
  health: HealthIcon,
  wellness: HealthIcon,
  purpose: PurposeIcon,
  relationships: RelationshipIcon,
  spiritual: SpiritualIcon,
  faith: SpiritualIcon,
  biblecenteredness: BibleCenterednessIcon,
  connectedcommunity: ConnectedCommunityIcon,
  datainformed: DataInformedIcon,
  faithsharing: FaithSharingIcon,
  futurefocused: FutureFocusedIcon,
  holisticstewardship: HolisticStewardshipIcon,
  leadershipdevelopment: LeadershipDevelopmentIcon,
  prayerculture: PrayerCultureIcon,
  resourcestability: ResourceStabilityIcon,
  servingothers: ServingOthersIcon,
  socialimpact: SocialImpactIcon,
  spiritualformation: SpiritualFormationIcon,
  teamhealth: TeamHealthIcon,
  trustedleaders: TrustedLeadersIcon,
  worshipexperience: WorshipExperienceIcon,
};

const sevenDimensions = ["faith", "purpose", "relationships", "finances", "wellness", "contentment", "virtue", "fulfillment"]
const Nurturing = ["connected-community", "worship-experience", "prayer-culture", "bible-centeredness", "spiritual-formation", "trusted-leaders"]
const Sending = ["faith-sharing", "serving-others", "holistic-stewardship", "leadership-development", "social-impact"]
const Leading = ["future-focused", "data-informed", "resource-stability", "team-health"]

const ResourcesNew = () => {

  const [filter, setFilter] = useState('All');
  const [resourcesData, setResourcesData] = useState([]);
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();

  const groupResourcesByTitleAndUrl = (resources) => {
    const groupedData = resources.reduce((acc, resource) => {
      const title = resource.title.trim();
      const { logo, dimension } = resource;
  
      const key = `${title}|${logo}`;
  
      if (acc[key]) {
        acc[key].dimensions = Array.from(new Set([...acc[key].dimensions, dimension]));
      } else {
        acc[key] = { ...resource, dimensions: [dimension] };
      }
      return acc;
    }, {});
  
    return Object.values(groupedData);
  };
  

  useEffect(() => {
    let filteredData = [];

    if (filter === 'All') {
      filteredData = recommendedData.flatMap(item => item?.data);
    } else if (filter === 'Flourishing') {
      filteredData = recommendedData
        .filter(item => sevenDimensions.includes(item?.Dimensions_name[0]))
        .flatMap(item => item?.data);
    } else if (filter === 'Nurturing') {
      filteredData = recommendedData
        .filter(item => Nurturing.includes(item?.Dimensions_name[0]))
        .flatMap(item => item?.data);
    } else if (filter === 'Sending') {
      filteredData = recommendedData
        .filter(item => Sending.includes(item?.Dimensions_name[0]))
        .flatMap(item => item?.data);
    } else if (filter === 'Leading') {
      filteredData = recommendedData
        .filter(item => Leading.includes(item?.Dimensions_name[0]))
        .flatMap(item => item?.data);
    } else {
      filteredData = recommendedData
        .filter(item => item?.Dimensions_name[0].includes(filter.toLowerCase()))
        .flatMap(item => item?.data);
    }
    
    const combinedData = groupResourcesByTitleAndUrl(filteredData)
    setResourcesData(combinedData);
  }, [filter]);


  const getDimensionIcon = (dimensionName) => {
    const dimensionKey =  String(dimensionName || '')?.toLowerCase().replace(/\s+/g, '');
    return dimensionIcons[dimensionKey] || SpiritualIcon;
  }

  const ResourceCard = ({ data }) => {

    const getImageByKey = (key) => {
      const imageObj = data.find(image => image.hasOwnProperty(key));
      return imageObj ? imageObj[key] : null;
    };

    return (
      <div className="resourcescard" onClick={() => window.open(data?.url, '_blank', 'noopener,noreferrer')}>
        <div className="resourceimg" >
          <img src={data.thumbnail} alt={data?.title} />
        </div>
        <div className="description">
          <div className="descriptionicon" >
            <p>{data?.title?.length > 15 ? data.title.slice(0, 25) : data?.title}</p>
            <img src={data.logo} alt={data?.tag} width={22} height={22} />
          </div>
          <div className="dimensioniconcontainer">
          {data?.dimensions.map((dimensionName, index) => {
              const DimensionIcon = getDimensionIcon(dimensionName);
              return (
                <div key={index} className="belong" style={{ backgroundColor: '#F4E7FF', color: '#851DD3' }}>
                  <div className="dimensionicon">
                    <DimensionIcon height="11" fill="#851DD3" />
                  </div>
                  {dimensionName}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  // console.log("resourcesData" , resourcesData);


  return (
    <section className='resource-contaioner'>
      <div className="pageheader">Next Steps</div>
      <div className="resource-card-container">
        <div className="cardone">
          <div className="centerdeatil">
            {windowWidth < 560 &&
              <div className="logo"><img src={glooLogo} alt="" /></div>
            }
            <div className="detailtext">
              <h3>Grow more with gloo+</h3>
              {windowWidth > 560 &&
                <p style={{marginTop: 3}}>More messages. Unlimited groups. Powerful outreach tools.  Time-saving resources. And more.</p>
              }
            </div>
            <Link  to="https://app.gloo.us/gloo-plus"  className="cta-card"  target="_blank" rel="noopener noreferrer"> See the perks </Link>
          </div>
          {windowWidth > 560 &&
            <div className="logo"><img src={glooLogo} alt="" /></div>
          }
        </div>
        <div className="cardtwo">
          <div className="centerdeatil">
            {windowWidth < 560 &&
              <div className="logo"></div>
            }
            <div className="detailtext">
              <h3>Discover More With
                Barna Access <span>Plus</span></h3>
              {windowWidth > 560 &&
                <p>Strengthen your message, train your team and grow your church with cultural insights and practical resources, all in one place</p>
              }
            </div>
            <Link to="https://app.gloo.us/discover/barna-hub"  className="cta-card"  target="_blank" rel="noopener noreferrer">Learn more</Link>
          </div>
          {windowWidth > 560 &&
            <div className="logo"></div>
          }
        </div>
      </div>
      <div className="filteroption">
        {filteroption.map((data, index) => {
          return (
            <div className={`option ${filter === data?.option ? 'selected' : 'unselected'}`} onClick={() => { setFilter(data?.option) }}>
              {data?.icon && <img src={filter === data?.option ? data?.seclectedIcon : data?.icon} />}
              <p>{data?.option}</p>
            </div>
          )
        })}
      </div>
      <div className="resources">
        {resourcesData?.map((data, index) => (
          <ResourceCard data={data} key={index} />
        ))}
      </div>
    </section>
  )

}

export default ResourcesNew;
