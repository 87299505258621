import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import menuicon from '../../Assests/Images/lightmenuicon.svg';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DoneIcon from '@mui/icons-material/Done';
import { Backdrop, SwipeableDrawer } from '@mui/material';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import Church from '../../Assests/Images/church.svg'
import CirclesOverlap from '../../Assests/Images/circles-overlap.svg'
import FlagUsa from '../../Assests/Images/Flag-usa.svg'
import mobiledropdown from "../../Assests/Images/mobile-dropdown.svg"
import calenderIcon from "../../Assests/Images/calender-icon.svg"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import filtericon from "../../Assests/Images/filter-icon.svg";
import closeIcon from "../../Assests/Images/close-icon.svg";
import AgeRangeIcon from '../../Assests/Images/Agerange.svg'
import GenderIcon from '../../Assests/Images/Gender.svg'

const MobileFilterCard = ({ appliedFilters, setAppliedFilters, filterType, comparison = true, setComparison, isDeployedDone ,isChurch }) => {
  const [drawerState, setDrawerState] = React.useState({
    isVisible: false,
    isDatasetVisible: false,
    isAgeGenderVisible: false,
  });
  const [timeline, setTimeline] = useState(appliedFilters?.timeline)
  const [tempDataset, setTempDataset] = useState(appliedFilters?.dataset)
  const [circleDown, setCircleDown] = useState(appliedFilters?.dataset === 'Circles' ? true : false)
  const [ageRange, setAgeRange] = useState(appliedFilters?.ageRange)
  const [enableSelect, setEenableSelect] = useState({ Agerange: true, Gender: true, circleDown: true })
  const [gender, setGender] = useState(appliedFilters?.gender)
  const [drawerPosition, setDrawerPosition] = useState(0);
  const isDragging = useRef(false);
  const startY = useRef(0);

  const openDrawer = (key) => {
    setDrawerState((prevState) => ({ ...prevState, [key]: true }));
  };

  const closeDrawer = () => {
    setDrawerPosition(0);
    setDrawerState({ isVisible: false, isDatasetVisible: false, isAgeGenderVisible: false, });
  };

  const toggleCircleDown = () => {
    setCircleDown(circleDown => !circleDown)
  }
  const [circleType, setCircleType] = useState(appliedFilters?.circleType);

  const handleStart = (event) => {
    isDragging.current = true;
    startY.current = event.touches ? event.touches[0].clientY : event.clientY;
  };

  const handleMove = (event) => {
    if (!isDragging.current) return;
    const currentY = event.touches ? event.touches[0].clientY : event.clientY;
    const deltaY = currentY - startY.current;
    if (deltaY > 0) {
      setDrawerPosition(deltaY);
    }
  };

  const handleEnd = () => {
    isDragging.current = false;
    if (drawerPosition > 80) {
      closeDrawer();
    } else {
      setDrawerPosition(0);
    }
  };


  const handleApplyFilter = () => {
    setComparison && setComparison(true);
    setAppliedFilters && setAppliedFilters({ timeline, dataset: tempDataset, circleType, ageRange, gender });
    closeDrawer();
  }

  const handleTempDataset = (dataset) => {
    dataset === 'Circles' ? setCircleDown(true) : setCircleDown(false);
    setTempDataset(dataset);
  }


  const clearFilters = () => {
    setGender(null)
    setAgeRange(null)
    setAppliedFilters && setAppliedFilters(prev => ({
      ...prev,
      gender: null,
      ageRange: null
    }))
  }

  const handlerrightcross = () => {
    if (filterType === 'rightfilter' && isDeployedDone && setComparison) {
      setComparison(false);
    }
  };

  const handleSelectFilter = (key) => {
    setEenableSelect({...enableSelect, [key]: !enableSelect[key]})
  }
  
  useEffect(() => {
    const drawerElements = document.querySelectorAll(".css-m38dr8-MuiPaper-root-MuiDrawer-paper");
  
    drawerElements.forEach((drawerElement) => {
      if (drawerElement) {
        drawerElement.style.transition = isDragging.current ? "none" : "transform 0.3s ease";
        drawerElement.style.transform = `translateY(${drawerPosition}px)`;
      }
    });
  }, [drawerPosition]);
  

  return (
    <div className="filter-compare-box-mobile">
      <div className="filter-right-box">
        <div className="action-btns">
          <button onClick={() => openDrawer("isDatasetVisible")} className="cta-type">
            <div className="status-icon" style={{ backgroundColor: filterType === 'leftfilter' ? '#C581FF' : '#00c980' }}></div>{appliedFilters?.dataset ?? 'National Average'}
            <img src={mobiledropdown} alt="mobiledropdown" />
          </button>
          <Backdrop
            sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
            open={drawerState?.isDatasetVisible}
          >
            <SwipeableDrawer
              anchor="bottom"
              duration={250}
              hideScrollbars={true}
              onClose={closeDrawer}
              isVisible={drawerState?.isDatasetVisible}
              open={drawerState?.isDatasetVisible}
              variant={"persistent"}
            >
              <div className="dataset-drawer-content" onTouchStart={handleStart} onTouchMove={handleMove} onTouchEnd={handleEnd} onMouseDown={handleStart} onMouseMove={handleMove} onMouseUp={handleEnd} onMouseLeave={handleEnd}>
                <div className="slider"><div className="slide"></div></div>
                <div className="content-heading">
                  <span className="heading">Data set</span>
                  <span
                    onClick={clearFilters}
                    className="clear-span"
                  >
                    Clear all
                  </span>
                </div>
                <div className="items-container">
                  <div className="items">
                    <div
                      onClick={() => handleTempDataset("My Church")}
                      className={`${tempDataset === 'My Church' ? 'item item-active' : 'item'}`}
                    >
                      <img src={Church}></img>
                      <p>My church</p>
                    </div>
                    <div
                      onClick={() => handleTempDataset('Circles')}
                      className={`${tempDataset === 'Circles' ? 'item item-active' : 'item'}`}
                    >
                      <img src={CirclesOverlap}></img>
                      <p>Circles</p>
                    </div>
                    <div
                      onClick={() => handleTempDataset("National Average")}
                      className={`${tempDataset === 'National Average' ? 'item item-active' : 'item'}`}
                    >
                      <img src={FlagUsa}></img>
                      <p>National Average</p>
                    </div>
                  </div>
                  <div
                    className={`circles-item ${tempDataset !== "Circles" || !circleDown
                      ? "close-circle"
                      : null
                      }`}
                  >
                    <div className="circles-heading">
                      <div className="my-circles">
                        <img
                          style={{ width: "24px", height: "24px" }}
                          src={CirclesOverlap}
                        ></img>
                        <p>My Circles</p>
                      </div>
                      <KeyboardArrowUpIcon onClick={() => handleSelectFilter('circleDown')} sx={{ cursor: "pointer", fontSize: "small", transform: enableSelect['circleDown'] ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}} />
                    </div>
                    {
                      enableSelect['circleDown'] &&
                      <div className="circles-btns">
                        <button
                          className={`${circleType === "All Circles" ? "selected" : null
                            }`}
                          onClick={() => setCircleType("All Circles")}
                        >
                          All Circles
                        </button>
                        <button
                          className={`${circleType === "Circle A" ? "selected" : null
                            }`}
                          onClick={() => setCircleType("Circle A")}
                        >
                          Circle A
                        </button>
                        <button
                          className={`${circleType === "Circle B" ? "selected" : null
                            }`}
                          onClick={() => setCircleType("Circle B")}
                        >
                          Circle B
                        </button>
                        <button
                          className={`${circleType === "Circle C" ? "selected" : null
                            }`}
                          onClick={() => setCircleType("Circle C")}
                        >
                          Circle C
                        </button>
                        <button
                          className={`${circleType === "Circle D" ? "selected" : null
                            }`}
                          onClick={() => setCircleType("Circle D")}
                        >
                          Circle D
                        </button>
                      </div>
                    }
                  </div>
                </div>
                <div className="btns">
                  <button onClick={() => closeDrawer()} className="cancel-cta">
                    Cancel
                  </button>
                  <button className="apply-cta" onClick={handleApplyFilter}>Apply</button>
                </div>
              </div>
            </SwipeableDrawer>
          </Backdrop>
          <button onClick={() => openDrawer("isVisible")} className="cta">
            <img src={calenderIcon} alt="calenderIcon" /> {timeline === "yearToDate" ? "Year to date" : timeline === "allTime" ? "All time" : timeline === "lastYear" ? "Last year" : timeline === "last30Days" ? "Last 30 days" : timeline}
            <img src={mobiledropdown} alt="mobiledropdown" />
          </button>
          <Backdrop
            sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
            open={drawerState?.isVisible}
          >
            <SwipeableDrawer
              anchor="bottom"
              duration={250}
              hideScrollbars={true}
              onClose={() => closeDrawer()}
              isVisible={drawerState?.isVisible}
              open={drawerState?.isVisible}
              variant={"persistent"}
            >
              <div className="calendar-drawer-content" onTouchStart={handleStart} onTouchMove={handleMove} onTouchEnd={handleEnd} onMouseDown={handleStart} onMouseMove={handleMove} onMouseUp={handleEnd} onMouseLeave={handleEnd}>
                <div className="slider"><div className="slide"></div></div>
                <div className="content-heading">
                  <span className="heading">Time period</span>
                  <span
                    onClick={clearFilters}
                    className="clear-span"
                  >
                    Clear all
                  </span>
                </div>
                <div className="items-container">
                  <ul>
                    <li onClick={() => setTimeline("yearToDate")}>
                      <span>Year to date</span>
                      {timeline === "yearToDate" && (
                        <DoneIcon fontSize="small" />
                      )}
                    </li>
                    <li onClick={() => setTimeline("allTime")}>
                      <span>All Time</span>
                      {timeline === "allTime" && (
                        <DoneIcon fontSize="small" />
                      )}
                    </li>
                    <li onClick={() => setTimeline("lastYear")}>
                      <span>Last Year</span>
                      {timeline === "lastYear" && (
                        <DoneIcon fontSize="small" />
                      )}
                    </li>
                    <li onClick={() => setTimeline("last30Days")}>
                      <span>Last 30 days</span>
                      {timeline === "last30Days" && (
                        <DoneIcon fontSize="small" />
                      )}
                    </li>
                  </ul>
                </div>
                <div className="btns">
                  <button onClick={() => closeDrawer()} className="cancel-cta">
                    Cancel
                  </button>
                  <button className="apply-cta" onClick={handleApplyFilter}>Apply</button>
                </div>
              </div>
            </SwipeableDrawer>
          </Backdrop>
          <button className="cta-menu" onClick={() => openDrawer("isAgeGenderVisible")}>
            <img src={filtericon} alt="filtericon" />
          </button>
          <Backdrop
            sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
            open={drawerState?.isAgeGenderVisible}
          // onClick={closeDrawerAgeGender}
          >
            <SwipeableDrawer
              anchor="bottom"
              duration={250}
              hideScrollbars={true}
              onClose={() => closeDrawer()}
              isVisible={drawerState?.isAgeGenderVisible}
              open={drawerState?.isAgeGenderVisible}
              variant={"persistent"}
            >
              <div className="dataset-drawer-content" onTouchStart={handleStart} onTouchMove={handleMove} onTouchEnd={handleEnd} onMouseDown={handleStart} onMouseMove={handleMove} onMouseUp={handleEnd} onMouseLeave={handleEnd}>
                <div className="slider"><div className="slide"></div></div>
                <div className="content-heading">
                  <span className="heading">Filters</span>
                  <span
                    onClick={clearFilters}
                    className="clear-span"
                  >
                    Clear all
                  </span>
                </div>

                <div className='dataset-options'>
                  <div className='circles-container'>
                    <div className='circles-heading'>
                      <div className='my-circles'>
                        <img style={{ width: "24px", height: "24px" }} src={AgeRangeIcon}></img>
                        <p>Age range</p>
                      </div>
                      <KeyboardArrowUpIcon onClick={()=>handleSelectFilter('Agerange')} sx={{ cursor: "pointer", fontSize: "small", transform: enableSelect['Agerange'] ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}} />
                    </div>
                    {
                      enableSelect['Agerange'] &&
                      <div className='circles-btns'>
                        <button className={`${ageRange === "18-25" ? "selected" : null}`} onClick={() => setAgeRange("18-25")}>18-25</button>
                        <button className={`${ageRange === "26-40" ? "selected" : null}`} onClick={() => setAgeRange("26-40")}>26-40</button>
                        <button className={`${ageRange === "41-59" ? "selected" : null}`} onClick={() => setAgeRange("41-59")}>41-59</button>
                        <button className={`${ageRange === "60-78" ? "selected" : null}`} onClick={() => setAgeRange("60-78")}>60-78</button>
                        <button className={`${ageRange === "79-99" ? "selected" : null}`} onClick={() => setAgeRange("79-99")}>79-99</button>
                      </div>
                    }
                  </div>
                  <div className='divider' />
                  <div className='circles-container'>
                    <div className='circles-heading'>
                      <div className='my-circles'>
                        <img style={{ width: "24px", height: "24px" }} src={GenderIcon}></img>
                        <p>Gender</p>
                      </div>
                      <KeyboardArrowUpIcon onClick={()=>handleSelectFilter('Gender')} sx={{ cursor: "pointer", fontSize: "small", transform: enableSelect['Gender'] ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.1s ease-in-out"}} />
                    </div>
                    {
                      enableSelect['Gender'] &&
                      <div className='circles-btns'>
                        <button className={`${gender === "Male" ? "selected" : null}`} onClick={() => setGender("Male")}>Male</button>
                        <button className={`${gender === "Female" ? "selected" : null}`} onClick={() => setGender("Female")}>Female</button>
                        <button className={`${gender === "Prefer not to answer" ? "selected" : null}`} onClick={() => setGender("Prefer not to answer")}>Prefer not to answer</button>
                      </div>
                    }
                  </div>
                </div>
                <div className="btns">
                  <button onClick={() => closeDrawer()} className="cancel-cta">
                    Cancel
                  </button>
                  <button className="apply-cta" onClick={handleApplyFilter}>Apply</button>
                </div>
              </div>
            </SwipeableDrawer>
          </Backdrop>
        </div>
        <img src={closeIcon} alt="closeIcon" onClick={handlerrightcross} />
      </div>
    </div>
  );
}

export default MobileFilterCard



